import DocumentsFullPageComponent from "./DocumentManagementFullPage";

const DocumentManagement: React.FC = () => {
  return (
    <>
      {/* <Heading className="py-5 text-xl" as="h1">Documents</Heading> */}
      <DocumentsFullPageComponent />
    </>
  );
};

export default DocumentManagement;

"use client";

import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "~/utils";
import {
  LayoutGrid,
  MessageCircle,
  FileText,
  ClipboardList,
  Home,
  FileLock,
  ChevronLeft,
  ChevronRight,
  Sliders,
  LogIn,
  Library,
} from "lucide-react";
import useIsPage from "~/hooks/useIsPage";
import useSession from "~/hooks/useSession";
import UserDropdown from "~/components/Layout/Nav/UserDropdown";
import { Button } from "~/components/ui/button";

const LogInButton = () => (
  <Button asChild variant="secondary" className="w-full justify-center">
    <Link to="/log_in" className="flex items-center gap-2">
      <LogIn className="h-4 w-4" />
      <span>Log In</span>
    </Link>
  </Button>
);

const SideNav = () => {
  const { isAnalysisPage } = useIsPage();
  const [collapsed, setCollapsed] = useState(isAnalysisPage);
  const { loggedIn, session } = useSession();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="relative flex">
      <nav
        className={`sticky top-0 flex h-[100vh] flex-col border-r border-[#E5E5E5] bg-[#F7F7F7] shadow-sm transition-all duration-200 ${
          collapsed ? "w-18" : "w-[280px]"
        }`}
      >
        <div className="flex items-center justify-between p-4">
          {!collapsed && (
            <Link to="/" className="flex cursor-pointer items-center">
              <img
                src="/images/Qumis_FullColor_Logomark_RGB.png"
                alt="Qumis Logo"
                width={32}
                height={32}
                className="mr-3"
              />
              <h3 className="text-xl text-[#000000]">Qumis</h3>
            </Link>
          )}
          {collapsed && (
            <Link to="/" className="mx-auto cursor-pointer">
              <img
                src="/images/Qumis_FullColor_Logomark_RGB.png"
                alt="Qumis Logo"
                width={32}
                height={32}
              />
            </Link>
          )}
          <button
            onClick={toggleSidebar}
            className="absolute -right-4 top-1/2 z-10 -translate-y-1/2 transform rounded-full border border-[#E5E5E5] bg-white p-1.5 shadow-md transition-all duration-200 hover:bg-[#E5E5E5] hover:shadow-lg"
            title={collapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {collapsed ? (
              <ChevronRight className="h-4 w-4 text-[#1E1E1E]" />
            ) : (
              <ChevronLeft className="h-4 w-4 text-[#1E1E1E]" />
            )}
          </button>
        </div>

        <div className="flex flex-grow flex-col overflow-y-auto px-3 py-4">
          <div className="mt-0">
            <div className="space-y-1">
              <NavItem
                href="/"
                icon={<Home className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Home"
              >
                Home
              </NavItem>
              <NavItem
                href="/chat"
                icon={<MessageCircle className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Chat"
              >
                Chat
              </NavItem>
              <NavItem
                href="/documents"
                icon={<FileLock className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Documents Vault"
              >
                Vault
              </NavItem>
              <NavItem
                href="/prompt-library"
                icon={<Library className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Prompt Library"
              >
                Prompt Library
              </NavItem>
            </div>
          </div>

          <div className="mt-6">
            <div
              className={`mb-2 px-4 text-xs font-medium uppercase tracking-wider text-[#666666] ${
                collapsed ? "hidden" : ""
              }`}
            >
              Reports
            </div>
            <div className="space-y-1">
              <NavItem
                href="/policy-comparison"
                icon={<LayoutGrid className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Policy Comparisons"
              >
                Comparisons
              </NavItem>
              <NavItem
                href="/claim-coverage"
                icon={<FileText className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Claim Coverage"
              >
                Claims
              </NavItem>
              <NavItem
                href="/generic-report"
                icon={<Sliders className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Custom Report"
              >
                Custom Report
              </NavItem>
              <NavItem
                href="/coverage-checklist"
                beta
                icon={<ClipboardList className="h-4 w-4" />}
                collapsed={collapsed}
                tooltip="Coverage Checklist"
              >
                Checklist
              </NavItem>
            </div>
          </div>

          {/* User account section at the bottom */}
          <div className="mt-auto px-2 pt-4">
            {loggedIn ? (
              <div
                className={`flex items-center ${
                  collapsed ? "justify-center" : "gap-2"
                }`}
              >
                <UserDropdown />
                {!collapsed && session?.email && (
                  <span className="mr-10 max-w-[180px] truncate text-sm text-[#666666]">
                    {session.email}
                  </span>
                )}
              </div>
            ) : collapsed ? (
              <Button
                asChild
                variant="secondary"
                className="flex w-full justify-center p-2"
                title="Log In"
              >
                <Link to="/log_in">
                  <LogIn className="h-4 w-4" />
                </Link>
              </Button>
            ) : (
              <LogInButton />
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

function NavItem({
  href,
  icon,
  children,
  badge,
  beta,
  collapsed,
  tooltip,
}: {
  href: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  badge?: React.ReactNode;
  beta?: boolean;
  collapsed: boolean;
  tooltip: string;
}) {
  const location = useLocation();
  const { isHomePage } = useIsPage();

  // Check if this is the home item and we're on the home page
  const isHomeItem = href === "/";
  // Item is active if it's the home item and we're on home page, or if the paths match exactly
  const isActive = (isHomeItem && isHomePage) || location.pathname === href;

  return (
    <Link
      to={href}
      className={cn(
        "flex items-center gap-3 rounded-lg px-4 py-3 text-sm font-medium transition-all duration-200",
        isActive
          ? "bg-[#E5E5E5] font-bold text-[#000000]"
          : "text-[#1E1E1E] hover:bg-[#FAFAFA] hover:text-[#000000]",
        collapsed ? "justify-center" : ""
      )}
      title={tooltip}
    >
      <div
        className={cn(
          "flex items-center justify-center",
          isActive ? "text-[#000000]" : "text-[#1E1E1E]"
        )}
      >
        {icon}
      </div>
      {!collapsed && <span>{children}</span>}
      {!collapsed && beta && (
        <span className="ml-auto rounded-md bg-[#E5E5E5] px-1.5 py-0.5 text-xs font-medium text-[#000000]">
          BETA
        </span>
      )}
      {!collapsed && badge && <span className="ml-auto">{badge}</span>}
    </Link>
  );
}

export default SideNav;

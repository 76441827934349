import { useState, useRef, useEffect } from "react";
import { format } from "date-fns";
import { FileText } from "lucide-react";
import { ElementProps } from "../types";
import { marked } from "marked";

interface SearchViewProps {
  search: string;
  list: ElementProps[];
  linkAction?: (hit: any) => void;
}
interface FilenamesProps {
  element: ElementProps;
  search: string;
}

const SearchView: React.FC<SearchViewProps> = ({
  search,
  list,
  linkAction,
}) => {
  const formattedDate = (date: number) => format(date * 1000, "MMM d, yyyy");

  return (
    <div className="rounded-md border">
      <div className="space-y-6 p-4">
        {list.map((element) => (
          <div key={element.id} className="rounded-lg border bg-card shadow-sm">
            <div
              className={[
                "p-6",
                linkAction ? "cursor-pointer hover:bg-accent" : "",
              ].join(" ")}
              onClick={() => linkAction && linkAction(element)}
            >
              <div className="flex items-start justify-between">
                <h3 className="flex-1 pr-4 text-xl font-semibold leading-tight">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlightContent(element.title, search),
                    }}
                  />
                </h3>
                <span className="inline-flex items-center rounded-full bg-secondary px-2.5 py-0.5 text-xs font-semibold text-secondary-foreground">
                  {formattedDate(element.createdAt)}
                </span>
              </div>
              <Filenames element={element} search={search} />
            </div>
            <div className="prose prose-sm max-h-[200px] max-w-none overflow-y-auto px-6 pb-6 dark:prose-invert ">
              <CustomMarkdown
                content={element.content || "Content Empty"}
                search={search}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Filenames: React.FC<FilenamesProps> = ({ element, search }) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll(!showAll);
  };
  const filenames = showAll ? element.filenames : element.filenames.slice(0, 3);

  return (
    <div className="mt-2 flex flex-wrap gap-2">
      {filenames.map((filename, i) => (
        <button
          key={i}
          className="inline-flex h-9 items-center gap-2 rounded-md border bg-background px-3 text-sm font-medium hover:bg-accent hover:text-accent-foreground"
        >
          <FileText className="h-4 w-4 shrink-0" />
          <span
            dangerouslySetInnerHTML={{
              __html: highlightContent(filename, search),
            }}
          />
        </button>
      ))}
      {element.filenames.length > 3 && (
        <button
          onClick={toggleShowAll}
          className="inline-flex h-9 items-center gap-2 rounded-md border bg-background px-3 text-sm font-medium hover:bg-accent hover:text-accent-foreground"
        >
          {showAll ? "Show less" : `+${element.filenames.length - 3} Show more`}
        </button>
      )}
    </div>
  );
};

// ------------------ HIGHLIGHT CONTENT ------------------

interface CustomMarkdownProps {
  content: string;
  search: string;
}

const CustomMarkdown: React.FC<CustomMarkdownProps> = ({ content, search }) => {
  const html = marked.parse(content) as string;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (!search) {
        containerRef.current.scrollTop = 0;
      } else {
        const firstHighlight = containerRef.current.querySelector(
          ".bg-yellow-200, .dark\\:bg-yellow-800"
        );
        if (firstHighlight) {
          firstHighlight.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [html, search]);

  return (
    <div
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: highlightContent(html, search) }}
    />
  );
};

const highlightContent = (html: string, search: string) => {
  if (!search || search == "") return html;
  const searchRegex = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const regex = new RegExp(`(${searchRegex})`, "gi");
  const highlightedHtml = html.replace(
    regex,
    "<span class='bg-yellow-200 dark:bg-yellow-800'>$1</span>"
  );
  return highlightedHtml;
};

export default SearchView;

import { useLocation } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useBreadcrumb } from "~/context/BreadcrumbContext";

const Breadcrumbs = () => {
  const location = useLocation();
  const { reportTitle } = useBreadcrumb();
  const breadcrumbs = getBreadcrumbs(location.pathname, reportTitle);

  return (
    <div className="flex items-center px-4 py-2">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <span
            key={index}
            className="flex items-center text-sm text-[#666666]"
          >
            {breadcrumb}
            {index < breadcrumbs.length - 1 && (
              <ChevronRight className="mx-2 h-4 w-4 stroke-[2] text-[#1E1E1E]" />
            )}
          </span>
        );
      })}
    </div>
  );
};

function getBreadcrumbs(
  pathname: string,
  reportTitle: string | null
): React.ReactNode[] {
  const pathParts = pathname.split("/").filter(Boolean);

  if (pathParts.length === 0) {
    return [""];
  }

  // Filter out UUIDs from the path parts
  const filteredPathParts = pathParts.filter((part) => {
    // UUID pattern: 8-4-4-4-12 hexadecimal characters
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return !uuidPattern.test(part);
  });

  // Special handling for report routes
  if (pathname.includes("/report/analysis/")) {
    return [
      <span className="text-[#666666]">Home</span>,
      <span className="text-[#666666]">Report</span>,
      <span className="text-[#666666]">Analysis</span>,
      <span className="text-[#666666]">{reportTitle || "Report"}</span>,
    ];
  }

  // Special handling for public report routes
  if (pathname.includes("/report/") && !pathname.includes("/analysis/")) {
    return [
      <span className="text-[#666666]">Home</span>,
      <span className="text-[#666666]">Report</span>,
      <span className="text-[#666666]">{reportTitle || "Report"}</span>,
    ];
  }

  const breadcrumbs = filteredPathParts.map((part) => {
    if (part === "chat") return <span className="text-[#666666]">Chat</span>;
    if (part === "documents")
      return <span className="text-[#666666]">Documents Vault</span>;
    if (part === "policy-comparison")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Comparisons</span>
        </React.Fragment>
      );
    if (part === "claim-coverage")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Claims</span>
        </React.Fragment>
      );
    if (part === "chat")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Chat</span>
        </React.Fragment>
      );
    if (part === "generic-report")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Custom Report</span>
        </React.Fragment>
      );
    if (part === "coverage-checklist")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Checklist</span>
        </React.Fragment>
      );
    if (part === "policy-comparison-new")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Comparisons</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Generate</span>
        </React.Fragment>
      );
    if (part === "claim-coverage-new")
      return (
        <React.Fragment>
          <span className="text-[#666666]">Reports</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Claims</span>{" "}
          <ChevronRight className="mx-1 h-4 w-4 stroke-[2] text-[#1E1E1E]" />{" "}
          <span className="text-[#666666]">Generate</span>
        </React.Fragment>
      );
    if (part === "analysis")
      return <span className="text-[#666666]">Analysis</span>;
    if (part === "report")
      return <span className="text-[#666666]">Report</span>;
    return (
      <span className="text-[#666666]">
        {part.charAt(0).toUpperCase() + part.slice(1)}
      </span>
    );
  });

  // Add "Home" at the beginning of the breadcrumbs
  const result = [<span className="text-[#666666]">Home</span>, ...breadcrumbs];
  return result;
}

export default Breadcrumbs;

import React from "react";
import { LoadingComponent } from "~/utils/util-report";

interface UCTemplateProps {
  SearchComponent: React.ComponentType;
  ButtonComponent: React.ComponentType;
  isLoading?: boolean;
  loadingTitle?: string;
  enableLoading?: boolean;
  title?: string;
  description?: string;
}

const UCTemplate: React.FC<UCTemplateProps> = ({
  SearchComponent,
  ButtonComponent,
  isLoading = false,
  loadingTitle = "LOADING",
  enableLoading = true,
  title,
  description,
}) => {
  if (isLoading && enableLoading) {
    return <LoadingComponent title={loadingTitle} />;
  }

  return (
    <div className="container mx-auto py-8">
      {(title || description) && (
        <div className="mb-8 text-center">
          {title && (
            <h2 className="mb-2 text-2xl font-bold text-primary">{title}</h2>
          )}
          {description && (
            <p className="mx-auto max-w-2xl text-muted-foreground">
              {description}
            </p>
          )}
        </div>
      )}

      <div className="flex flex-col items-stretch justify-center gap-6 md:flex-row">
        <div className="custom-shadcn-components h-[400px] flex-1 overflow-hidden rounded-lg border bg-card shadow-sm transition-all md:h-[500px] lg:h-[600px]">
          <div className="h-full w-full overflow-y-auto p-5 scrollbar-thin">
            <SearchComponent />
          </div>
        </div>

        <div className="custom-shadcn-components h-[400px] flex-1 overflow-hidden rounded-lg border bg-card shadow-sm transition-all md:h-[500px] lg:h-[600px]">
          <div className="flex h-full w-full items-center justify-center p-5">
            <ButtonComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UCTemplate;

import { useLocation } from "react-router-dom";

/**
 * Custom hook to determine if the current page matches a specific page type
 * @returns Object with boolean flags for different page types
 */
const useIsPage = () => {
  const location = useLocation();
  const isAnalysisPage =
    location.pathname === "/analysis" ||
    location.pathname.startsWith("/analysis/") ||
    location.pathname.includes("/report/analysis");

  return {
    isHomePage: location.pathname === "/" || location.pathname === "/home",
    isAnalysisPage: isAnalysisPage,
  };
};

export default useIsPage;

import { useState, useEffect } from "react";
import Pagination from "~/components/Pagination";
import ListView from "./Views/ListView";
import UploadArea from "../Documents/UploadArea";
import { DocumentTypeEnum, getDocument } from "~/api";
import ContentView from "./Views/ContentView";
import { initStore } from "./helpers";
import { DocumentItem, SortOrder, SortField, ViewType } from "./types";
import DeleteDialog from "./Components/DeleteDialog";
import Header from "./Modules/Header";
import { Worker } from "@react-pdf-viewer/core";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { useDocViewer } from "~/doc-util";
import { SingleDocResponseData } from "~/api/query_fns/documents";

export default function DocumentsFullPageComponent() {
  // Local state
  const [currentSearch, setCurrentSearch] = useState("");
  const [viewType, setViewType] = useState<ViewType>("list");
  const [selectedTypes, setSelectedTypes] = useState<DocumentTypeEnum[]>(
    Object.values(DocumentTypeEnum)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<SortField>("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  // Dialog state
  const [documentToDelete, setDocumentToDelete] = useState<DocumentItem | null>(
    null
  );

  // PDF Viewer state
  const {
    isDocViewerOpen,
    initialPage,
    document: selectedDocument,
    setDocViewerState,
    citation,
  } = useDocViewer();

  // Initialize connection
  const { state, actions } = initStore();
  const reloadDocuments = () => {
    actions.loadData({
      viewType,
      selectedTypes,
      currentSearch,
      currentPage,
      sortField,
      sortOrder,
    });
  };
  // Actions
  useEffect(
    () => reloadDocuments(),
    [currentSearch, selectedTypes, viewType, currentPage, sortField, sortOrder]
  );

  const toggleDocumentType = (type: DocumentTypeEnum) => {
    setSelectedTypes((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };

  // Function to open a document in the PDF viewer
  const openDocument = async (document: DocumentItem) => {
    try {
      // Fetch the document using the getDocument API
      const response = await getDocument({ id: document.id });

      // Use the fetched document data with the presigned URL
      setDocViewerState({
        isOpen: true,
        doc: response,
        page: 1,
        citation: null,
      });
    } catch (error) {
      console.error("Error fetching document:", error);
      // Fallback to using the document data we already have
      const docData: SingleDocResponseData = {
        document: {
          id: document.id,
          filename: document.filename,
          documentType: document.documentType,
          // Required properties in DocumentType
          organizationName: "", // Default value
          uploaderEmail: document.userEmail || "",
          createdAt: new Date(document.createdAt * 1000).toISOString(),
          claimNumber: "", // Default value
          claimColor: "", // Default value
        },
        // Use a default URL if presignedUrl is not available
        presignedUrl:
          document.presignedUrl ||
          `${import.meta.env.VITE_API_URL}/api/documents/${
            document.id
          }/download`,
        existingDoc: true,
      };

      setDocViewerState({
        isOpen: true,
        doc: docData,
        page: 1,
        citation: null,
      });
    }
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
        <div className="mb-8 text-center">
          <h1 className="text-3xl text-gray-900">Documents Vault</h1>
          <p className="mt-2 text-lg text-gray-600">
            Manage, organize, and search through your documents
          </p>
        </div>
        <UploadArea
          hideDelete={true}
          onUploadComplete={() => {
            console.log("Upload Complete");
            reloadDocuments();
          }}
        />
        <div className="mb-10 mt-8">
          <Header
            currentSearch={currentSearch}
            setCurrentSearch={setCurrentSearch}
            selectedTypes={selectedTypes}
            toggleDocumentType={toggleDocumentType}
            viewType={viewType}
            setViewType={setViewType}
            itemsPerPage={state.data?.hitsPerPage || 10}
            totalItemsCount={state.data?.found || 0}
            filteredItemsCount={state.data?.hits.length || 0}
          />
        </div>
        <div className="mt-8 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
          {viewType === "list" ? (
            <div className="h-[500px] overflow-auto">
              <ListView
                showLoadingMsg={state.isLoading && !state.isReady}
                documents={state.data?.hits || []}
                sortField={sortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                setSortField={setSortField}
                onDelete={setDocumentToDelete}
                onViewDocument={openDocument}
              />
            </div>
          ) : (
            <ContentView
              documents={state.data?.hits || []}
              currentSearch={currentSearch}
              onViewDocument={openDocument}
            />
          )}
        </div>
        <div className="mb-4 mt-8 flex justify-center">
          <Pagination
            total={state.data?.found || 0}
            page={currentPage}
            setPage={setCurrentPage}
            perPage={state.data?.hitsPerPage || 10}
          />
        </div>
        <DeleteDialog
          documentToDelete={documentToDelete}
          setDocumentToDelete={setDocumentToDelete}
          onSuccessDeleteDocument={() => reloadDocuments()}
        />

        {/* PDF Viewer Dialog */}
        <PDFViewerDialog
          open={isDocViewerOpen}
          doc={selectedDocument}
          initialPage={initialPage}
          setDocViewerState={setDocViewerState}
          citation={citation}
        />
      </div>
    </Worker>
  );
}

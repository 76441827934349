const Pagination = ({ currentRefinement, nbPages, refine }: any) => {
  return (
    <div className="mt-2 flex justify-end gap-2">
      {new Array(nbPages).fill(null).map((_, index) => {
        const page = index + 1;
        return (
          <button
            key={page}
            onClick={() => refine(page)}
            className={`rounded px-3 py-1 ${
              currentRefinement === page ? "bg-black text-white" : "bg-gray-200"
            }`}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;

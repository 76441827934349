import {
  FileText,
  FileSpreadsheet,
  FileImage,
  File,
  type LucideIcon,
} from "lucide-react";

export function getFileIcon(mimeType: string): LucideIcon {
  switch (mimeType) {
    case "application/pdf":
      return FileText;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return FileSpreadsheet;
    case "image/jpeg":
    case "image/png":
    case "image/tiff":
      return FileImage;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return FileText;
    default:
      return File;
  }
}

export function getMimeType(filename: string): string {
  const ext = filename.split(".").pop()?.toLowerCase();

  switch (ext) {
    case "pdf":
      return "application/pdf";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "jpeg":
    case "jpg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "tiff":
    case "tif":
      return "image/tiff";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    default:
      return "application/octet-stream";
  }
}

// import { PromptTemplate } from "../types";
import { PromptTemplate } from "~/api/query_fns/prompt_template";
import Item from "./Item";

interface ListProps {
  list: PromptTemplate[];
  onSelectPrompt: (prompt: PromptTemplate) => void;
  onEditPrompt: (prompt: PromptTemplate) => void;
  onDeletePrompt: (prompt: PromptTemplate) => void;
}

const List: React.FC<ListProps> = ({
  list,
  onSelectPrompt,
  onEditPrompt,
  onDeletePrompt,
}) => {
  return list.length > 0 ? (
    <>
      <div className="px-2 py-1.5 text-sm font-semibold">Saved Prompts</div>
      {list.map((prompt) => (
        <Item
          key={prompt.id}
          prompt={prompt}
          onSelectPrompt={onSelectPrompt}
          onEditPrompt={onEditPrompt}
          onDeletePrompt={onDeletePrompt}
        />
      ))}
    </>
  ) : (
    <div className="px-2 py-1.5 text-sm text-muted-foreground">
      {list.length > 0 ? "No matching prompts" : "No saved prompts"}
    </div>
  );
};

export default List;

import TypesenseConnection from "./Documents/TypesenseConnection";

interface DocumentsProps {
  fileTypes: string[];
  title: string;
}

const Documents: React.FC<DocumentsProps> = ({ fileTypes, title }) => {
  // const _fileTypes = Object.values(DocumentTypeEnum).map(type => type.toString());
  return <TypesenseConnection title={title} selectedTypes={fileTypes} />;
};

export default Documents;

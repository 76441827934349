import SearchView from "~/components/Search/v2/views/SearchView";
import {
  DocumentItem,
  HighlightResult as DocumentHighlightResult,
} from "../types";
import {
  ElementProps,
  HighlightResult as SearchHighlightResult,
} from "~/components/Search/v2/types";

interface ContentViewProps {
  documents: DocumentItem[];
  currentSearch: string;
  onViewDocument: (document: DocumentItem) => Promise<void>;
}

const ContentView: React.FC<ContentViewProps> = ({
  documents,
  currentSearch,
  onViewDocument,
}) => {
  const handleViewDocument = async (document: DocumentItem) => {
    await onViewDocument(document);
  };

  const convertDocumentsToElementProps = (
    documents: DocumentItem[]
  ): ElementProps[] => {
    return documents.map((document) => ({
      id: document.id,
      title: document.filename,
      content: document.content,
      createdAt: document.createdAt,
      filenames: [],
      _highlightResult: document._highlightResult.map(
        (highlight: DocumentHighlightResult): SearchHighlightResult => ({
          field: "content",
          snippets: [highlight.value],
          indices: [],
          matchedTokens: highlight.matchedWords,
        })
      ),
    }));
  };

  return (
    <SearchView
      search={currentSearch}
      list={convertDocumentsToElementProps(documents)}
      linkAction={handleViewDocument}
    />
  );
};

export default ContentView;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatSession, fetchChatSessions } from "~/api/query_fns/chat-api";
import { format } from "date-fns";

interface RecentChatsPanelProps {
  className?: string;
}

const RecentChatsPanel: React.FC<RecentChatsPanelProps> = ({
  className = "",
}) => {
  const [sessions, setSessions] = useState<ChatSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const loadSessions = async () => {
      setLoading(true);
      try {
        const response = await fetchChatSessions({ page, per_page: 5 });
        setSessions(response.chatSessions || []);
        setTotalPages(response.pagination?.totalPages || 1);
      } catch (err) {
        console.error("Failed to fetch chat sessions:", err);
        setError("Failed to load chat history");
      } finally {
        setLoading(false);
      }
    };

    loadSessions();
  }, [page]);

  const handleSessionClick = (chatId: string) => {
    navigate(`/chat/${chatId}`);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const renderContent = () => {
    if (loading && sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <div className="mx-auto h-5 w-5 animate-spin rounded-full border-2 border-[#E5E5E5] border-t-[#000000]"></div>
          <p className="mt-2 text-sm text-[#666666]">Loading chat history...</p>
        </div>
      );
    }

    if (error && sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <p className="text-sm text-[#E63946]">{error}</p>
        </div>
      );
    }

    if (sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <p className="text-sm text-[#666666]">
            No previous chat sessions found
          </p>
        </div>
      );
    }

    return (
      <div className="space-y-4 p-4">
        {sessions.map((session) => (
          <div
            key={session.chatId}
            className="group relative cursor-pointer rounded-lg border border-[#E5E5E5] bg-white p-4 shadow-[0px_1px_3px_rgba(0,0,0,0.1)] transition-all duration-200 hover:border-[#007AFF] hover:shadow-[0px_2px_5px_rgba(0,0,0,0.15)]"
            onClick={() => handleSessionClick(session.chatId)}
          >
            <div className="mb-2 flex items-center justify-between">
              <span className="text-sm text-[#666666]">
                {format(new Date(session.updatedAt), "MMM d, yyyy h:mm a")}
              </span>
            </div>

            <div className="mt-2 flex flex-wrap gap-1">
              {session.documentFilenames &&
              session.documentFilenames.length > 0 ? (
                <>
                  {session.documentFilenames
                    .slice(0, 3)
                    .map((filename, index) => (
                      <div
                        key={`${session.chatId}-${index}`}
                        className="flex items-center gap-1 whitespace-nowrap rounded-md border border-[#E5E5E5] bg-[#FAFAFA] px-2 py-1 text-xs text-[#1E1E1E]"
                      >
                        <span className="max-w-[150px] truncate">
                          {filename}
                        </span>
                      </div>
                    ))}
                  {session.documentFilenames.length > 3 && (
                    <button
                      className="px-2 py-1 text-xs text-[#007AFF] hover:underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        // You can implement a modal or expand functionality here
                        alert(
                          `All documents: ${session.documentFilenames.join(
                            ", "
                          )}`
                        );
                      }}
                    >
                      +{session.documentFilenames.length - 3} more
                    </button>
                  )}
                </>
              ) : (
                <div className="text-xs text-[#666666]">
                  Chat without documents
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`flex h-full flex-col ${className}`}>
      <div className="w-full flex-1 overflow-y-auto">
        {renderContent()}

        {totalPages > 1 && (
          <div className="sticky bottom-0 flex items-center justify-between border-t border-[#E5E5E5] bg-white p-4">
            <button
              className="rounded-lg border border-[#E5E5E5] bg-white px-3 py-1 text-sm text-[#000000] transition-colors duration-200 hover:bg-[#FAFAFA] disabled:opacity-50 disabled:hover:bg-white"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className="text-sm text-[#666666]">
              Page {page} of {totalPages}
            </span>
            <button
              className="rounded-lg border border-[#E5E5E5] bg-white px-3 py-1 text-sm text-[#000000] transition-colors duration-200 hover:bg-[#FAFAFA] disabled:opacity-50 disabled:hover:bg-white"
              onClick={handleNextPage}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentChatsPanel;

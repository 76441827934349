import { ReactNode } from "react";

interface SectionalContainerProps {
  title: string;
  icon?: ReactNode;
  children: ReactNode;
  headerRight?: ReactNode;
  contentClassName?: string;
  maxContentHeight?: string;
}

/**
 * SectionalContainer component
 *
 * A reusable container component with a styled header section following the Qumis design system.
 * Used for creating sectional containers in the application.
 *
 * @param title - The title of the section
 * @param icon - Optional icon to display next to the title
 * @param children - Content to display in the container body
 * @param headerRight - Optional content to display on the right side of the header
 * @param contentClassName - Optional additional classes for the content section
 * @param maxContentHeight - Optional max height for the content section (with scrolling)
 */
const SectionalContainer = ({
  title,
  icon,
  children,
  headerRight,
  contentClassName = "",
  maxContentHeight,
}: SectionalContainerProps) => {
  return (
    <div className="flex h-full flex-col overflow-hidden rounded-lg border border-[#E5E5E5] bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.1)]">
      <div className="border-b border-[#E5E5E5] bg-[#FAFAFA] p-5">
        <div className="flex items-center justify-between">
          <h2 className="flex items-center text-xl font-medium text-[#000000]">
            {icon && (
              <span className="mr-2 h-5 w-5 text-[#1E1E1E]">{icon}</span>
            )}
            {title}
          </h2>
          {headerRight && headerRight}
        </div>
      </div>
      <div
        className={`custom-scrollbar flex-1 overflow-y-auto p-4 ${contentClassName}`}
        style={maxContentHeight ? { maxHeight: maxContentHeight } : undefined}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionalContainer;

import {
  destroyPromptTemplate,
  postPromptTemplate,
  PromptGroupType,
  PromptTemplate,
  putPromptTemplate,
} from "~/api/query_fns/prompt_template";
import { toast } from "react-hot-toast";

export const createPrompt = async (
  prompt: PromptTemplate,
  callback: () => void
) => {
  try {
    const data = await postPromptTemplate({
      name: prompt.name,
      description: prompt.description,
      shared: prompt.shared,
      is_owned_by_current_user: prompt.isOwnedByCurrentUser,
      prompt_group: prompt.promptGroup as PromptGroupType,
    });

    console.log("Create successful", data);
  } catch (error) {
    console.error("Error creating template:", error);
    toast.error("Failed to share the template");
  } finally {
    callback();
  }
};

export const updatePrompt = async (
  prompt: PromptTemplate,
  callback: () => void
) => {
  try {
    const data = await putPromptTemplate({
      id: prompt.id,
      name: prompt.name,
      description: prompt.description,
      shared: prompt.shared,
      is_owned_by_current_user: prompt.isOwnedByCurrentUser,
      prompt_group: prompt.promptGroup as PromptGroupType,
    });

    console.log("Update successful", data);
  } catch (error) {
    console.error("Error updating template:", error);
    toast.error("Failed to share the template");
  } finally {
    callback();
  }
};

export const destroyPrompt = async (
  prompt: PromptTemplate,
  callback: () => void
) => {
  try {
    await destroyPromptTemplate({ id: prompt.id });
    console.log("Delete successful");
  } catch (error) {
    console.error("Error deleting template:", error);
  } finally {
    callback();
  }
};

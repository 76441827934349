import { Dispatch } from "react";
import { Action, DefaultState } from "./types";

type ReducerProps<DataParams> = {
  state: DefaultState<DataParams>;
  dispatch: Dispatch<Action<DataParams>>;
  connection: (params: any) => Promise<DataParams>;
};

const loadData =
  <DataParams>({ state, dispatch, connection }: ReducerProps<DataParams>) =>
  async (requestParams: any) => {
    const storeType = state.initialData
      ? "SET_RELOADED_DATA"
      : "SET_ORIGINAL_DATA";

    try {
      dispatch({ type: "SET_LOADING", value: true });
      const data = await connection(requestParams);
      dispatch({ type: storeType, data: data as unknown as DataParams });
    } catch (error) {
      dispatch({ type: "SET_ERROR", value: error as string });
    } finally {
      dispatch({ type: "SET_LOADING", value: false });
      if (!state.isReady) {
        dispatch({ type: "SET_READY", value: true });
      }
    }
  };

export default { loadData };

import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useLoaderData } from "react-router-dom";
import {
  ArtifactFetchResponse,
  fetchJobReport,
  findJobReportByEmail,
  ReportType,
} from "~/api/query_fns/coverage-analysis";
import { ReportTabs } from "~/components/ReportTabs";
import { EmailReportDisclaimer } from "~/components/EmailReportDisclaimer";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { useState, useRef, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useOpenReferenceDetails } from "../doc-util";
import { CustomChat } from "~/components/util-chat";
import {
  generateInitialMessage,
  generateChatContext,
} from "~/utils/util-report";
import { Message } from "../components/CustomMessages";
import { fetchDocumentTexts } from "~/utils";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { Citation } from "~/api/query_fns/citations-api";
import { useBreadcrumb } from "~/context/BreadcrumbContext";

interface LoaderParams {
  params: {
    jobId?: string;
  };
}

type FetchJobReportLoaderResponse = {
  id: string;
  from: string;
  reportName: string;
  reportKey: string;
  reportType: ReportType;
  documents: SingleDocResponseData[];
  coverageReport: string;
  followOnQuestions: string[];
  processingStatus: string;
  citations: Citation[];
  oId: string;
  artifacts?: ArtifactFetchResponse[];
};

export const loader = async ({ params }: LoaderParams) => {
  const report = await fetchJobReport({ jobId: params.jobId || "" });
  await fetchDocumentTexts(report.documents);

  return report;
};

const GenReportPublic = () => {
  const [userId, setUserId] = useState("");
  const [locked, setLocked] = useState(true);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
  const [content, setContent] = useState("AccessReportContent");
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [email, setEmail] = useState("");
  const [warningText, setWarningText] = useState("");
  const [reportChatMessages, setReportChatMessages] = useState<Message[]>([]);
  const [reportTabsWidth, setReportTabsWidth] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const reportTabsRef = useRef<HTMLDivElement>(null);
  const { setReportTitle } = useBreadcrumb();

  const appendToReportChatMessages = (newMessages: Message[]) => {
    setReportChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  const {
    id,
    from,
    reportName,
    reportKey,
    reportType,
    documents,
    coverageReport,
    followOnQuestions,
    processingStatus,
    citations,
    oId,
    artifacts,
  } = useLoaderData() as FetchJobReportLoaderResponse;

  // Set the report title in the breadcrumb context
  useEffect(() => {
    setReportTitle(reportName);

    // Clean up the report title when unmounting
    return () => {
      setReportTitle(null);
    };
  }, [reportName, setReportTitle]);

  const unlock = (email: string) => {
    setWarningText("");
    setEmail(email.toLowerCase());
  };

  useEffect(() => {
    // Reset queryExecuted to false whenever the email changes
    setQueryExecuted(false);
  }, [email]); // Dependency array includes email to trigger effect when email changes

  useQuery(
    ["findJobReportByEmail", email, id],
    () => findJobReportByEmail({ email, report_email_job_id: id }),
    {
      onSuccess: (data) => {
        if (data?.unlock) {
          setUserId(data?.userId);
          setLocked(false);
          setContent("RenderSecurityContent");
        } else {
          setEmail("");
          setWarningText("* This email does not have access to the report.");
        }
        setQueryExecuted(true); // Set to true after successful fetch
      },
      enabled: !!email && !!id && !queryExecuted, // Only enable if email and id are set and query has not been executed
    }
  );

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  } = useOpenReferenceDetails(documents, citations || []);

  const context = generateChatContext(
    documents.map((doc) => doc.document),
    coverageReport
  );

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  // console.log("Policy File Name: ", policyDetails.fileName);
  // console.log("Policy Presigned URL: ", policyDetails.presignedUrl);
  // console.log("Fact File Name: ", factDetails.fileName);
  // console.log("Fact Presigned URL: ", factDetails.presignedUrl);

  const initialMessage = generateInitialMessage(
    reportType,
    from,
    followOnQuestions
  );

  // Handle the start of dragging
  const handleDragStart = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  // Handle dragging
  const handleDrag = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const mouseX = e.clientX - containerRect.left;

      // Calculate percentage width (with limits to prevent panels from becoming too small)
      const minWidth = 250; // Minimum width in pixels
      const maxWidth = containerWidth - minWidth;

      const clampedX = Math.max(minWidth, Math.min(mouseX, maxWidth));
      const newWidthPercent = (clampedX / containerWidth) * 100;

      setReportTabsWidth(newWidthPercent);
    },
    [isDragging]
  );

  // Handle the end of dragging
  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Add event listeners for drag events
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [isDragging, handleDrag, handleDragEnd]);

  return (
    <div
      id="report-container"
      className="relative mx-auto flex w-full max-w-[100vw] flex-col gap-4 lg:flex-row"
      ref={containerRef}
    >
      <div
        id="document-container"
        className="w-full lg:w-auto"
        ref={reportTabsRef}
        style={{
          width: reportTabsWidth ? `${reportTabsWidth}%` : "60%",
          flexShrink: 0,
        }}
      >
        <PDFViewerDialog
          open={isDocViewerOpen}
          doc={document}
          initialPage={initialPage}
          setDocViewerState={setDocViewerState}
          citation={citation}
        />

        <div className="mx-auto w-full">
          <ReportTabs
            reportName={reportName}
            fullCoverageReport={coverageReport}
            docs={documents}
            openReference={openReference}
            reportChatMessages={reportChatMessages}
            reportType={reportType as ReportType}
            documents={documents}
            artifacts={artifacts}
            reportId={id}
          />
          <EmailReportDisclaimer
            setContent={setContent}
            content={content}
            locked={locked}
            isDisclaimerOpen={isDisclaimerOpen}
            setIsDisclaimerOpen={setIsDisclaimerOpen}
            onClose={() => console.log("Disclaimer closed")}
            unlock={unlock}
            warningText={warningText}
            processingStatus={processingStatus}
          />
        </div>
      </div>

      {/* Resizable divider with gap preserved */}
      <div
        className="relative mx-2 flex items-center justify-center"
        style={{ cursor: isDragging ? "col-resize" : "default" }}
      >
        <div className="h-[calc(100vh-4rem)] w-0.5 bg-gray-100"></div>
        <div
          className="absolute top-1/2 z-10 flex h-12 w-5 -translate-y-1/2 cursor-col-resize items-center justify-center rounded-sm bg-gray-100 shadow-sm hover:bg-gray-200"
          onMouseDown={handleDragStart}
        >
          <div className="flex flex-col items-center gap-1">
            <div className="h-2.5 w-0.5 rounded-full bg-gray-400"></div>
            <div className="h-2.5 w-0.5 rounded-full bg-gray-400"></div>
          </div>
        </div>
      </div>

      <div
        id="chat-container"
        className="flex h-[calc(100vh-4rem)] flex-grow flex-col overflow-hidden"
        style={{
          width: reportTabsWidth ? `${100 - reportTabsWidth - 2}%` : "40%",
        }}
      >
        <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
          <CustomChat
            context={context}
            initialMessage={initialMessage}
            reportId={id}
            documents={documents}
            userId={userId}
            reportKey={reportKey}
            openReference={openReference}
            reportSource="email_report"
            appendToReportChatMessages={appendToReportChatMessages}
            oId={oId}
            addCitations={addCitations}
          />
        </CopilotKit>
      </div>
    </div>
  );
};

export default GenReportPublic;

import { Link } from "react-router-dom";
import { useState } from "react";
import { DocumentTypeEnum, getDocuments } from "~/api";
import { useQuery } from "react-query";

import DocumentCard from "~/components/DocumentCard";
import Pagination from "~/components/Pagination";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";

interface PolicyListProps {
  linkPrefix: string;
  itemsPerPage: number;
}

const UCPolicyListComponent: React.FC<PolicyListProps> = ({
  linkPrefix,
  itemsPerPage,
}) => {
  const [page, setPage] = useState<number>(1);

  const documentsQuery = useQuery({
    queryKey: ["policyDocuments", page],
    queryFn: () =>
      getDocuments({
        page: page,
        document_type: DocumentTypeEnum.Policy,
        uploader: "me",
      }),
    refetchOnWindowFocus: false,
  });

  const hasDocuments =
    documentsQuery.data?.documents && documentsQuery.data.documents.length > 0;

  return (
    <div className="flex h-full flex-col space-y-4 pt-4">
      <Heading
        as="h2"
        className="text-black-100 flex flex-row items-center gap-2 text-2xl font-semibold"
      >
        My Policies
      </Heading>
      {hasDocuments && (
        <Paragraph variant="small" className="text-muted-foreground/75">
          Showing {Math.min(documentsQuery.data?.total ?? 0, itemsPerPage)} of{" "}
          {documentsQuery.data?.total ?? 0} Policies
        </Paragraph>
      )}

      <div className="relative flex-grow">
        {hasDocuments ? (
          <div className="absolute inset-0 grid gap-4 overflow-y-auto px-2">
            {documentsQuery.data?.documents.map((doc) => (
              <Link
                to={`${linkPrefix}${doc.id}`}
                key={doc.id}
                className="block transform-gpu"
              >
                <DocumentCard doc={doc} />
              </Link>
            ))}
          </div>
        ) : (
          <div className="flex h-full items-center justify-center">
            <Paragraph className="text-lg text-muted-foreground/90">
              No policies available.
            </Paragraph>
          </div>
        )}
      </div>

      {hasDocuments && (
        <div className="mt-auto pt-4">
          <Pagination
            setPage={(p) => setPage(p)}
            total={documentsQuery.data?.total ?? 0}
            page={page}
            perPage={itemsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default UCPolicyListComponent;

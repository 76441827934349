import { Checkbox } from "~/components/ui/checkbox";
import { Label } from "~/components/ui/label";
import { ReportOptionEnum } from "~/api/query_fns/coverage-analysis";
import { Card } from "~/components/ui/card";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

interface ReportOptionsProps {
  reportOptions: ReportOptionEnum[];
  toggleReportOption: (option: ReportOptionEnum) => void;
  reportType: string;
  reportSubType?: string;
}

const ReportOptions = ({
  reportOptions,
  toggleReportOption,
  reportType,
  reportSubType: propReportSubType,
}: ReportOptionsProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlReportSubType = searchParams.get("reportSubType");

  const shouldShowOptions = (optionPrefix: string) => {
    return reportType.includes(optionPrefix);
  };

  // Use the prop value if provided, otherwise use the URL parameter
  const reportSubType = propReportSubType || urlReportSubType;
  const isAuditMode = reportSubType === "audit";
  const isCoverageMode = reportSubType === "coverage";
  const isActiveMode = reportSubType === "active";

  // Auto-select options based on report subtype, but only on initial render
  useEffect(() => {
    // Use a ref to track if this is the initial render
    const isInitialRender = true;

    if (isInitialRender) {
      if (isCoverageMode) {
        // For coverage subtype, auto-select ROR and Next Steps initially
        if (!reportOptions.includes(ReportOptionEnum.POLICY_FACT_LETTER_ROR)) {
          toggleReportOption(ReportOptionEnum.POLICY_FACT_LETTER_ROR);
        }
        if (
          !reportOptions.includes(ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM)
        ) {
          toggleReportOption(ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM);
        }
      } else if (isActiveMode) {
        // For active subtype, auto-select Next Steps
        if (
          !reportOptions.includes(ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM)
        ) {
          toggleReportOption(ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM);
        }
      }
    }

    // Empty dependency array ensures this only runs once on mount
  }, []);

  const checkboxClass =
    "h-5 w-5 rounded-sm border-2 border-gray-300 data-[state=checked]:bg-black data-[state=checked]:border-black focus:outline-none focus:ring-0 focus:ring-offset-0 transition-colors";

  // Add descriptions for each option
  const optionDescriptions = {
    [ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM]:
      "Include recommended next steps for handling this claim",
    [ReportOptionEnum.POLICY_FACT_AUDIT_CLAIM]:
      "Include a detailed audit of a closed claim",
    [ReportOptionEnum.POLICY_FACT_LETTER_ROR]:
      "Generate a letter reserving the insurer's rights during claim investigation",
    [ReportOptionEnum.POLICY_FACT_LETTER_FNOL]:
      "Generate a First Notice of Loss letter",
    [ReportOptionEnum.POLICY_FACT_LETTER_DENIAL]:
      "Generate a claim denial letter with explanation of decision",
    [ReportOptionEnum.POLICY_FACT_LETTER_REBUTTAL]:
      "Generate a response to policyholder's objection to claim decision",
  };

  return (
    <Card className="border-border bg-background p-6 shadow-sm">
      {shouldShowOptions("policy_fact") && (
        <div className="space-y-5">
          <div className="space-y-1.5">
            <p className="text-lg font-semibold">Report Options</p>
            <p className="text-sm text-muted-foreground">
              Include the following items in the report:
            </p>
          </div>

          <div className="grid grid-cols-2 gap-x-4 gap-y-4">
            {/* Only show Audit Claim option in audit mode */}
            {isAuditMode ? (
              <div className="flex items-start space-x-3.5">
                <Checkbox
                  id="audit-claim"
                  checked={true} // Always checked in audit mode
                  disabled={true} // Cannot be unchecked
                  className={`${checkboxClass} mt-1`}
                />
                <div>
                  <Label
                    htmlFor="audit-claim"
                    className="cursor-pointer text-sm font-medium"
                  >
                    Audit Claim
                  </Label>
                  <p className="mt-0.5 text-xs text-muted-foreground">
                    {
                      optionDescriptions[
                        ReportOptionEnum.POLICY_FACT_AUDIT_CLAIM
                      ]
                    }
                  </p>
                </div>
              </div>
            ) : (
              <>
                {/* Show all other options when not in audit mode, EXCEPT the audit option */}
                <div className="flex items-start space-x-3.5">
                  <Checkbox
                    id="ongoing-claim"
                    checked={reportOptions.includes(
                      ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM
                    )}
                    onCheckedChange={() =>
                      toggleReportOption(
                        ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM
                      )
                    }
                    disabled={isActiveMode} // Disable checkbox in active mode
                    className={`${checkboxClass} mt-1`}
                  />
                  <div>
                    <Label
                      htmlFor="ongoing-claim"
                      className="cursor-pointer text-sm font-medium"
                    >
                      Include Next Steps
                    </Label>
                    <p className="mt-0.5 text-xs text-muted-foreground">
                      {
                        optionDescriptions[
                          ReportOptionEnum.POLICY_FACT_ONGOING_CLAIM
                        ]
                      }
                    </p>
                  </div>
                </div>
                {/* Removed the Audit Claim option from here */}
                <div className="flex items-start space-x-3.5">
                  <Checkbox
                    id="letter-ror"
                    checked={reportOptions.includes(
                      ReportOptionEnum.POLICY_FACT_LETTER_ROR
                    )}
                    onCheckedChange={() =>
                      toggleReportOption(
                        ReportOptionEnum.POLICY_FACT_LETTER_ROR
                      )
                    }
                    className={`${checkboxClass} mt-1`}
                  />
                  <div>
                    <Label
                      htmlFor="letter-ror"
                      className="cursor-pointer text-sm font-medium"
                    >
                      Reservation of Rights Letter
                    </Label>
                    <p className="mt-0.5 text-xs text-muted-foreground">
                      {
                        optionDescriptions[
                          ReportOptionEnum.POLICY_FACT_LETTER_ROR
                        ]
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-3.5">
                  <Checkbox
                    id="letter-fnol"
                    checked={reportOptions.includes(
                      ReportOptionEnum.POLICY_FACT_LETTER_FNOL
                    )}
                    onCheckedChange={() =>
                      toggleReportOption(
                        ReportOptionEnum.POLICY_FACT_LETTER_FNOL
                      )
                    }
                    className={`${checkboxClass} mt-1`}
                  />
                  <div>
                    <Label
                      htmlFor="letter-fnol"
                      className="cursor-pointer text-sm font-medium"
                    >
                      FNOL Letter
                    </Label>
                    <p className="mt-0.5 text-xs text-muted-foreground">
                      {
                        optionDescriptions[
                          ReportOptionEnum.POLICY_FACT_LETTER_FNOL
                        ]
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-3.5">
                  <Checkbox
                    id="letter-denial"
                    checked={reportOptions.includes(
                      ReportOptionEnum.POLICY_FACT_LETTER_DENIAL
                    )}
                    onCheckedChange={() =>
                      toggleReportOption(
                        ReportOptionEnum.POLICY_FACT_LETTER_DENIAL
                      )
                    }
                    className={`${checkboxClass} mt-1`}
                  />
                  <div>
                    <Label
                      htmlFor="letter-denial"
                      className="cursor-pointer text-sm font-medium"
                    >
                      Denial Letter
                    </Label>
                    <p className="mt-0.5 text-xs text-muted-foreground">
                      {
                        optionDescriptions[
                          ReportOptionEnum.POLICY_FACT_LETTER_DENIAL
                        ]
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-3.5">
                  <Checkbox
                    id="letter-rebuttal"
                    checked={reportOptions.includes(
                      ReportOptionEnum.POLICY_FACT_LETTER_REBUTTAL
                    )}
                    onCheckedChange={() =>
                      toggleReportOption(
                        ReportOptionEnum.POLICY_FACT_LETTER_REBUTTAL
                      )
                    }
                    className={`${checkboxClass} mt-1`}
                  />
                  <div>
                    <Label
                      htmlFor="letter-rebuttal"
                      className="cursor-pointer text-sm font-medium"
                    >
                      Rebuttal Letter
                    </Label>
                    <p className="mt-0.5 text-xs text-muted-foreground">
                      {
                        optionDescriptions[
                          ReportOptionEnum.POLICY_FACT_LETTER_REBUTTAL
                        ]
                      }
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ReportOptions;

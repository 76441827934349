import { ElementProps, HighlightResult, DocumentItem } from "../types";
import ListView from "../views/ListView";
import SearchView from "../views/SearchView";

type formattedDocumentItem = DocumentItem & {
  _highlightResult?: HighlightResult[];
};

interface ViewContainerProps {
  showLoadingMsg: boolean;
  filteredList: DocumentItem[];
  viewMode: "list" | "search";
  search: string;
}

const convertDocumentItemToElementProps = (
  item: formattedDocumentItem
): ElementProps => {
  return {
    id: item.id,
    title: item.filename || "Document Name - Empty",
    createdAt: item.createdAt,
    filenames: [],
    content: item.content,
    _highlightResult: item._highlightResult || [],
  };
};

const ViewContainer: React.FC<ViewContainerProps> = ({
  showLoadingMsg,
  filteredList,
  viewMode,
  search,
}) => {
  const formattedList = filteredList.map(convertDocumentItemToElementProps);

  if (showLoadingMsg) {
    return (
      <div className="py-8 text-center text-muted-foreground">Loading...</div>
    );
  }

  if (formattedList.length === 0) {
    return (
      <div className="py-8 text-center text-muted-foreground">No Results</div>
    );
  }

  return viewMode === "list" ? (
    <ListView list={formattedList} search={search} />
  ) : (
    <SearchView list={formattedList} search={search} />
  );
};

export default ViewContainer;

import React, { FC, useState, Dispatch, SetStateAction } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { FileText, Maximize2, Minimize2, Download } from "lucide-react";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/card";
import { CategoryFilterMenu } from "./custommarkdowncustomchecklist/CategoryFilterMenu";
import { StatusMetricsGrid } from "./custommarkdowncustomchecklist/StatusMetricGrid";
import {
  StatusType,
  STATUS_MAPPINGS,
  isValidCategory,
} from "./custommarkdowncustomchecklist/util-customchecklist";
import { ReferenceLink } from "./CustomMarkdown";
import ExcelJS from "exceljs";

export type ChartContextType = {
  reportId?: string;
  documentId?: string;
};

// Update the context creation
export const ChartContext = React.createContext<ChartContextType>({});

// First, add this keyframe at the top of your file, after the imports
const pulseKeyframe = `
  @keyframes subtle-pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.35);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

// Type for status mapping
type StatusMapping = {
  label: string;
  color: string;
  letter: string;
};

// Helper function types
const getStatusFromElement = (element: React.ReactNode): string => {
  if (!element) return "";

  if (Array.isArray(element)) {
    element = element[0];
  }

  if (!React.isValidElement(element)) return "";

  const key = element.key as string;
  if (!key) return "";

  const status = key.split("-")[0];

  // Map status values
  switch (status) {
    case "Match":
      return "Match";
    case "Partial":
      return "Partial";
    case "Mismatch":
      return "Mismatch";
    case "N/A":
    case "NA":
      return "NA";
    default:
      return "";
  }
};

// Add this helper function near the top of the file, after the imports
const getCategoryStatusCounts = (
  rows: React.ReactNode[][],
  category: string
) => {
  return rows.reduce((acc, row) => {
    const rowCategory = String(row[0]);

    if (rowCategory === "Category" || rowCategory === "---") {
      return acc;
    }

    const isUncategorized =
      !rowCategory || rowCategory.trim() === "" || rowCategory === "undefined";
    const categoryMatches = isUncategorized
      ? category === "Un-Categorized"
      : rowCategory === category;

    if (categoryMatches) {
      const statusCell = row[row.length - 2];
      const status = getStatusFromElement(statusCell);
      acc[status] = (acc[status] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);
};

// Add this helper function to get total status counts
const getTotalStatusCounts = (rows: React.ReactNode[][]) => {
  return rows.reduce((acc, row) => {
    const category = String(row[0]);

    if (category === "Category" || category === "---") {
      return acc;
    }

    const statusCell = row[row.length - 2];
    const status = getStatusFromElement(statusCell);
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
};

// Update the getColumnWidth function for even better proportions
const getColumnWidth = (index: number, totalColumns: number): string => {
  // First column (Category)
  if (index === 0) return "w-[15%]";

  // Status columns (first visible and second-to-last)
  if (index === 1 || index === totalColumns - 1) {
    return "w-[20%]";
  }

  // Subcategory/Type column (second column)
  if (index === 2) {
    return "w-[18%]";
  }

  // Element column (middle column)
  return "w-[30%]";
};

// Type for category
interface Category {
  name: string;
  count: number;
}

// Props type for TableHeaderSection
interface TableHeaderSectionProps {
  categories: Category[];
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  isFullScreen: boolean;
  setFullScreen: (value: boolean) => void;
  statusCounts: Record<string, number>;
  selectedStatuses: string[];
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>;
  headers: React.ReactNode[];
  rows: React.ReactNode[][];
  reportId: string;
}

const TableHeaderSection: FC<TableHeaderSectionProps> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  isFullScreen,
  setFullScreen,
  selectedStatuses,
  setSelectedStatuses,
  headers,
  rows,
  reportId,
}) => {
  const allCategories = categories.map((cat) => cat.name);
  const isAllSelected = selectedCategories.length === categories.length;

  const handleToggleCategory = (categoryName: string) => {
    if (categoryName === "all") {
      setSelectedCategories(isAllSelected ? [] : allCategories);
    } else {
      setSelectedCategories([categoryName]);
    }
  };

  const handleStatusToggle = (status: string) => {
    setSelectedStatuses((current) =>
      current.includes(status)
        ? current.filter((s) => s !== status)
        : [...current, status]
    );
  };

  // Add a new memo for total counts that doesn't depend on filters
  const totalStatusCounts = React.useMemo(() => {
    const counts = rows.reduce((acc, row) => {
      const statusCell = row[row.length - 2];
      const status = getStatusFromElement(statusCell);

      if (status) {
        acc[status] = (acc[status] || 0) + 1;
        acc.total = (acc.total || 0) + 1;
      }

      return acc;
    }, {} as Record<string, number>);

    return counts;
  }, [rows]); // Only depends on rows, not on filters

  const handleExcelDownload = async (reportId: string) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Table Data");

    // Helper function to safely extract text from React elements
    const extractText = (node: React.ReactNode): string => {
      if (React.isValidElement(node)) {
        return extractText(node.props.children);
      }
      if (Array.isArray(node)) {
        return node.map(extractText).join("");
      }
      return node?.toString() || "";
    };

    // Add headers
    const headerRow = worksheet.addRow(headers.map(extractText));

    // Identify the "status" column index
    const statusColumnIndex = headers.findIndex((header) =>
      extractText(header).toLowerCase().startsWith("status")
    );

    // Log the index of the "status" column
    console.log(`Status column index: ${statusColumnIndex}`);

    // Apply filters to the headers
    worksheet.autoFilter = {
      from: "A1",
      to: { row: 1, column: headers.length },
    };

    // Add rows and apply row-specific formatting
    rows.forEach((row, rowIndex) => {
      const firstCellText = extractText(row[0]);

      // Exclude rows starting with "Category" or "---"
      if (firstCellText === "Category" || firstCellText === "---") {
        console.log("Skipping row", rowIndex);
        return;
      }

      // Convert the status and update the row
      convertExcelStatusToSTATUSMAPPING(
        statusColumnIndex,
        extractText,
        row,
        rowIndex
      );

      // Create the Excel row using the updated row data
      const excelRow = worksheet.addRow(row.map(extractText));

      // Apply row formatting based on the converted status
      const status = row[statusColumnIndex] as keyof typeof STATUS_MAPPINGS;
      console.log(`Row ${rowIndex}, Status: ${status}`);

      if (STATUS_MAPPINGS[status]) {
        const fillColor = STATUS_MAPPINGS[status]["excelColor"] || "FFFF00";
        console.log("fillColor", fillColor);
        excelRow.eachCell((cell, colNumber) => {
          const cellValue = cell.value as string;

          // Regular expressions to match dollar amounts and percentages
          const dollarRegex = /\$([\d,]+(\.\d{1,2})?)/;
          const percentRegex = /([\d,]+(\.\d{1,2})?)%/;

          // Check and convert dollar amounts
          const dollarMatch = cellValue.match(dollarRegex);
          if (dollarMatch) {
            const numericValue = parseFloat(dollarMatch[1].replace(/,/g, ""));
            cell.value = numericValue;
            cell.numFmt = '"$"#,##0.00'; // Format as currency
          }

          // Check and convert percentages
          const percentMatch = cellValue.match(percentRegex);
          if (percentMatch) {
            const numericValue =
              parseFloat(percentMatch[1].replace(/,/g, "")) / 100;
            cell.value = numericValue;
            cell.numFmt = "0.00%"; // Format as percentage
          }

          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: `FF${fillColor.slice(1)}` },
          };
          cell.alignment = { wrapText: true };

          if (colNumber === 1 || colNumber === 2) {
            cell.font = {
              color: { argb: "FF000000" },
              bold: true,
              size: 12,
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFEFEFEF" },
            };
            cell.border = {
              top: { style: "thin", color: { argb: "FFCCCCCC" } },
              left: { style: "thin", color: { argb: "FFCCCCCC" } },
              bottom: { style: "thin", color: { argb: "FFCCCCCC" } },
              right: { style: "thin", color: { argb: "FFCCCCCC" } },
            };
          }
        });
      }
    });

    // Style the headers
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FF333333" } }; // Dark gray text for better readability
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" }, // Light gray background for a clean look
      };
      cell.alignment = {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
      }; // Center align text
    });

    // Set column widths
    worksheet.columns.forEach((column) => {
      column.width = 30; // Adjust this value as needed
    });

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${reportId}_checklist.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="flex flex-col border-b border-border/30 bg-background/60 backdrop-blur-md backdrop-saturate-150 supports-[backdrop-filter]:bg-background/40">
      <div className="flex items-center justify-between border-b border-border/30 p-6 pb-2">
        <h3 id="comparison-dashboard" className="text-md font-semibold">
          Comparison Dashboard
        </h3>

        <div className="flex items-center gap-2">
          <CategoryFilterMenu
            categories={categories}
            selectedCategories={selectedCategories}
            onCategoryToggle={handleToggleCategory}
            rows={rows}
            getCategoryStatusCounts={getCategoryStatusCounts}
            getTotalStatusCounts={getTotalStatusCounts}
          />

          <Button
            variant="outline"
            onClick={() => handleExcelDownload(reportId)}
            className="flex h-11 items-center gap-2 border-2 px-5 font-medium transition-all 
              duration-200 hover:scale-105 hover:bg-muted hover:shadow-md
              active:scale-95"
          >
            <Download className="h-5 w-5 text-foreground" />
            <span className="text-sm font-semibold">Download Excel</span>
          </Button>

          <Button
            variant="outline"
            onClick={() => setFullScreen(!isFullScreen)}
            className="flex h-11 items-center gap-2 border-2 px-5 font-medium transition-all 
              duration-200 hover:scale-105 hover:bg-muted hover:shadow-md
              active:scale-95"
          >
            {isFullScreen ? (
              <>
                <span className="text-sm font-semibold">Minimize Table</span>
                <Minimize2 className="h-5 w-5 text-foreground" />
              </>
            ) : (
              <>
                <span className="text-sm font-semibold">Expand Table</span>
                <Maximize2 className="h-5 w-5 text-foreground" />
              </>
            )}
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-[1fr,auto] gap-4 border-b border-border/30 bg-muted/5 p-6">
        <StatusMetricsGrid
          statusCounts={totalStatusCounts}
          selectedStatuses={selectedStatuses as StatusType[]}
          onStatusToggle={handleStatusToggle}
        />
      </div>

      <TableHeaderColumns headers={headers} />
    </div>
  );
};

interface TableHeaderColumnsProps {
  headers: React.ReactNode[];
}

// Update TableHeaderColumns to remove the last column
const TableHeaderColumns: FC<TableHeaderColumnsProps> = ({ headers }) => (
  <div className="sticky top-0 z-50 border-b border-border/30 bg-background/60 shadow-sm backdrop-blur-md backdrop-saturate-150 supports-[backdrop-filter]:bg-background/40">
    <Table className="w-full table-fixed">
      <TableHeader>
        <TableRow className="border-none">
          {headers.slice(0, -1).map(
            (
              header,
              index // Remove last header
            ) => (
              <TableHead
                key={index}
                className={`px-6 py-4 text-center first:pl-8 last:pr-8 ${getColumnWidth(
                  index,
                  headers.length - 1 // Adjust total length
                )}`}
              >
                <div className="flex items-center justify-center">
                  <span className="whitespace-normal break-words text-center text-[13px] font-semibold uppercase tracking-wider text-foreground/70">
                    {header}
                  </span>
                </div>
              </TableHead>
            )
          )}
        </TableRow>
      </TableHeader>
    </Table>
  </div>
);

const CustomMarkdownCheckListTable: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
  selectedText?: string;
  reportId: string;
}> = ({ children, openReference, reportId }) => {
  const [isFullScreen, setFullScreen] = React.useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const regex = /\[(.*?)\]/g;
  const [selectedStatuses, setSelectedStatuses] = useState<StatusType[]>([]);

  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = pulseKeyframe;
    document.head.appendChild(styleSheet);
    return () => styleSheet.remove();
  }, []);

  // Add escape key handler
  React.useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isFullScreen) {
        setFullScreen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isFullScreen]);

  const processText = (text: unknown): React.ReactNode => {
    if (typeof text !== "string") {
      if (React.isValidElement(text)) {
        return React.cloneElement(text as React.ReactElement, {
          children: processText((text as React.ReactElement).props.children),
        });
      }
      return text as React.ReactNode;
    }

    const statusMappings: Record<string, StatusMapping> = {
      Match: {
        label: "●",
        letter: "M",
        color:
          "bg-emerald-50 text-emerald-500 border-emerald-200 hover:bg-emerald-100 hover:border-emerald-300",
      },
      Partial: {
        label: "◐",
        letter: "P",
        color:
          "bg-amber-50 text-amber-500 border-amber-200 hover:bg-amber-100 hover:border-amber-300",
      },
      Mismatch: {
        label: "○",
        letter: "X",
        color:
          "bg-red-50 text-red-500 border-red-200 hover:bg-red-100 hover:border-red-300",
      },
      NA: {
        // Change from 'N/A' to 'NA'
        label: "−",
        letter: "NA",
        color:
          "bg-slate-50 text-slate-500 border-slate-200 hover:bg-slate-100 hover:border-slate-300",
      },
    };

    const cleanText = text.replace(/<br\s*\/?>/gi, " ");
    const parts = cleanText.split(regex);
    return parts.map((part, index) => {
      const status = statusMappings[part];
      if (status) {
        return (
          <Tooltip key={`${part}-${index}`}>
            <TooltipTrigger>
              <span
                className={`
                  inline-flex h-6 w-6 
                  cursor-default items-center justify-center rounded-full border text-base 
                  font-medium transition-all duration-300
                  ease-in-out
                  hover:scale-105
                  hover:shadow-sm
                  active:scale-95
                  ${status.color}
                  relative leading-none
                  animate-in
                  fade-in-50
                  hover:[animation:subtle-pulse_2s_ease-in-out_infinite]
                  motion-safe:transform-gpu
                `}
              >
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  {status.label}
                </span>
                <span className="sr-only">{status.letter}</span>
              </span>
            </TooltipTrigger>
            <TooltipContent
              className="animate-in fade-in-0 zoom-in-95"
              sideOffset={5}
            >
              {part === "Match" && "Match"}
              {part === "Partial" && "Partial Match"}
              {part === "Mismatch" && "No Match"}
              {part === "NA" && "Not Applicable"}
            </TooltipContent>
          </Tooltip>
        );
      }
      if (index % 2 === 1) {
        return (
          <ReferenceLink
            key={index}
            text={part}
            openReference={openReference}
            isSelected={false}
          />
        );
      }
      return part;
    });
  };

  const processChildren = (
    children: React.ReactNode
  ): {
    headers: React.ReactNode[];
    rows: React.ReactNode[][];
  } => {
    const headers: React.ReactNode[] = [];
    const rows: React.ReactNode[][] = [];
    let currentRow: React.ReactNode[] = [];

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === "thead") {
          React.Children.forEach(
            (child as React.ReactElement).props.children,
            (tr) => {
              if (React.isValidElement(tr)) {
                React.Children.forEach(
                  (tr as React.ReactElement).props.children,
                  (th) => {
                    if (React.isValidElement(th)) {
                      const headerContent =
                        typeof (th as React.ReactElement).props.children ===
                          "string" && (th as React.ReactElement);
                      headers.push(processText(headerContent));
                    }
                  }
                );
              }
            }
          );
        } else if (child.type === "tbody") {
          React.Children.forEach(
            (child as React.ReactElement).props.children,
            (tr) => {
              if (React.isValidElement(tr)) {
                currentRow = [];
                React.Children.forEach(
                  (tr as React.ReactElement).props.children,
                  (td) => {
                    if (React.isValidElement(td)) {
                      currentRow.push(
                        processText((td as React.ReactElement).props.children)
                      );
                    }
                  }
                );
                rows.push(currentRow);
              }
            }
          );
        }
      }
    });

    return { headers, rows };
  };

  const { headers, rows } = processChildren(children) as {
    headers: React.ReactNode[];
    rows: React.ReactNode[][];
  };

  // Update categories memo to exclude "Category" and "---" rows
  const categories = React.useMemo(() => {
    const categoryCounts = rows.reduce((acc, row) => {
      const category = String(row[0]);

      if (category === "Category" || category === "---") {
        return acc;
      }

      const isUncategorized =
        !category || category.trim() === "" || category === "undefined";
      const key = isUncategorized ? "Un-Categorized" : category;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const uniqueCategories = Object.keys(categoryCounts).map((category) => ({
      name: category,
      count: categoryCounts[category],
    }));

    // Set all categories as selected by default (only if selectedCategories is empty)
    if (selectedCategories.length === 0 && uniqueCategories.length > 0) {
      setSelectedCategories(uniqueCategories.map((cat) => cat.name));
    }

    return uniqueCategories;
  }, [rows]); // Remove selectedCategories.length from dependencies to prevent loops

  // Update the filteredRows logic only
  const filteredRows = React.useMemo(() => {
    if (selectedCategories.length === 0) {
      return [];
    }

    return rows.filter((row) => {
      const category = String(row[0]);

      if (!isValidCategory(category)) {
        return false;
      }

      const isUncategorized =
        !category || category.trim() === "" || category === "undefined";
      const categoryMatches = isUncategorized
        ? selectedCategories.includes("Un-Categorized")
        : selectedCategories.includes(category);

      if (!categoryMatches) return false;

      if (selectedStatuses.length > 0) {
        const statusCell = row[row.length - 2];
        if (!statusCell) return false;
        const status = getStatusFromElement(statusCell) as StatusType;
        return selectedStatuses.includes(status);
      }

      return true;
    });
  }, [rows, selectedCategories, selectedStatuses]);

  // Update the statusCounts memo to safely handle statusCell
  const statusCounts = React.useMemo(() => {
    const counts = filteredRows.reduce((acc, row) => {
      const statusCell = row[row.length - 2];
      const status = getStatusFromElement(statusCell);

      if (status) {
        acc[status] = (acc[status] || 0) + 1;
        acc.total = (acc.total || 0) + 1;
      }

      return acc;
    }, {} as Record<string, number>);

    return counts;
  }, [filteredRows]);

  // Update TableContent to add tooltips and remove last column
  const TableContent = () => (
    <div className="relative">
      <Table className="w-full table-fixed">
        <TableBody className="relative">
          {filteredRows.length > 0 ? (
            filteredRows.map((row, rowIndex) => {
              const statusCell = row[row.length - 2];
              const status = getStatusFromElement(statusCell);
              const statusStyles =
                {
                  Match: "hover:bg-emerald-50/80",
                  Partial: "hover:bg-amber-50/80",
                  Mismatch: "hover:bg-red-50/80",
                  NA: "hover:bg-slate-50/80",
                }[status] || "hover:bg-muted/20";

              const comments = row[row.length - 1];

              return (
                <React.Fragment key={rowIndex}>
                  <TableRow
                    className={`border-b border-muted-foreground/[0.08] transition-colors duration-200 ${statusStyles}`}
                  >
                    {row.slice(0, -1).map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        className={`group px-6 py-4 ${getColumnWidth(
                          cellIndex,
                          row.length - 1
                        )} ${
                          cellIndex === row.length - 2 ? "text-center" : ""
                        }`}
                      >
                        <div
                          className={`flex min-w-0 items-center ${
                            cellIndex === row.length - 2
                              ? "justify-center"
                              : "justify-between"
                          } gap-4`}
                        >
                          <span className="min-w-0 flex-1 whitespace-normal break-words text-[14px] leading-relaxed text-foreground/80 transition-colors group-hover:text-foreground">
                            {cell}
                          </span>
                          {typeof cell === "string" &&
                            cell.match(/page\s*\d+/i) && (
                              <Button
                                variant="ghost"
                                size="sm"
                                className="mx-auto h-8 gap-2 rounded-full px-4 opacity-0 transition-all duration-200 group-hover:opacity-100 hover:scale-105 hover:bg-background hover:shadow-md"
                                onClick={() => {
                                  const pageNum = cell.match(/\d+/)?.[0];
                                  if (pageNum) openReference(`page ${pageNum}`);
                                }}
                              >
                                <FileText className="h-4 w-4 text-muted-foreground" />
                                #{cell.match(/\d+/)?.[0]}
                              </Button>
                            )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                  {comments && (
                    <TableRow className="border-b border-muted-foreground/[0.08]">
                      <TableCell
                        colSpan={row.length - 1}
                        className="border-t-0 px-6 py-3 text-sm"
                      >
                        <div className="relative overflow-hidden rounded-lg bg-gradient-to-r from-primary/[0.03] via-primary/[0.05] to-transparent">
                          <div className="absolute left-0 top-0 h-full w-[3px] bg-gradient-to-b from-primary/40 via-primary/30 to-primary/20" />
                          <div className="relative px-6 py-3">
                            <div className="flex items-start gap-3">
                              <img
                                src="/images/q-icon.png"
                                alt="Qumis"
                                className="mt-0.5 h-5 w-auto opacity-90"
                              />
                              <div className="flex flex-col gap-1">
                                <span className="text-xs font-medium uppercase tracking-wider text-primary/70">
                                  Qumis INSIGHTS
                                </span>
                                <span className="text-[13px] leading-relaxed text-foreground/80">
                                  {comments}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={headers.length - 2}
                className="h-40 text-center text-base text-muted-foreground"
              >
                No results found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <>
      <div className="relative">
        <Card
          className={`${
            isFullScreen
              ? "fixed inset-0 z-50 m-0 scale-100 bg-background"
              : "my-6 scale-[0.999] hover:scale-100"
          } w-full border-muted-foreground/15 shadow-md transition-all duration-300`}
        >
          <div
            className={`relative flex flex-col overflow-hidden rounded-xl bg-background ${
              isFullScreen
                ? "h-screen"
                : "h-[600px] max-h-[800px] min-h-[800px]"
            }`}
          >
            <TableHeaderSection
              categories={categories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              setSelectedStatuses={
                setSelectedStatuses as Dispatch<SetStateAction<string[]>>
              }
              isFullScreen={isFullScreen}
              setFullScreen={setFullScreen}
              statusCounts={statusCounts}
              selectedStatuses={selectedStatuses as StatusType[]}
              headers={headers}
              rows={rows}
              reportId={reportId}
            />
            <div className="flex-1 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/40">
              <TableContent />
            </div>
          </div>
        </Card>
      </div>
      {isFullScreen && (
        <div
          className="fixed inset-0 z-40 bg-background/95 backdrop-blur-sm transition-opacity duration-300"
          onClick={() => setFullScreen(false)}
        />
      )}
    </>
  );
};

export default CustomMarkdownCheckListTable;

function convertExcelStatusToSTATUSMAPPING(
  statusColumnIndex: number,
  extractText: (node: React.ReactNode) => string,
  row: React.ReactNode[],
  rowIndex: number
) {
  if (statusColumnIndex !== -1) {
    const statusCellContent = extractText(row[statusColumnIndex]).toLowerCase();
    let status = "";

    if (statusCellContent.includes("no match")) {
      status = "Mismatch";
    } else if (statusCellContent.includes("partial match")) {
      status = "Partial";
    } else if (statusCellContent.includes("match")) {
      status = "Match";
    } else if (statusCellContent.includes("n/a")) {
      status = "NA";
    }

    // Set the status in the row for further processing
    row[statusColumnIndex] = status;

    // Log the status for debugging
    console.log(`Row ${rowIndex}, Converted Status: ${status}`);
  }
}

import TypesenseConnection from "./JobReports/TypesenseConnection";
import { FileText } from "lucide-react";

interface JobReportSearchProps {
  reportTypes: string[];
  reportTitle?: string;
  showHeader?: boolean;
  hitsPerPage?: number;
  enableSortByToggle?: boolean;
  customStyles?: {
    container?: string;
    title?: string;
    searchInput?: string;
    reportItem?: string;
    reportTitle?: string;
    reportMeta?: string;
    loadingSpinner?: string;
    emptyState?: string;
    errorState?: string;
  };
}

const JobReportSearch: React.FC<JobReportSearchProps> = ({
  reportTypes,
  reportTitle,
  hitsPerPage,
  customStyles = {},
  showHeader = true,
  enableSortByToggle = false,
}) => {
  return (
    <div className="flex h-full flex-col">
      <div
        className={`rounded-lg bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.1)] ${
          showHeader ? "border border-[#E5E5E5]" : ""
        } flex h-full flex-col overflow-hidden`}
      >
        {showHeader && (
          <div className="border-b border-[#E5E5E5] bg-[#FAFAFA] p-5">
            {reportTitle && (
              <h2
                className={`flex items-center text-xl font-medium text-[#000000] ${
                  customStyles.title || ""
                }`}
              >
                <FileText className="mr-2 h-5 w-5 text-[#1E1E1E]" />
                {reportTitle}
              </h2>
            )}
          </div>
        )}
        <div
          className={`flex-1 overflow-y-auto ${customStyles.container || ""}`}
        >
          <TypesenseConnection
            collectionName="job_reports"
            queryByListView="report_name,filenames"
            queryBySearchView="report_name,filenames,analysis"
            filterBy={`report_type: [${reportTypes.join(", ") || ""}]`}
            facetBy="report_type,doc_type,user_id,organization_id"
            sortByField="created_at"
            sortByDirection="desc"
            enableSortByToggle={enableSortByToggle}
            title=""
            hitsPerPage={hitsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default JobReportSearch;

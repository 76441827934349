import React from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import UploadArea from "~/routes/Documents/UploadArea";

interface WelcomeChatScreenProps {
  sendMessage: (message: string) => void;
  onDocumentUpload: (doc: SingleDocResponseData) => void;
  uploadedDocuments: SingleDocResponseData[];
  onDeleteDocument: (docId: string) => void;
}

const WelcomeChatScreen: React.FC<WelcomeChatScreenProps> = ({
  sendMessage,
  onDocumentUpload,
  onDeleteDocument,
  uploadedDocuments,
}) => {
  const suggestionCards = [
    {
      title: "Policy Coverage Analysis",
      icon: "📋",
      question:
        "What coverages are included in this policy and what are their limits?",
    },
    {
      title: "Exclusion Identification",
      icon: "⚠️",
      question: "What key exclusions should I be aware of in this policy?",
    },
    {
      title: "Coverage Comparison",
      icon: "⚖️",
      question:
        "How does this policy compare to industry standards for similar coverage?",
    },
    {
      title: "Policy Requirements",
      icon: "📝",
      question:
        "What are my obligations under this policy for reporting claims?",
    },
  ];

  return (
    <div className="flex h-full min-h-[60%] flex-col">
      <div className="sticky top-0 z-10 bg-white py-4 shadow-sm">
        {/* Header Section */}
        <section className="mx-auto w-full max-w-4xl bg-white p-1 px-6">
          <div className="text-center">
            <h3 className="mb-2 text-5xl  tracking-tight text-[#1a1a1a]">
              Qumis Clerk
            </h3>
            <p className="mx-auto max-w-2xl text-base leading-relaxed text-[#555555]">
              Upload any insurance related documents and I'll help you
              understand policies, coverage details, exclusions, and answer any
              questions you might have.
            </p>
          </div>
        </section>
      </div>

      <div className="flex-1 overflow-y-auto py-4">
        <div className="mx-auto w-full max-w-4xl space-y-8 px-6">
          {/* Upload Area */}
          <div className="w-full transform transition-all duration-300 hover:scale-[1.01]">
            <UploadArea
              showSearch={true}
              uploadedDocs={uploadedDocuments}
              onUploadComplete={onDocumentUpload}
              onDeleteDocument={onDeleteDocument}
              description="Upload PDF, Excel, Word, or image files (JPG, PNG, TIFF)"
            />
          </div>

          {/* Suggestion Cards */}
          <div className="mt-4">
            <h4 className="mb-5 text-xl font-medium text-[#333333]">
              Suggested Questions
            </h4>
            <div className="overflow-x-auto pb-2">
              <div className="flex min-w-max gap-4">
                {suggestionCards.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => sendMessage(item.question)}
                    className="group w-64 cursor-pointer rounded-lg border border-[#E5E5E5] bg-white p-5 shadow-sm transition-all duration-200 hover:border-[#D0D5DD] hover:bg-[#F7F9FC] hover:shadow-md"
                  >
                    <div className="mb-3 flex items-center gap-3">
                      <span
                        className="text-2xl"
                        role="img"
                        aria-label={item.title}
                      >
                        {item.icon}
                      </span>
                      <h3 className="text-sm font-medium text-[#1E1E1E] transition-colors group-hover:text-[#000000]">
                        {item.title}
                      </h3>
                    </div>
                    <p className="text-xs leading-relaxed text-[#555555] group-hover:text-[#333333]">
                      {item.question}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeChatScreen;

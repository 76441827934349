"use client";

import * as React from "react";
import { DocumentTypeEnum, DocumentTypeIcons } from "~/api";

interface DocumentTypeFilterProps {
  selectedTypes: DocumentTypeEnum[];
  onTypeToggle: (type: DocumentTypeEnum) => void;
}

export function DocumentTypeFilter({
  selectedTypes,
  onTypeToggle,
}: DocumentTypeFilterProps) {
  const [searchQuery] = React.useState("");

  const parseType = (type: DocumentTypeEnum) => {
    return type
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const allTypes = Object.values(DocumentTypeEnum);
  const filteredTypes = allTypes.filter((type) =>
    parseType(type).toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Determine if all filtered types are selected
  const areAllFilteredTypesSelected =
    filteredTypes.length > 0 &&
    filteredTypes.every((type) => selectedTypes.includes(type));

  // Function to toggle all filtered types
  const handleToggleAll = () => {
    if (areAllFilteredTypesSelected) {
      // Deselect all filtered types
      filteredTypes.forEach((type) => {
        if (selectedTypes.includes(type)) {
          onTypeToggle(type);
        }
      });
    } else {
      // Select all filtered types
      filteredTypes.forEach((type) => {
        if (!selectedTypes.includes(type)) {
          onTypeToggle(type);
        }
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-3">
        {filteredTypes.length > 0 && (
          <button
            onClick={handleToggleAll}
            className="flex items-center gap-2 rounded-md border border-[#E5E5E5] bg-white px-4 py-2 text-sm font-medium text-[#1E1E1E] transition-colors hover:bg-[#F7F7F7]"
          >
            {areAllFilteredTypesSelected ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-[#000000]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span>Deselect All</span>
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-[#000000]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Select All</span>
              </>
            )}
          </button>
        )}

        {filteredTypes.map((type) => {
          const TypeIcon = DocumentTypeIcons[type];
          const isSelected = selectedTypes.includes(type);

          return (
            <button
              key={type}
              onClick={() => onTypeToggle(type)}
              disabled={false}
              className={`flex items-center gap-2 rounded-md px-4 py-2 text-sm transition-colors ${
                isSelected
                  ? "border-2 border-[#1E1E1E] bg-[#E5E5E5] font-medium"
                  : "border border-[#E5E5E5] bg-white text-[#666666] hover:bg-[#F7F7F7]"
              }`}
            >
              <TypeIcon
                className={`h-4 w-4 ${
                  isSelected ? "text-[#000000]" : "text-[#666666]"
                }`}
              />
              <span>{parseType(type)}</span>
              {isSelected && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 h-4 w-4 text-[#000000]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          );
        })}
      </div>

      {filteredTypes.length === 0 && (
        <div className="rounded-md border border-[#E5E5E5] bg-[#FAFAFA] px-4 py-3 text-sm text-[#666666]">
          No document types found.
        </div>
      )}
    </div>
  );
}

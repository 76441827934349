import React from "react";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import SearchContainer from "../components/SearchField";
import Pagination from "../components/Pagination";
import ViewContainer from "./ViewContainer";
import { initStore } from "./helpers";

interface TypesenseConnectionProps {
  title: string;
  selectedTypes: string[];
}

const TypesenseConnection: React.FC<TypesenseConnectionProps> = ({
  title,
  selectedTypes,
}) => {
  // State
  const [viewMode, setViewMode] = useState<"list" | "search">("list");
  const [currentSearch, setCurrentSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Initialize connection
  const { state, actions } = initStore();

  // Actions
  useEffect(() => {
    actions.loadData({ viewMode, selectedTypes, currentSearch, currentPage });
  }, [currentSearch, viewMode]);

  return (
    <div className="max-w-10xl mx-auto space-y-6 px-6 pt-6">
      <div className="sticky top-0 space-y-6 bg-background pt-4">
        <Header
          showLoadingMsg={state.isLoading && state.isReady}
          title={title}
          itemsPerPage={state.data?.hitsPerPage || 20}
          totalItemsCount={state.initialData?.nbHits || 0}
          filteredItemsCount={state.data?.nbHits || 0}
          viewMode={viewMode}
          setViewMode={setViewMode}
          search={currentSearch}
          enableToggle={true}
        />
        <SearchContainer
          currentRefinement={currentSearch}
          refine={setCurrentSearch}
          setViewMode={setViewMode}
        />
      </div>
      <ViewContainer
        showLoadingMsg={state.isLoading && !state.isReady}
        filteredList={state.data?.hits || []}
        viewMode={viewMode}
        search={currentSearch}
      />
      <Pagination
        currentRefinement={currentPage}
        refine={setCurrentPage}
        nbPages={state.initialData?.nbPages || 0}
      />
    </div>
  );
};

export default TypesenseConnection;

import { Label } from "@radix-ui/react-label";
// import { PromptTemplate } from "../types";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";
import { Button } from "~/components/ui/button";
import { X } from "lucide-react";
import { PromptTemplate } from "~/api/query_fns/prompt_template";

interface SetupDialogWrapperProps {
  prompt: PromptTemplate | null;
  onCloseDialog: () => void;
  onSavePrompt: (prompt: PromptTemplate) => void;
}
interface SetupDialogProps {
  prompt: PromptTemplate;
  onCloseDialog: () => void;
  onSavePrompt: (prompt: PromptTemplate) => void;
}

const SetupDialog: React.FC<SetupDialogProps> = ({
  prompt,
  onCloseDialog,
  onSavePrompt,
}) => {
  const isNewMode = !prompt.id;
  const [currentName, setCurrentName] = useState(prompt.name);
  const [currentDescription, setCurrentDescription] = useState(
    prompt.description
  );

  const handleSavePrompt = () => {
    onSavePrompt({
      ...prompt,
      name: currentName,
      description: currentDescription,
    });
    onCloseDialog();
  };

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center">
      <div className="fixed inset-0 bg-black/50" onClick={onCloseDialog} />

      <div className="relative z-[10000] w-full max-w-md rounded-lg bg-background p-6 shadow-lg">
        <div className="mb-4">
          <h2 className="text-lg font-semibold">
            {isNewMode ? "Save Prompt" : "Edit Prompt"}
          </h2>
          <p className="text-sm text-muted-foreground">
            {isNewMode
              ? "Save your current prompt for future use."
              : "Edit your saved prompt."}
          </p>
        </div>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              value={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              className="col-span-3 h-8 w-full rounded-md border border-input bg-background px-3 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              placeholder="Enter a name for this prompt"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="prompt" className="text-right">
              Prompt
            </Label>
            <Textarea
              id="prompt"
              placeholder="Enter a prompt"
              value={currentDescription}
              onChange={(e) => setCurrentDescription(e.target.value)}
              className="col-span-3 min-h-[100px]"
            />
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <Button type="button" variant="secondary" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            type="button"
            disabled={!currentName || !currentDescription}
            onClick={handleSavePrompt}
          >
            {isNewMode ? "Save" : "Update"}
          </Button>
        </div>

        <button
          onClick={onCloseDialog}
          className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

const SetupDialogWrapper: React.FC<SetupDialogWrapperProps> = ({
  prompt,
  onCloseDialog,
  onSavePrompt,
}) => {
  // Track escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCloseDialog();
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [onCloseDialog]);

  // Prevent scroll when dialog is open
  useEffect(() => {
    if (prompt) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [prompt]);

  return (
    prompt &&
    createPortal(
      <SetupDialog
        prompt={prompt}
        onCloseDialog={onCloseDialog}
        onSavePrompt={onSavePrompt}
      />,
      document.body
    )
  );
};

export default SetupDialogWrapper;

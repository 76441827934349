import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";

// Define a local Citation type since it's not exported from coverage-analysis
export interface Citation {
  id: string;
  boundingBox?: {
    polygon: number[];
    pageIndex: number;
    pageHeight?: number;
    pageWidth?: number;
  };
  // Add other properties as needed
}

export type ReportSourceType =
  | "email_report"
  | "auth_ui_report"
  | "unauth_ui_report"
  | "chat"
  | "auth_ui_policy";
export type openReferenceType = (
  reference: string
) =>
  | { presignedUrl: string; pageNumber: number; isOpen: boolean }
  | { isOpen: boolean };
export type DocViewerState = {
  isOpen: boolean;
  page: number;
  doc: SingleDocResponseData | null;
  citation: Citation | null;
};

export const NEW_SPLITTER = "§";
export const OLD_SPLITTER = ",";

export type ReferenceDetails = {
  citationId: string;
  filename: string;
  page: string;
  section: string;
  status: boolean;
  pageNumber: number;
};

export function parseReference(reference: string): ReferenceDetails {
  if (reference.includes(NEW_SPLITTER)) {
    const referenceParts = reference
      .split(NEW_SPLITTER)
      .map((part) => part.trim());

    // Handle both 5-part and 4-part references
    let citationId, filename, page, section, status;
    if (referenceParts.length === 5) {
      [citationId, filename, page, section, status] = referenceParts;
    } else {
      // If only 4 parts, first part is filename (no citation ID)
      [filename, page, section, status] = referenceParts;
      citationId = "";
    }

    const pageNumber = parseInt(page?.replace(/\D/g, "") || "0") || 0;

    return {
      citationId: citationId || "",
      filename: filename || "",
      page: page || "",
      section: section || "",
      status:
        typeof status === "string"
          ? status.toLowerCase() === "true"
          : Boolean(status),
      pageNumber,
    };
  } else {
    // Handle old format: "[filename, pg X]"
    const cleanRef = reference.replace(/[[]]/g, "");
    const [filename, pagePart] = cleanRef.split(",").map((part) => part.trim());
    const pageNumber = parseInt(pagePart?.replace(/\D/g, "") || "0") || 0;

    return {
      citationId: "",
      filename: filename || "",
      page: pagePart || "",
      section: "",
      status: false,
      pageNumber,
    };
  }
}

export function useOpenReferenceDetails(
  docs: SingleDocResponseData[],
  citations: Citation[]
) {
  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    citation,
  } = useDocViewer();

  const openReference = (reference: string) => {
    console.log(`Reference: ${reference}`);
    const { citationId, filename, pageNumber } = parseReference(reference);

    const doc = docs.find(
      (doc) => filename.indexOf(doc.document.filename) !== -1
    );
    if (doc) {
      console.log(`Document found: ${filename}`);
      let citation = null;

      if (Array.isArray(citations) && citations.length > 0 && citationId) {
        citation = citations.find((citation) => citation.id === citationId);
      }

      setDocViewerState({
        doc,
        page: pageNumber,
        isOpen: true,
        citation: citation ?? null,
      });
      return {
        presignedUrl: doc.presignedUrl,
        pageNumber,
        isOpen: true,
        citation: citation ?? null,
      };
    } else {
      console.log(`Document not found: ${filename}`);
      if (docs.length === 1) {
        const singleDoc = docs[0];
        setDocViewerState({
          doc: singleDoc,
          page: pageNumber,
          isOpen: true,
          citation: null,
        });
        return {
          presignedUrl: singleDoc.presignedUrl,
          pageNumber: 0,
          isOpen: true,
          citation: null,
        };
      }
      return {
        isOpen: false,
      };
    }
  };

  const addCitations = (newCitations: Citation[]) => {
    citations.push(...newCitations);
  };

  return {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  };
}

function useDocViewer() {
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [document, setDocument] = useState<SingleDocResponseData | null>(null);
  const [citation, setCitation] = useState<Citation | null>(null);
  const setDocViewerState = ({
    isOpen,
    page,
    doc,
    citation,
  }: DocViewerState) => {
    setIsDocViewerOpen(isOpen);
    setInitialPage(page);
    setDocument(doc);
    setCitation(citation);
  };

  return {
    isDocViewerOpen,
    initialPage,
    document,
    citation,
    setDocViewerState,
  };
}

export { useDocViewer };

import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import WelcomePopup from "~/components/WelcomePopup";
import useSession from "~/hooks/useSession";
import {
  MessageCircle,
  LayoutGrid,
  FileText,
  ClipboardList,
  Sliders,
  ChevronRight,
  FileLock,
  Clock,
  Sparkles,
  Pin,
} from "lucide-react";
import RecentReportsPanel from "~/components/RecentReportsPanel";
import RecentChatsPanel from "~/components/RecentChatsPanel";
import ActionCard from "~/components/ActionCard";
import SectionalCard from "~/components/SectionalContainer";
import Cookies from "js-cookie";

// Cookie name for storing pinned tools
const PINNED_TOOLS_COOKIE = "pinnedTools";

// Default featured tools
const DEFAULT_FEATURED_TOOLS = [
  "chat-documents",
  "policy-comparison",
  "claim-assessment",
  "documents-vault",
];

const LandingPage = () => {
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const { session } = useSession();
  const [activeTab, setActiveTab] = useState<"reports" | "chats">("reports");
  const [contentHeight, setContentHeight] = useState("360px");
  const [pinnedTools, setPinnedTools] = useState<string[]>(
    DEFAULT_FEATURED_TOOLS
  );

  // Load pinned tools from cookie on mount
  useEffect(() => {
    const savedPinnedTools = Cookies.get(PINNED_TOOLS_COOKIE);
    if (savedPinnedTools) {
      try {
        const parsedTools = JSON.parse(savedPinnedTools);
        if (Array.isArray(parsedTools)) {
          setPinnedTools(parsedTools);
        }
      } catch (error) {
        console.error("Error parsing pinned tools from cookie:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (session && session.email) {
      const hasSeenWelcome = sessionStorage.getItem("hasSeenWelcome");
      if (!hasSeenWelcome) {
        setShowWelcomePopup(true);
      }
    }
  }, [session]);

  // Add resize handler for dynamic height adjustment
  useEffect(() => {
    const handleResize = () => {
      // Set different heights based on viewport height
      const vh = window.innerHeight;
      if (vh < 600) {
        setContentHeight("240px");
      } else if (vh < 800) {
        setContentHeight("320px");
      } else if (vh < 1000) {
        setContentHeight("400px");
      } else {
        setContentHeight("480px");
      }
    };

    // Initial call
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClosePopup = () => {
    setShowWelcomePopup(false);
    sessionStorage.setItem("hasSeenWelcome", "true");
  };

  const tools = [
    {
      id: "custom-reporting",
      title: "Custom Reporting",
      description: "Generate tailored reports for stakeholders",
      icon: <Sliders className="h-5 w-5" />,
      path: "/generic-report",
      primaryAction: "Create Report",
    },
    {
      id: "coverage-checking",
      title: "Coverage Checking",
      description: "Systematically verify policy coverage elements",
      icon: <ClipboardList className="h-5 w-5" />,
      path: "/coverage-checklist",
      primaryAction: "Verify Coverage",
      beta: true,
    },
    {
      id: "chat-documents",
      title: "Chat with Documents",
      description: "Chat with your insurance related documents",
      icon: <MessageCircle className="h-5 w-5" />,
      path: "/chat",
      primaryAction: "Chat with Qumis Clerk",
    },
    {
      id: "policy-comparison",
      title: "Comparison",
      description:
        "Compare quotes, binders, applications, policies and checklists",
      icon: <LayoutGrid className="h-5 w-5" />,
      path: "/policy-comparison",
      primaryAction: "Generate Comparison",
    },
    {
      id: "claim-assessment",
      title: "Claim Assessment",
      description: "Evaluate coverage for specific claims",
      icon: <FileText className="h-5 w-5" />,
      path: "/claim-coverage",
      primaryAction: "Assess Claim",
    },
    {
      id: "documents-vault",
      title: "Documents Vault",
      description: "Securely store and access your insurance documents",
      icon: <FileLock className="h-5 w-5" />,
      path: "/documents",
      primaryAction: "Access Documents",
    },
  ];

  const togglePinTool = (toolId: string) => {
    let newPinnedTools: string[];

    if (pinnedTools.includes(toolId)) {
      // Remove tool from pinned tools
      newPinnedTools = pinnedTools.filter((id) => id !== toolId);
    } else {
      // Add tool to pinned tools
      newPinnedTools = [...pinnedTools, toolId];
    }

    // Update state
    setPinnedTools(newPinnedTools);

    // Save to cookie
    Cookies.set(PINNED_TOOLS_COOKIE, JSON.stringify(newPinnedTools), {
      expires: 365,
    });
  };

  const isToolPinned = (toolId: string) => {
    return pinnedTools.includes(toolId);
  };

  // Get the actual tool objects for the pinned tools
  const featuredTools = tools.filter((tool) => pinnedTools.includes(tool.id));

  return (
    <div className="min-h-screen bg-white">
      <main className="px-2">
        {/* Header Section with Humus title and subheading */}
        <section className="bg-white py-1">
          <div className="container mx-auto px-2 text-center sm:px-8 lg:px-10">
            <h1 className="mb-4 font-serif text-5xl font-extrabold tracking-tight text-[#000000]">
              Qumis
            </h1>
            <p className="mx-auto max-w-2xl text-xl font-light tracking-wide text-[#666666]">
              Insurance Knowledge Work
            </p>
          </div>
        </section>

        {/* Featured Tools Section - Updated with dynamic pinned tools */}
        <section className="bg-white py-8">
          <div className="container mx-auto px-2 sm:px-8 lg:px-10">
            <div className="mx-auto grid grid-cols-1 gap-6 md:grid-cols-4">
              {featuredTools.map((tool) => (
                <ActionCard
                  key={tool.id}
                  id={tool.id}
                  title={tool.title}
                  description={tool.description}
                  icon={tool.icon}
                  path={tool.path}
                  primaryAction={tool.primaryAction}
                  beta={tool.beta}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Tools & Recent Activity Section */}
        <section className="py-6">
          <div className="container mx-auto px-2 sm:px-8 lg:px-10">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-12">
              {/* Tools Section */}
              <div className="lg:col-span-4">
                <SectionalCard
                  title="All Tools"
                  icon={<Sparkles className="h-5 w-5" />}
                  maxContentHeight={contentHeight}
                  contentClassName="space-y-3"
                >
                  {tools.map((tool, index) => (
                    <NavLink
                      key={index}
                      to={tool.path}
                      className="group flex items-start rounded-lg bg-[#FAFAFA] p-3 transition-colors duration-200 hover:bg-[#F7F7F7]"
                    >
                      <div className="mr-3 rounded-lg bg-white p-2 shadow-sm">
                        {tool.icon}
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="flex items-center">
                          <h3 className="truncate text-sm font-medium text-[#000000]">
                            {tool.title}
                          </h3>
                          {tool.beta && (
                            <span className="ml-1.5 flex-shrink-0 rounded-md bg-[#FAFAFA] px-1.5 py-0.5 text-xs font-medium text-[#666666]">
                              BETA
                            </span>
                          )}
                        </div>
                        <p className="mt-1 line-clamp-2 text-xs text-[#666666]">
                          {tool.description}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            togglePinTool(tool.id);
                          }}
                          className={`mr-2 rounded-full p-1.5 transition-colors ${
                            isToolPinned(tool.id)
                              ? "bg-[#F0F0F0] text-[#000000]"
                              : "text-[#666666] hover:bg-[#F0F0F0] hover:text-[#000000]"
                          }`}
                          title={
                            isToolPinned(tool.id)
                              ? "Unpin from featured tools"
                              : "Pin to featured tools"
                          }
                        >
                          <Pin
                            className={`h-3.5 w-3.5 ${
                              isToolPinned(tool.id) ? "fill-[#000000]" : ""
                            }`}
                          />
                        </button>
                        <ChevronRight className="h-4 w-4 flex-shrink-0 text-[#666666] transition-colors group-hover:text-[#000000]" />
                      </div>
                    </NavLink>
                  ))}
                </SectionalCard>
              </div>

              {/* Recent Activity Section */}
              <div className="lg:col-span-8">
                <SectionalCard
                  title="Recent Activity"
                  icon={<Clock className="h-5 w-5" />}
                  maxContentHeight={contentHeight}
                  headerRight={
                    <div className="flex space-x-1.5 rounded-lg bg-[#F7F7F7] p-1">
                      <button
                        onClick={() => setActiveTab("reports")}
                        className={`rounded-lg px-3 py-1.5 text-xs font-medium transition-colors duration-200 ${
                          activeTab === "reports"
                            ? "bg-white text-[#000000] shadow-sm"
                            : "text-[#666666] hover:text-[#000000]"
                        }`}
                      >
                        <div className="flex items-center gap-1.5">
                          <FileText className="h-3.5 w-3.5" />
                          Reports
                        </div>
                      </button>
                      <button
                        onClick={() => setActiveTab("chats")}
                        className={`rounded-lg px-3 py-1.5 text-xs font-medium transition-colors duration-200 ${
                          activeTab === "chats"
                            ? "bg-white text-[#000000] shadow-sm"
                            : "text-[#666666] hover:text-[#000000]"
                        }`}
                      >
                        <div className="flex items-center gap-1.5">
                          <MessageCircle className="h-3.5 w-3.5" />
                          Chats
                        </div>
                      </button>
                    </div>
                  }
                >
                  {activeTab === "reports" ? (
                    <RecentReportsPanel />
                  ) : (
                    <RecentChatsPanel />
                  )}
                </SectionalCard>
              </div>
            </div>
          </div>
        </section>
      </main>

      <WelcomePopup open={showWelcomePopup} onClose={handleClosePopup} />

      <style
        dangerouslySetInnerHTML={{
          __html: `
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }
        
        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #E5E5E5;
          border-radius: 9999px;
        }
        
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: #666666;
        }
        
        @keyframes fade-in {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        .animate-fade-in {
          animation: fade-in 0.6s ease-out;
        }
        
        @media (max-height: 600px) {
          .responsive-section-height {
            max-height: 240px;
          }
        }
        
        @media (min-height: 601px) and (max-height: 800px) {
          .responsive-section-height {
            max-height: 320px;
          }
        }
        
        @media (min-height: 801px) and (max-height: 1000px) {
          .responsive-section-height {
            max-height: 400px;
          }
        }
        
        @media (min-height: 1001px) {
          .responsive-section-height {
            max-height: 480px;
          }
        }
        `,
        }}
      />
    </div>
  );
};

export default LandingPage;

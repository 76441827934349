import React from "react";

const ErrorReportGenerationComponent: React.FC<{
  job_id: string;
  error: Error;
}> = ({ job_id, error }) => {
  const title = (() => {
    switch (error.message) {
      case "JOB_REPORT_WAS_DELETED":
        return "Warning, this report was deleted.";
      case "DOCUMENT_WAS_DELETED":
        return "Warning, this report was associated with a deleted document";
      default:
        return "Report generation failed";
    }
  })();

  return (
    <div
      className="mx-auto mt-10 flex max-w-7xl flex-col items-center justify-center px-4 py-16"
      style={{ borderRadius: "10px", minHeight: "calc(100vh - 200px)" }}
    >
      <h1
        style={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "#333",
          textAlign: "center",
          borderBottom: "1px solid #ddd",
          paddingBottom: "20px",
          width: "100%",
        }}
      >
        {title}
      </h1>
      <p
        style={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          color: "#666",
          margin: "40px 0",
          textAlign: "center",
          padding: "20px",
          maxWidth: "800px",
        }}
      >
        Report generation failed (ID: {job_id}). We're investigating and will
        contact you soon. For questions, email{" "}
        <a
          href="mailto:support@qumis.ai"
          style={{ color: "#337ab7", textDecoration: "none" }}
        >
          support@qumis.ai
        </a>
        .
      </p>
    </div>
  );
};

export default ErrorReportGenerationComponent;

import React, { useState, useEffect, useCallback, useRef } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { v4 as uuidv4 } from "uuid";
import { cleanCitationsFromText } from "~/components/CustomMarkdown";
import { ClipboardIcon } from "lucide-react";
import { toast } from "react-toastify";

interface TextSelectionMenuProps {
  selectedTextMenuOption?: TextSelectionMenuOption;
  setSelectedTextMenuOption: (option: TextSelectionMenuOption) => void;
  containerRef: React.RefObject<HTMLElement>;
}
const icons = {
  ADD_TO_CHAT:
    "M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z",
  CHECK_REFERENCES:
    "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01",
  INSIGHTS:
    "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
  CORRECT_INACCURACY: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
  HIGHLIGHT_RISKS:
    "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z",
  WRITE_EMAIL:
    "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
  SUMMARIZE: "M4 6h16M4 10h16M4 14h16M4 18h7",
  COPY_TEXT:
    "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M12 5l-3 3h6l-3-3z",
};

// Add these new types and enum
export enum TextSelectionMenuOptionType {
  ADD_TO_CHAT = "ADD_TO_CHAT",
  CHECK_REFERENCES = "CHECK_REFERENCES",
  INSIGHTS = "INSIGHTS", // Changed from PROVIDE_REASONING
  CORRECT_INACCURACY = "CORRECT_INACCURACY", // Changed from DOUBLE_CHECK
  WRITE_EMAIL = "WRITE_EMAIL", // Changed from EXPAND
  SUMMARIZE = "SUMMARIZE", // Changed from BULLET_POINT to SUMMARIZE
  HIGHLIGHT_RISKS = "HIGHLIGHT_RISKS", // Changed from EXPLORE
  COPY_TEXT = "COPY_TEXT",
}

export interface TextSelectionMenuOption {
  id?: string;
  type: TextSelectionMenuOptionType;
  label: string;
  icon: string;
  prefix: string;
  postfix: string; // Add this line
  autoDispatch: boolean;
  formatedText?: string;
}

const TextSelectionMenuOptions: TextSelectionMenuOption[] = [
  {
    type: TextSelectionMenuOptionType.COPY_TEXT,
    label: "Copy Text",
    icon: icons.COPY_TEXT,
    prefix: "",
    postfix: "",
    autoDispatch: false,
  },
  {
    type: TextSelectionMenuOptionType.SUMMARIZE,
    label: "Summarize",
    icon: icons.SUMMARIZE,
    prefix:
      "Please provide a concise summary of the following text. Focus on the key points, main provisions, and any critical information that would be relevant for understanding the overall meaning without losing important context:",
    postfix: "",
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.CORRECT_INACCURACY,
    label: "Correct Inaccuracy",
    icon: icons.CORRECT_INACCURACY,
    prefix:
      "Please review the following text carefully. Evaluate it for accuracy by checking against all relevant documents and provisions. If there are any inconsistencies or issues, please provide a clear and unbiased explanation with supporting references:",
    postfix: "",
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.CHECK_REFERENCES,
    label: "Check References",
    icon: icons.CHECK_REFERENCES,
    prefix:
      'Please methodically check all the documents and provide references for the following text: \n "',
    postfix: '"\n',
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.INSIGHTS,
    label: "Insights",
    icon: icons.INSIGHTS,
    prefix:
      "Please provide insights on the following text, including key takeaways, important details to note, and any relevant supporting information from the associated documents:",
    postfix: "",
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.HIGHLIGHT_RISKS,
    label: "Highlight Risks",
    icon: icons.HIGHLIGHT_RISKS,
    prefix:
      "Please analyze the following text for any potential risks, liabilities, or exposures. Include any specific provisions or clauses that could create coverage gaps or heightened liabilities. Provide an explanation of how these risks could impact the policyholder, insurer, or other involved parties, and suggest any possible mitigations or considerations based on the associated documents:",
    postfix: "",
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.WRITE_EMAIL,
    label: "Write Email",
    icon: icons.WRITE_EMAIL,
    prefix:
      "Please draft a professional email regarding the following text. The email should clearly explain the key points, include any necessary action items, and address the relevant parties if identified. Ensure the tone is appropriate for communication with clients, colleagues, or stakeholders:",
    postfix: "",
    autoDispatch: true,
  },
  {
    type: TextSelectionMenuOptionType.ADD_TO_CHAT,
    label: "Add To Chat",
    icon: icons.ADD_TO_CHAT,
    prefix: '"',
    postfix:
      '" \n The above text is from the report. Please take it into account when responding.\n',
    autoDispatch: false,
  },
];
const TextSelectionMenu: React.FC<TextSelectionMenuProps> = ({
  setSelectedTextMenuOption,
  containerRef,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [selectedText, setSelectedText] = useState("");
  const menuRef = useRef<HTMLDivElement>(null);

  const handleTextSelect = useCallback(
    (event: MouseEvent) => {
      const selection = window.getSelection();
      const target = event.target as HTMLElement;

      // Check if the target is not an input element
      if (
        selection &&
        selection.toString().trim().length > 0 &&
        !["INPUT", "TEXTAREA"].includes(target.tagName)
      ) {
        if (containerRef.current && containerRef.current.contains(target)) {
          const rect = containerRef.current.getBoundingClientRect();
          console.log("selection", selection.toString());
          setSelectedText(selection.toString());
          setPosition({
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
          });
          setIsVisible(true);
        }
      }
    },
    [containerRef]
  );

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", handleTextSelect);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleTextSelect);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleTextSelect, handleClickOutside]);

  const handleOptionSelect = (
    option: TextSelectionMenuOption,
    isVisible: boolean
  ) => {
    // Handle copy text option separately
    if (option.type === TextSelectionMenuOptionType.COPY_TEXT) {
      const cleanedText = cleanCitationsFromText(selectedText);
      navigator.clipboard
        .writeText(cleanedText)
        .then(() => {
          // Show toast notification when text is copied
          toast.success(`Copied to clipboard`, {
            position: "bottom-right",
            style: {
              border: "1px solid #000000",
              padding: "16px",
              color: "#000000",
            },
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error("Failed to copy text");
        });
      setIsVisible(false);
      return;
    }

    // Original behavior for other options
    const newOption = { ...option };
    newOption.id = uuidv4();
    const formattedText = `${option.prefix}${
      option.prefix ? "" : ""
    }${selectedText}${option.postfix ? "" : ""}${option.postfix}`;
    newOption.formatedText = formattedText;
    setSelectedTextMenuOption(newOption);
    setIsVisible(isVisible);
  };

  return (
    <PopoverPrimitive.Root open={isVisible} onOpenChange={setIsVisible}>
      <PopoverPrimitive.Anchor
        style={{
          position: "absolute",
          left: position.x,
          top: position.y,
        }}
      />
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={menuRef}
          className="z-50 w-80 rounded-lg border-2 border-gray-300 bg-white p-4 shadow-xl transition-all duration-200 ease-in-out"
          sideOffset={5}
          align="start"
        >
          <div className="grid gap-2">
            {TextSelectionMenuOptions.map((option) => (
              <button
                key={option.type}
                className="group flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm transition-all duration-200 ease-in-out hover:-translate-y-0.5 hover:bg-blue-50 hover:shadow-md"
                onClick={() => handleOptionSelect(option, false)}
              >
                {option.type === TextSelectionMenuOptionType.COPY_TEXT ? (
                  <ClipboardIcon className="mr-3 h-5 w-5 text-gray-600 transition-colors duration-200 ease-in-out group-hover:text-blue-700" />
                ) : (
                  <svg
                    className="mr-3 h-5 w-5 text-gray-600 transition-colors duration-200 ease-in-out group-hover:text-blue-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d={option.icon}
                    />
                  </svg>
                )}
                <span className="text-sm font-medium text-gray-800 transition-colors duration-200 ease-in-out group-hover:text-blue-800">
                  {option.label}
                </span>
              </button>
            ))}
          </div>
          <PopoverPrimitive.Arrow className="fill-gray-100" />
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};

export default TextSelectionMenu;

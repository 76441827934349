import Breadcrumbs from "~/components/Layout/Breadcrumbs";
import useIsPage from "~/hooks/useIsPage";
import useSession from "~/hooks/useSession";

const Nav = () => {
  const { loggedIn, organizationName } = useSession();
  const { isHomePage } = useIsPage();

  return (
    <nav className="flex w-full items-center justify-between">
      <div className="flex items-center">
        {!isHomePage && <Breadcrumbs />}

        {isHomePage && (
          <div className="flex items-center px-4 py-2">
            <span className="text-sm text-[#666666]">Home</span>
          </div>
        )}
      </div>

      <div className="ml-auto">
        {loggedIn && organizationName && (
          <span className="text-sm text-[#666666]">{organizationName}</span>
        )}
      </div>
    </nav>
  );
};

export default Nav;

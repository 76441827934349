import { searchTypesense } from "~/api/query_fns/typesense";

// Example of request params:
// q: ""
// query: ""
// facet_by: "document_type,user_id,organization_id"
// filter_by: "document_type:[policy, quote, binder, single_endorsement, attachment, fact, reference, additional_notes, spec]"
// query_by: "filename"
// facets: []
// highlightPostTag: "</ais-highlight-0000000000>"
// highlightPreTag: "<ais-highlight-0000000000>"
// page: 0
// tagFilters: ""

interface createSearchParams {
  query: string;
  facetBy: string;
  filterBy: string;
  queryBy: string;
  sortBy: string;

  page: number;
  hitsPerPage: number;
}

interface createSearchRequestParams {
  collectionName: string;
  searchParams: createSearchParams;
}

export interface createSearchResponse {
  found: number;
  hits: any[];
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
}

export const createSearchRequest = async ({
  collectionName,
  searchParams,
}: createSearchRequestParams): Promise<createSearchResponse> => {
  const response = await searchTypesense({
    collection_name: collectionName,
    search_params: {
      q: searchParams.query || "",
      query: searchParams.query || "",
      facet_by: searchParams.facetBy,
      filter_by: searchParams.filterBy,
      query_by: searchParams.queryBy,
      sort_by: searchParams.sortBy,
      page: searchParams.page,
      per_page: searchParams.hitsPerPage,
    },
  });

  return {
    found: response.found,
    hits: response.hits.map((hit: any) => ({
      ...hit.document,
      objectID: hit.document.id,
      _highlightResult: hit.highlights,
    })),
    nbHits: response.hits.length,
    nbPages: Math.ceil(response.found / (searchParams.hitsPerPage || 20)),
    hitsPerPage: searchParams.hitsPerPage || 20,
  };
};

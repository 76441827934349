import { ElementProps, HighlightResult, JobReportItem } from "../types";
import ListView from "../views/ListView";
import SearchView from "../views/SearchView";
import { useNavigate } from "react-router-dom";

type formattedJobReportItem = JobReportItem & {
  _highlightResult?: HighlightResult[];
};

interface ViewContainerProps {
  showLoadingMsg: boolean;
  filteredList: JobReportItem[];
  viewMode: "list" | "search";
  search: string;
}

const convertJobReportItemToElementProps = (
  item: formattedJobReportItem
): ElementProps => {
  return {
    id: item.id,
    title:
      item.reportName ||
      item.analysis?.substring(0, 30) + "..." ||
      "Report Name - Empty",
    createdAt: item.createdAt,
    filenames: item.filenames,
    content: item.analysis,
    _highlightResult: item._highlightResult || [],
  };
};

const ViewContainer: React.FC<ViewContainerProps> = ({
  showLoadingMsg,
  filteredList,
  viewMode,
  search,
}) => {
  const formattedList = filteredList.map(convertJobReportItemToElementProps);

  // Navigation
  const navigate = useNavigate();
  const openReport = (hit: any) => {
    navigate(`/report/analysis/${hit.id}`);
  };

  if (showLoadingMsg) {
    return (
      <div className="py-8 text-center text-muted-foreground">Loading...</div>
    );
  }

  if (formattedList.length === 0) {
    return (
      <div className="py-8 text-center text-muted-foreground">No Results</div>
    );
  }

  return viewMode === "list" ? (
    <ListView list={formattedList} search={search} linkAction={openReport} />
  ) : (
    <SearchView list={formattedList} search={search} linkAction={openReport} />
  );
};

export default ViewContainer;

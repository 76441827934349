import { useEffect, useState } from "react";
import { Input } from "~/components/ui/input";
import SetupDialog from "./components/SetupDialog";
import { Loader2, Save } from "lucide-react";
import List from "./components/List";
// import { PromptTemplate } from "./types";
import { cn } from "~/utils";
// import { Button } from "~/components/ui/button";
import initConnerction from "~/utils/state/store";
// import { createSearchResponse } from "~/utils/typesense/customSearch/createSearchRequest";
import {
  getPersonalPromptTemplates,
  GetPersonalPromptTemplatesResponse,
  PromptTemplate,
} from "~/api/query_fns/prompt_template";
import Pagination from "./components/Pagination";
import { createPrompt, destroyPrompt, updatePrompt } from "./helpers";

interface PromptTemplatesMenuProps {
  description: string;
  onSelectPrompt: (description: string) => void;
}

const PER_PAGE = 5;

const PromptTemplatesMenu: React.FC<PromptTemplatesMenuProps> = ({
  description,
  onSelectPrompt,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [editingPrompt, setEditingPrompt] = useState<PromptTemplate | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);

  // Initialize store
  const { state, actions } =
    initConnerction<GetPersonalPromptTemplatesResponse>(
      getPersonalPromptTemplates
    );

  // Actions
  const loadData = () =>
    actions.loadData({
      page: currentPage,
      per_page: PER_PAGE,
      search: searchQuery,
    });
  useEffect(() => {
    isOpen && loadData();
  }, [isOpen, currentPage]);

  // Handlers
  const openSetupDialog = () => {
    const name =
      description.length > 30 ? description.slice(0, 30) + "..." : description;
    setEditingPrompt({
      id: "",
      name: name,
      description: description,
      email: "",
      isOwnedByCurrentUser: true,
      promptGroup: "my_reports",
      shared: false,
      upvotes: 0,
      vote: 0,
    });
  };
  const onCloseDialog = () => {
    setEditingPrompt(null);
  };
  const handleSelectPrompt = (prompt: PromptTemplate) => {
    onSelectPrompt(prompt.description);
  };
  const handleEditPrompt = (prompt: PromptTemplate) => {
    setEditingPrompt(prompt);
  };
  const handleDeletePrompt = (prompt: PromptTemplate) => {
    destroyPrompt(prompt, () => {
      setEditingPrompt(null);
      loadData();
    });
  };
  const handleSavePrompt = (prompt: PromptTemplate) => {
    const _action = prompt.id ? updatePrompt : createPrompt;
    _action(prompt, () => {
      setEditingPrompt(null);
      loadData();
    });
  };

  const handleUpdateIsOpen = (newIsOpen: boolean) => {
    if (editingPrompt) return;
    setIsOpen(newIsOpen);

    // reset state
    if (!newIsOpen) {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  // Track on click outside of the menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        event.target instanceof Element &&
        !event.target.closest(".menu-container")
      ) {
        handleUpdateIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, editingPrompt]);

  return (
    <div className="relative">
      <button
        onClick={() => handleUpdateIsOpen(!isOpen)}
        className={cn(
          "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
          "ml-2 h-10 w-10 p-0 text-gray-500 hover:bg-accent hover:text-accent-foreground"
        )}
      >
        <Save className="h-5 w-5" />
      </button>

      {isOpen && (
        <div
          className={cn(
            "menu-container absolute bottom-[40px] right-[25px] z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2",
            "w-56"
          )}
        >
          <div className="flex gap-2 px-2 py-1.5">
            <Input
              type="search"
              placeholder="Search prompts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="h-8 w-full rounded-md border border-input bg-background px-3 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            />
            <button
              className="copilotKitSendButton flex h-8 w-8 items-center justify-center"
              disabled={state.isLoading}
              onClick={() => loadData()}
            >
              {state.isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  width="16"
                  height="16"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="my-1 h-px bg-muted" />

          <button
            className={cn(
              "relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors",
              "hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
            )}
            onClick={openSetupDialog}
          >
            Save current prompt
          </button>

          <div className="my-1 h-px bg-muted" />

          <List
            list={state.data?.list || []}
            onSelectPrompt={handleSelectPrompt}
            onEditPrompt={handleEditPrompt}
            onDeletePrompt={handleDeletePrompt}
          />

          {(state.data?.totalPages && state.data?.totalPages > 1 && (
            <>
              <div className="my-1 h-px bg-muted" />
              <Pagination
                page={currentPage}
                totalPages={state.data.totalPages}
                setPage={setCurrentPage}
              />
            </>
          )) ||
            ""}
        </div>
      )}

      <SetupDialog
        prompt={editingPrompt}
        onCloseDialog={onCloseDialog}
        onSavePrompt={handleSavePrompt}
      />
    </div>
  );
};

export default PromptTemplatesMenu;

import { useEffect, useState } from "react";
import Header from "../components/Header";
import SearchContainer from "../components/SearchField";
import Pagination from "../components/Pagination";
import ViewContainer from "./ViewContainer";
import {
  createSearchResponse,
  createSearchRequest,
} from "~/utils/typesense/customSearch/createSearchRequest";
import initConnerction from "../state/store";

interface TypesenseConnectionProps {
  collectionName: string;
  filterBy: string;
  facetBy: string;
  title: string;

  queryBy?: string;
  queryByListView?: string;
  queryBySearchView?: string;
  hitsPerPage?: number;
  sortByField?: SortByField;
  sortByDirection?: SortByDirection;
  enableSortByToggle?: boolean;
}
type SortByField = "created_at" | "report_name";
type SortByDirection = "asc" | "desc";

const DEFAULT_HITS_PER_PAGE = 20;

const TypesenseConnection: React.FC<TypesenseConnectionProps> = ({
  collectionName,
  queryBy,
  queryByListView,
  queryBySearchView,
  filterBy,
  facetBy,
  title,
  hitsPerPage,
  sortByField = "created_at",
  sortByDirection = "desc",
  enableSortByToggle = false,
}) => {
  // State
  const [viewMode, setViewMode] = useState<"list" | "search">("list");
  const [currentSearch, setCurrentSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSortByField, setCurrentSortByField] =
    useState<SortByField>(sortByField);
  const [currentSortByDirection, setCurrentSortByDirection] =
    useState<SortByDirection>(sortByDirection);

  // Initialize connection
  const { state, actions } =
    initConnerction<createSearchResponse>(createSearchRequest);
  const requestParams = () => {
    return {
      collectionName,
      searchParams: {
        filterBy,
        facetBy,
        queryBy:
          queryBy || (viewMode == "list" ? queryByListView : queryBySearchView),
        query: currentSearch,
        page: currentPage,
        sortBy:
          currentSortByField && currentSortByDirection
            ? `${currentSortByField}:${currentSortByDirection}`
            : undefined,
        hitsPerPage: hitsPerPage || DEFAULT_HITS_PER_PAGE,
      },
    };
  };

  // Actions
  useEffect(() => {
    actions.loadData(requestParams());
  }, [
    currentSortByField,
    currentSortByDirection,
    currentSearch,
    viewMode,
    currentPage,
  ]);

  return (
    <div className="flex h-full flex-col">
      {/* Fixed header */}
      <div className="flex-shrink-0 px-6 pb-4 pt-4">
        <Header
          showLoadingMsg={state.isLoading && state.isReady}
          title={title}
          itemsPerPage={
            state.data?.hitsPerPage || hitsPerPage || DEFAULT_HITS_PER_PAGE
          }
          totalItemsCount={state.data?.found || 0}
          filteredItemsCount={state.data?.nbHits || 0}
          viewMode={viewMode}
          setViewMode={setViewMode}
          search={currentSearch}
          enableSortByToggle={enableSortByToggle}
          sortByField={currentSortByField}
          setSortByField={(value) =>
            setCurrentSortByField(value as SortByField)
          }
          sortByDirection={currentSortByDirection}
          setSortByDirection={(value) =>
            setCurrentSortByDirection(value as SortByDirection)
          }
          enableToggle={false}
        />
        <div className="mt-4">
          <SearchContainer
            currentRefinement={currentSearch}
            refine={setCurrentSearch}
            setViewMode={setViewMode}
          />
        </div>
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto px-6">
        <ViewContainer
          showLoadingMsg={state.isLoading && !state.isReady}
          filteredList={state.data?.hits || []}
          viewMode={viewMode}
          search={currentSearch}
        />
      </div>

      {/* Fixed footer */}
      <div className="flex-shrink-0 border-t bg-background px-6">
        <Pagination
          currentRefinement={currentPage}
          refine={setCurrentPage}
          nbPages={state.data?.nbPages || 0}
        />
      </div>
    </div>
  );
};

export default TypesenseConnection;

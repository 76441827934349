import React, { useState } from "react";

import { Link } from "react-router-dom";
import {
  getPromptTemplates,
  postPromptTemplate,
  putPromptTemplate,
  destroyPromptTemplate,
  PromptTemplate,
  PromptGroupType,
} from "~/api/query_fns/prompt_template";
import { postVotePromptTemplate } from "~/api/query_fns/vote_prompt_template";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Checkbox } from "~/components/ui/checkbox";
import { Textarea } from "~/components/ui/textarea";
import {
  Library,
  Plus,
  Edit,
  Trash2,
  Share,
  ThumbsUp,
  ThumbsDown,
  MoreVertical,
  User,
  Users,
  Sparkles,
  Loader2,
} from "lucide-react";

import { useQueryClient, useQuery, useMutation } from "react-query";
import Pagination from "~/components/Pagination";

interface PromptListProps {
  prompts: PromptTemplate[];
  selectedPrompt: PromptTemplate | null;
  onSelectPrompt: (prompt: PromptTemplate) => void;
  isLoading: boolean;
}

// Update the type definition to match the actual structure
interface LocalPromptTemplate extends Omit<PromptTemplate, "vote"> {
  vote: number;
}

const PER_PAGE = 5;

const PromptList: React.FC<PromptListProps> = ({
  prompts,
  selectedPrompt,
  onSelectPrompt,
  isLoading,
}) => {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const promptsByPage = prompts.slice(
    (currentPage - 1) * PER_PAGE,
    currentPage * PER_PAGE
  );
  if (isLoading) {
    return <div className="p-4 text-center">Loading prompts...</div>;
  }

  if (prompts.length === 0) {
    return <div className="p-4 text-center">No prompts found</div>;
  }

  return (
    <div className="space-y-2">
      {promptsByPage.map((prompt) => (
        <Card
          key={prompt.id}
          className={`cursor-pointer transition-colors hover:bg-[#F9F9F9] ${
            selectedPrompt?.id === prompt.id
              ? "border-[#0070F3] bg-[#F0F7FF]"
              : ""
          }`}
          onClick={() => onSelectPrompt(prompt)}
        >
          <CardHeader className="p-4">
            <CardTitle className="text-base">{prompt.name}</CardTitle>
            {prompt.email && (
              <CardDescription className="text-xs">
                By: {prompt.email}
              </CardDescription>
            )}
          </CardHeader>
        </Card>
      ))}
      {prompts.length > PER_PAGE && (
        <div className="flex justify-center">
          <Pagination
            total={prompts.length}
            page={currentPage}
            perPage={PER_PAGE}
            setPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

// Simple toast implementation since the import is missing
const useToast = () => {
  return {
    toast: ({
      title,
      description,
    }: {
      title: string;
      description: string;
      variant?: string;
    }) => {
      console.log(`${title}: ${description}`);
      // In a real implementation, this would show a toast notification
    },
  };
};

const PromptLibrary: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<PromptGroupType>("my_reports");
  const [selectedPrompt, setSelectedPrompt] =
    useState<LocalPromptTemplate | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [newPromptName, setNewPromptName] = useState("");
  const [newPromptContent, setNewPromptContent] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeSearch, setActiveSearch] = useState("");

  const queryClient = useQueryClient();
  const { toast } = useToast();

  // Fetch prompt templates
  const { data: promptGroups, isLoading } = useQuery({
    queryKey: ["promptTemplates", activeSearch],
    queryFn: () => {
      return getPromptTemplates({ search: activeSearch });
    },
  });

  // Create new prompt template
  const createMutation = useMutation({
    mutationFn: postPromptTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["promptTemplates"] });
      toast({
        title: "Success",
        description: "Prompt template created successfully",
      });
      setCreateDialogOpen(false);
      resetForm();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to create prompt template " + error,
        variant: "destructive",
      });
    },
  });

  const handleSearch = () => {
    setActiveSearch(searchQuery);
  };

  // Update prompt template
  const updateMutation = useMutation({
    mutationFn: putPromptTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["promptTemplates"] });
      toast({
        title: "Success",
        description: "Prompt template updated successfully",
      });
      setEditMode(false);
      resetForm();
    },
    onError: (error) => {
      console.log("error", error);
      toast({
        title: "Error",
        description: "Failed to update prompt template",
        variant: "destructive",
      });
    },
  });

  // Delete prompt template
  const deleteMutation = useMutation({
    mutationFn: destroyPromptTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["promptTemplates"] });
      toast({
        title: "Success",
        description: "Prompt template deleted successfully",
      });
      setDeleteDialogOpen(false);
      setSelectedPrompt(null);
    },
    onError: (error) => {
      console.log("error", error);
      toast({
        title: "Error",
        description: "Failed to delete prompt template",
        variant: "destructive",
      });
    },
  });

  // Vote on prompt template
  const voteMutation = useMutation({
    mutationFn: postVotePromptTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["promptTemplates"] });
    },
    onError: (error) => {
      console.log("error", error);
      toast({
        title: "Error",
        description: "Failed to vote on prompt template",
        variant: "destructive",
      });
    },
  });

  // Reset form fields
  const resetForm = () => {
    setNewPromptName("");
    setNewPromptContent("");
    setIsShared(false);
    setSelectedPrompt(null);
  };

  // Handle prompt selection
  const handlePromptSelect = (prompt: PromptTemplate) => {
    setSelectedPrompt(prompt as LocalPromptTemplate);
    setNewPromptName(prompt.name);
    setNewPromptContent(prompt.description);
    setIsShared(prompt.shared);
    setEditMode(false);
  };

  // Handle edit mode
  const handleEditClick = () => {
    setEditMode(true);
  };

  // Handle save changes
  const handleSaveChanges = () => {
    if (!selectedPrompt) return;

    updateMutation.mutate({
      id: selectedPrompt.id,
      name: newPromptName,
      description: newPromptContent,
      shared: isShared,
      prompt_group: selectedTab,
      is_owned_by_current_user: true,
    });
  };

  // Handle create new prompt
  const handleCreatePrompt = () => {
    createMutation.mutate({
      name: newPromptName,
      description: newPromptContent,
      shared: isShared,
      prompt_group: selectedTab,
      is_owned_by_current_user: true,
    });
  };

  // Handle delete prompt
  const handleDeletePrompt = () => {
    if (!selectedPrompt) return;
    deleteMutation.mutate({ id: selectedPrompt.id });
  };

  // Handle vote
  const handleVote = (promptId: string, vote: boolean) => {
    voteMutation.mutate({ prompt_template_id: promptId, vote: vote ? 1 : 0 });
  };

  // Handle share toggle
  const handleShareToggle = () => {
    if (!selectedPrompt) return;

    updateMutation.mutate({
      id: selectedPrompt.id,
      name: selectedPrompt.name,
      description: selectedPrompt.description,
      shared: !selectedPrompt.shared,
      prompt_group: selectedTab,
      is_owned_by_current_user: true,
    });
  };

  return (
    <div className="container mx-auto py-6">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center">
          <Library className="mr-2 h-6 w-6 text-[#1E1E1E]" />
          <h1 className="text-2xl font-bold text-[#000000]">Prompt Library</h1>
        </div>
        <Button
          onClick={() => {
            resetForm();
            setCreateDialogOpen(true);
          }}
        >
          <Plus className="mr-2 h-4 w-4" /> Create New Prompt
        </Button>
      </div>

      <div className="grid grid-cols-12 gap-6">
        {/* Left sidebar with prompt categories and list */}
        <div className="col-span-4">
          <div className="flex gap-2 px-2 py-1.5">
            <Input
              type="search"
              placeholder="Search prompts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="h-8 w-full rounded-md border border-input bg-background px-3 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            />
            <button
              className="copilotKitSendButton flex h-8 w-8 items-center justify-center"
              disabled={isLoading}
              onClick={handleSearch}
            >
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  width="16"
                  height="16"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              )}
            </button>
          </div>

          <Tabs
            defaultValue="my_reports"
            onValueChange={(value) => setSelectedTab(value as PromptGroupType)}
          >
            <TabsList className="mb-4 w-full">
              <TabsTrigger value="my_reports" className="flex-1">
                <User className="mr-2 h-4 w-4" /> My Prompts
              </TabsTrigger>
              <TabsTrigger value="organization_reports" className="flex-1">
                <Users className="mr-2 h-4 w-4" /> Organization
              </TabsTrigger>
              <TabsTrigger value="qumis_reports" className="flex-1">
                <Sparkles className="mr-2 h-4 w-4" /> System
              </TabsTrigger>
            </TabsList>

            <TabsContent value="my_reports" className="m-0">
              <PromptList
                prompts={
                  promptGroups?.find((g) => g.group === "my_reports")
                    ?.options || []
                }
                selectedPrompt={selectedPrompt}
                onSelectPrompt={handlePromptSelect}
                isLoading={isLoading}
              />
            </TabsContent>

            <TabsContent value="organization_reports" className="m-0">
              <PromptList
                prompts={
                  promptGroups?.find((g) => g.group === "organization_reports")
                    ?.options || []
                }
                selectedPrompt={selectedPrompt}
                onSelectPrompt={handlePromptSelect}
                isLoading={isLoading}
              />
            </TabsContent>

            <TabsContent value="qumis_reports" className="m-0">
              <PromptList
                prompts={
                  promptGroups?.find((g) => g.group === "qumis_reports")
                    ?.options || []
                }
                selectedPrompt={selectedPrompt}
                onSelectPrompt={handlePromptSelect}
                isLoading={isLoading}
              />
            </TabsContent>
          </Tabs>
        </div>

        {/* Right side with prompt details and editor */}
        <div className="col-span-8">
          {selectedPrompt ? (
            <Card className="h-full">
              <CardHeader className="flex flex-row items-center justify-between">
                <div>
                  <CardTitle>
                    {editMode ? (
                      <Input
                        value={newPromptName}
                        onChange={(e) => setNewPromptName(e.target.value)}
                        className="font-bold"
                      />
                    ) : (
                      selectedPrompt.name
                    )}
                  </CardTitle>
                  {selectedPrompt.email && !editMode && (
                    <CardDescription>
                      Created by: {selectedPrompt.email}
                    </CardDescription>
                  )}
                </div>
                {selectedPrompt.isOwnedByCurrentUser && !editMode && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={handleEditClick}>
                        <Edit className="mr-2 h-4 w-4" /> Edit
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleShareToggle}>
                        <Share className="mr-2 h-4 w-4" />
                        {selectedPrompt.shared
                          ? "Unshare"
                          : "Share with Organization"}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => setDeleteDialogOpen(true)}
                        className="text-[#E63946]"
                      >
                        <Trash2 className="mr-2 h-4 w-4" /> Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </CardHeader>
              <CardContent>
                {editMode ? (
                  <Textarea
                    value={newPromptContent}
                    onChange={(e) => setNewPromptContent(e.target.value)}
                    className="min-h-[300px] font-mono"
                  />
                ) : (
                  <div className="min-h-[300px] whitespace-pre-wrap rounded-md border p-4">
                    {selectedPrompt.description}
                  </div>
                )}
              </CardContent>
              <CardFooter className="flex justify-between">
                {editMode ? (
                  <div className="flex w-full items-center gap-4">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="shared"
                        checked={isShared}
                        onCheckedChange={(checked) =>
                          setIsShared(checked === true)
                        }
                      />
                      <Label htmlFor="shared">Share with organization</Label>
                    </div>
                    <div className="ml-auto flex gap-2">
                      <Button
                        variant="outline"
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleVote(selectedPrompt.id, true)}
                        disabled={selectedPrompt.vote === 1}
                        className={
                          selectedPrompt.vote === 1 ? "bg-[#E5E5E5]" : ""
                        }
                      >
                        <ThumbsUp className="mr-1 h-4 w-4" />{" "}
                        {selectedPrompt.upvotes || 0}
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleVote(selectedPrompt.id, false)}
                        disabled={selectedPrompt.vote === 0}
                        className={
                          selectedPrompt.vote === 0 ? "bg-[#E5E5E5]" : ""
                        }
                      >
                        <ThumbsDown className="h-4 w-4" />
                      </Button>
                    </div>
                    <div className="flex gap-2">
                      <Button asChild>
                        <Link
                          to={`/generic-report?promptId=${selectedPrompt.id}`}
                        >
                          Use in Report
                        </Link>
                      </Button>
                      <Button asChild variant="outline">
                        <Link to={`/chat?promptId=${selectedPrompt.id}`}>
                          Use in Chat
                        </Link>
                      </Button>
                    </div>
                  </>
                )}
              </CardFooter>
            </Card>
          ) : (
            <Card className="flex h-full items-center justify-center">
              <CardContent className="py-10 text-center">
                <Library className="mx-auto mb-4 h-16 w-16 text-[#E5E5E5]" />
                <h3 className="mb-2 text-xl font-medium text-[#1E1E1E]">
                  No Prompt Selected
                </h3>
                <p className="mb-6 text-[#666666]">
                  Select a prompt from the library or create a new one
                </p>
                <Button onClick={() => setCreateDialogOpen(true)}>
                  <Plus className="mr-2 h-4 w-4" /> Create New Prompt
                </Button>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      {/* Create Prompt Dialog */}
      <Dialog open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Prompt</DialogTitle>
            <DialogDescription>
              Create a new prompt template for reports or chat interactions.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  placeholder="Enter prompt name"
                  value={newPromptName}
                  onChange={(e) => setNewPromptName(e.target.value)}
                />
              </div>

              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="content">Prompt Content</Label>
                <Textarea
                  id="content"
                  placeholder="Enter your prompt template content"
                  value={newPromptContent}
                  onChange={(e) => setNewPromptContent(e.target.value)}
                  className="min-h-[150px] font-mono"
                />
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="shared"
                  checked={isShared}
                  onCheckedChange={(checked) => setIsShared(checked === true)}
                />
                <Label htmlFor="shared">Share with organization</Label>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setCreateDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleCreatePrompt}>Create Prompt</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Prompt</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this prompt? This action cannot be
              undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeletePrompt}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PromptLibrary;

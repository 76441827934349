import { apiFn } from "~/api/utils";
import { ReportSourceType } from "~/doc-util";

export interface PostChatMessageParams {
  id: string;
  report_source: string; //ReportSourceType
  message: string;
  report_id?: string;
  document_ids: string;
  user_id?: string;
  report_key: string;
  chat_id: string;
  message_type: "user" | "assistant";
}

export interface PostChatMessageResponse {
  id: string;
  report_key: string;
  report_source: ReportSourceType;
  message: string;
  report_id: string;
  document_ids: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export const postChatMessage = apiFn<
  PostChatMessageParams,
  PostChatMessageResponse
>("POST", "/chat_messages");

export interface UpdateMessageRatingParams {
  id: string;
  rating: number;
}

export interface UpdateMessageRatingResponse {
  rating: number;
  id: string;
  reportSource: string;
  message: string;
  reportId: string | null;
  reportKey: string;
  userId: string;
  documentIds: string;
  createdAt: string;
  updatedAt: string;
  chatId: string;
  messageType: "user" | "assistant";
}

export const updateMessageRating = apiFn<
  UpdateMessageRatingParams,
  UpdateMessageRatingResponse
>("PATCH", (params) => `/chat_messages/${params.id}/rating`);

// Chat Sessions interfaces
export interface ChatSessionsRequestParams {
  page?: number;
  per_page?: number;
}

export interface ChatSession {
  chatId: string;
  documentFilenames: string[];
  updatedAt: string;
}

export interface PaginationMetadata {
  currentPage: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
}

export interface ChatSessionsResponse {
  chatSessions: ChatSession[];
  pagination: PaginationMetadata;
}

// Chat Sessions API function
export const fetchChatSessions = apiFn<
  ChatSessionsRequestParams,
  ChatSessionsResponse
>("GET", (params) => {
  const page = params?.page || 1;
  const perPage = params?.per_page || 10;
  return `/chat_messages/sessions?page=${page}&per_page=${perPage}`;
});

// Chat Session Messages interfaces
export interface ChatSessionMessagesRequestParams {
  chat_id: string;
}

export interface ChatSessionMessagesResponse {
  messages: ChatMessage[];
}

// Chat Session Messages API function
export const fetchChatSessionMessages = apiFn<
  ChatSessionMessagesRequestParams,
  ChatSessionMessagesResponse
>(
  "GET",
  (params) => `/chat_messages/session_messages?chat_id=${params.chat_id}`
);

// Chat message type in FetchJobReportResponse
export interface ChatMessage {
  id: string;
  reportSource: string;
  message: string;
  reportId: string | null;
  reportKey: string;
  userId: string;
  documentIds: string;
  createdAt: string;
  updatedAt: string;
  rating: number | null;
  chatId: string;
  messageType: "user" | "assistant";
}

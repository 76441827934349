import { useEffect, useState } from "react";
import { destroyDocument } from "~/api";
import { DocumentItem } from "../types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { apiFn } from "~/api/utils";

interface DeleteDialogProps {
  documentToDelete: DocumentItem | null;
  setDocumentToDelete: (document: DocumentItem | null) => void;
  onSuccessDeleteDocument: () => void;
}

interface AssociatedJobReport {
  id: string;
  reportName: string;
}

const associatedJobReportsWithDocument = apiFn<
  { id: string },
  AssociatedJobReport[]
>("GET", (params) => `/documents/${params.id}/associated_job_reports`);

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  documentToDelete,
  setDocumentToDelete,
  onSuccessDeleteDocument,
}) => {
  if (!documentToDelete) return null;
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [associatedJobReports, setAssociatedJobReports] = useState<
    AssociatedJobReport[]
  >([]);

  useEffect(() => {
    loadData({
      documentToDelete,
      setIsLoading,
      setAssociatedJobReports,
      setIsReady,
    });
  }, []);

  return (
    <Dialog
      open={true}
      onOpenChange={onCloseDialog.bind(null, isLoading, setDocumentToDelete)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogDescription>
            Are you sure you want to remove the file "
            {documentToDelete.filename}"?
            {associatedJobReports.length > 0 && (
              <div className="mt-4">
                <div className="font-bold">
                  Report{associatedJobReports.length > 1 ? "s" : ""} that will
                  be deleted:
                </div>
                <div className="mt-1">
                  {associatedJobReports
                    .map((jobReport) => jobReport.reportName)
                    .join(", ")}
                </div>
              </div>
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            onClick={onCloseDialog.bind(null, isLoading, setDocumentToDelete)}
            className="border border-black bg-white text-black hover:bg-black hover:text-white"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteFile.bind(null, {
              documentToDelete,
              setIsLoading,
              setDocumentToDelete,
              onSuccessDeleteDocument,
            })}
            className="border border-black bg-white text-black hover:bg-black hover:text-white"
          >
            {agreeBtnCopy({ isReady, isLoading })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// -------------- HELPER FUNCTIONS --------------

interface LoadDataParams {
  documentToDelete: DocumentItem;
  setIsLoading: (isLoading: boolean) => void;
  setAssociatedJobReports: (
    associatedJobReports: AssociatedJobReport[]
  ) => void;
  setIsReady: (isReady: boolean) => void;
}

// Load information about associated job reports
const loadData = async ({
  documentToDelete,
  setIsLoading,
  setAssociatedJobReports,
  setIsReady,
}: LoadDataParams) => {
  setIsLoading(true);
  try {
    const response = await associatedJobReportsWithDocument({
      id: documentToDelete.id,
    });
    setAssociatedJobReports(response);
  } catch (error) {
    console.error(error);
    setIsReady(false);
  } finally {
    setIsLoading(false);
    setIsReady(true);
  }
};

interface HandleDeleteFileParams {
  documentToDelete: DocumentItem;
  setIsLoading: (isLoading: boolean) => void;
  setDocumentToDelete: (document: DocumentItem | null) => void;
  onSuccessDeleteDocument: () => void;
}

// Delete document
const handleDeleteFile = async ({
  documentToDelete,
  setIsLoading,
  setDocumentToDelete,
  onSuccessDeleteDocument,
}: HandleDeleteFileParams) => {
  setIsLoading(true);
  try {
    await destroyDocument({ id: documentToDelete.id });
  } catch (error) {
    console.error(error);
  } finally {
    setIsLoading(false);
    setDocumentToDelete(null);
    onSuccessDeleteDocument();
  }
};

// Close dialog if we are not deleting
const onCloseDialog = (
  isLoading: boolean,
  setDocumentToDelete: (document: DocumentItem | null) => void
) => {
  !isLoading && setDocumentToDelete(null);
};

const agreeBtnCopy = ({
  isReady,
  isLoading,
}: {
  isReady: boolean;
  isLoading: boolean;
}) => {
  if (!isReady && isLoading) {
    return "Loading...";
  } else if (isLoading) {
    return "Deleting...";
  } else {
    return "Delete";
  }
};

export default DeleteDialog;

import {
  LoaderFunctionArgs,
  useLoaderData,
  Await,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import { defer } from "react-router-dom";
import { ReportTabs } from "~/components/ReportTabs";
import {
  ArtifactFetchResponse,
  fetchJobReport,
  ReportType,
} from "~/api/query_fns/coverage-analysis";
import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useOpenReferenceDetails } from "../doc-util";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { CustomChat } from "~/components/util-chat";
import { JobProcessingStatus } from "~/api/query_fns/coverage-analysis";
import React, { useState, useRef, useCallback } from "react";
import {
  generateInitialMessage,
  generateChatContext,
  getDocumentDetails,
} from "~/utils/util-report";
import { LoadingComponent } from "~/utils/util-report";
import ErrorBoundaryReport from "~/components/ErrorBoundaryReport";
import { Message } from "../components/CustomMessages";
import TextSelectionMenu, {
  TextSelectionMenuOption,
} from "~/components/TextSelectionMenu";
import { fetchDocumentTexts } from "~/utils";
import { Citation } from "~/api/query_fns/citations-api";
import { useBreadcrumb } from "~/context/BreadcrumbContext";

interface LoaderResponse {
  jobId: string;
  reportName: string;
  documents: SingleDocResponseData[];
  fullCoverageReport: string;
  reportKey: string;
  reportType: ReportType;
  from: string;
  followOnQuestions: string[];
  chatMessages?: Message[];
  latestVersionReportId?: string;
  latestVersion?: number;
  version?: number;
  isSuperseded: boolean;
  coverageReportUrl?: string;
  citations?: Citation[];
  oId: string;
  uId: string;
  artifacts?: ArtifactFetchResponse[];
}

export const reportPrivateAnalysisLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const fetchReportData = async (): Promise<LoaderResponse> => {
    let retryCount = 0;
    const maxRetries = 80; // Increased from 40 to 80
    const retryDelay = 15000; // 15 seconds in milliseconds

    while (retryCount < maxRetries) {
      try {
        const response = await fetchJobReport({
          jobId: params.job_id || "",
          lastChatMessageId: params.chat_message_id,
        });

        if (
          response.processingStatus === JobProcessingStatus.Complete ||
          response.processingStatus === JobProcessingStatus.Exempt ||
          response.processingStatus === JobProcessingStatus.Superceded
        ) {
          const mappedChatMessages =
            response.chatMessages?.map((chatMessage) => ({
              id: chatMessage.id,
              content: chatMessage.message,
              role: chatMessage.messageType as "user" | "assistant",
              createdAt: new Date(chatMessage.createdAt),
              isVisible: true,
              chatId: chatMessage.chatId,
            })) || [];

          await fetchDocumentTexts(
            response.documents as SingleDocResponseData[]
          );

          return {
            jobId: params.job_id || "",
            reportName: response.reportName,
            documents: response.documents,
            fullCoverageReport: response.coverageReport,
            coverageReportUrl: response.coverageReportUrl,
            reportKey: response.reportKey || "",
            reportType: response.reportType as ReportType,
            from: response.from,
            followOnQuestions: response.followOnQuestions || [],
            chatMessages: mappedChatMessages,
            latestVersionReportId: response.latestVersionReportId,
            latestVersion: response.latestVersion,
            version: response.version,
            isSuperseded:
              response.processingStatus === JobProcessingStatus.Superceded,
            citations: response.citations || [],
            oId: response.oId,
            uId: response.uId,
            artifacts: response.artifacts || [],
          };
        } else if (response.processingStatus === JobProcessingStatus.Deleted) {
          throw new Error("Job has been deleted");
        } else {
          console.log(
            `Current processing status: ${response.processingStatus}. Retrying in 15 seconds...`
          );
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          retryCount++;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    throw new Error("Generating Report Error: Timeout after 20 minutes");
  };

  return defer({ reportData: fetchReportData() });
};

const GenReportPrivate = () => {
  const { reportData } = useLoaderData() as {
    reportData: Promise<LoaderResponse>;
  };
  const location = useLocation();
  const { job_id } = useParams();
  const [loadedData, setLoadedData] = React.useState<LoaderResponse | null>(
    null
  );
  const { setReportTitle } = useBreadcrumb();

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const [reportChatMessages, setReportChatMessages] = useState<Message[]>([]);
  const [selectedTextMenuOption, setSelectedTextMenuOption] = useState<
    TextSelectionMenuOption | undefined
  >();

  const reportTabsRef = useRef<HTMLDivElement>(null);

  // Add state for tracking the width of the report tabs container
  const [reportTabsWidth, setReportTabsWidth] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const appendToReportChatMessages = (newMessages: Message[]) => {
    setReportChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  } = useOpenReferenceDetails(
    loadedData?.documents ?? [],
    loadedData?.citations ?? []
  );

  React.useEffect(() => {
    console.log("PDF Dialog State:", {
      isOpen: isDocViewerOpen,
      doc: document,
      page: initialPage,
      citation,
    });
  }, [isDocViewerOpen, document, initialPage, citation]);

  React.useEffect(() => {
    if (location.state?.hitData) {
      setLoadedData({
        jobId: location.state.hitData.id,
        reportName: location.state.hitData.reportName,
        documents: location.state.hitData.documents,
        fullCoverageReport: location.state.hitData.analysis,
        reportKey: location.state.hitData.reportKey,
        reportType: location.state.hitData.reportType,
        from: location.state.hitData.from,
        followOnQuestions: location.state.hitData.followOnQuestions || [],
        isSuperseded: false, // You might want to include this in the hitData if possible
        oId: location.state.hitData.oId,
        uId: location.state.hitData.uId,
      });

      // Set the report title in the breadcrumb context
      setReportTitle(location.state.hitData.reportName);
    } else {
      // Otherwise, use the loader data
      reportData.then((data) => {
        setLoadedData(data);
        if (data.chatMessages) {
          setReportChatMessages(data.chatMessages);
        }

        // Set the report title in the breadcrumb context
        setReportTitle(data.reportName);
      });
    }

    // Clean up the report title when unmounting
    return () => {
      setReportTitle(null);
    };
  }, [reportData, location.state, job_id, setReportTitle]);

  const context = React.useMemo(() => {
    if (!loadedData) return "";
    if (
      (loadedData && !loadedData.documents) ||
      !(loadedData.documents.length > 0)
    ) {
      return "";
    }
    const { documents, fullCoverageReport } = loadedData;

    // Pass the contents to generateChatContext
    return generateChatContext(
      documents.map((doc) => doc.document),
      fullCoverageReport
    );
  }, [loadedData]);

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  const clearSelectedTextMenuOption = () => {
    setSelectedTextMenuOption(undefined);
  };

  // Handle the start of dragging
  const handleDragStart = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  // Handle dragging
  const handleDrag = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const mouseX = e.clientX - containerRect.left;

      // Calculate percentage width (with limits to prevent panels from becoming too small)
      const minWidth = 250; // Minimum width in pixels
      const maxWidth = containerWidth - minWidth;

      const clampedX = Math.max(minWidth, Math.min(mouseX, maxWidth));
      const newWidthPercent = (clampedX / containerWidth) * 100;

      setReportTabsWidth(newWidthPercent);
    },
    [isDragging]
  );

  // Handle the end of dragging
  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Add event listeners for drag events
  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleDrag);
      window.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [isDragging, handleDrag, handleDragEnd]);

  return (
    <React.Suspense
      fallback={
        <LoadingComponent
          title={
            status === "regen"
              ? "Re-generating Report"
              : status === "update"
              ? "Updating Report"
              : "Generating Report"
          }
          showProgressBar={true}
        />
      }
    >
      <Await resolve={reportData} errorElement={<ErrorBoundaryReport />}>
        {(loadedData: LoaderResponse) => {
          const {
            jobId,
            reportName,
            reportKey,
            documents,
            fullCoverageReport,
            reportType,
            from,
            followOnQuestions,
            coverageReportUrl,
            uId,
            artifacts,
          } = loadedData;

          // Inside the Coverage component
          const policyDetails = getDocumentDetails(documents, "policy");
          const factDetails = getDocumentDetails(documents, "fact");

          console.log("Policy File Name: ", policyDetails.fileName);
          console.log("Policy Presigned URL: ", policyDetails.presignedUrl);
          console.log("Fact File Name: ", factDetails.fileName);
          console.log("Fact Presigned URL: ", factDetails.presignedUrl);

          const initialMessage = generateInitialMessage(
            reportType,
            from,
            followOnQuestions
          );

          return (
            <>
              <div
                id="report-tabs-container"
                className="relative mx-auto flex w-full max-w-[100vw] flex-col gap-4 lg:flex-row"
                ref={containerRef}
              >
                <div
                  id="report-tabs"
                  className="w-full lg:w-auto"
                  ref={reportTabsRef}
                  style={{
                    width: reportTabsWidth ? `${reportTabsWidth}%` : "60%",
                    flexShrink: 0,
                  }}
                >
                  <ReportTabs
                    reportId={jobId}
                    reportName={reportName}
                    coverageReportUrl={coverageReportUrl}
                    fullCoverageReport={fullCoverageReport}
                    docs={documents}
                    version={loadedData.version}
                    openReference={openReference}
                    reportChatMessages={reportChatMessages}
                    reportType={reportType}
                    documents={documents}
                    artifacts={artifacts}
                  />
                  <TextSelectionMenu
                    selectedTextMenuOption={selectedTextMenuOption}
                    setSelectedTextMenuOption={setSelectedTextMenuOption}
                    containerRef={reportTabsRef}
                  />
                </div>

                {/* Improved resizable divider with gap preserved */}
                <div
                  className="relative mx-2 flex items-center justify-center"
                  style={{ cursor: isDragging ? "col-resize" : "default" }}
                >
                  <div className="h-[calc(100vh-4rem)] w-0.5 bg-gray-100"></div>
                  <div
                    className="absolute top-1/2 z-10 flex h-12 w-5 -translate-y-1/2 cursor-col-resize items-center justify-center rounded-sm bg-gray-100 shadow-sm hover:bg-gray-200"
                    onMouseDown={handleDragStart}
                  >
                    <div className="flex flex-col items-center gap-1">
                      <div className="h-2.5 w-0.5 rounded-full bg-gray-400"></div>
                      <div className="h-2.5 w-0.5 rounded-full bg-gray-400"></div>
                    </div>
                  </div>
                </div>

                <div
                  id="chat-container"
                  className="flex h-[calc(100vh-4rem)] flex-grow flex-col overflow-hidden"
                  style={{
                    width: reportTabsWidth
                      ? `${100 - reportTabsWidth - 2}%`
                      : "40%",
                  }}
                >
                  <CopilotKit
                    url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}
                  >
                    <CustomChat
                      context={context}
                      initialMessage={initialMessage}
                      documents={documents}
                      reportKey={reportKey}
                      openReference={openReference}
                      reportSource="auth_ui_report"
                      appendToReportChatMessages={appendToReportChatMessages}
                      initialChatMessages={loadedData.chatMessages}
                      selectedTextMenuOption={selectedTextMenuOption}
                      clearSelectedTextMenuOption={clearSelectedTextMenuOption}
                      oId={loadedData.oId}
                      addCitations={addCitations}
                      userId={uId}
                      reportId={jobId}
                    >
                      {loadedData.latestVersionReportId &&
                        loadedData.latestVersionReportId !== jobId && (
                          <div className="bg-red-500 p-4 text-center text-white">
                            This is an old report (v{loadedData.version}).
                            Please{" "}
                            <a
                              href={`${
                                import.meta.env.VITE_WEB_URL
                              }/report/analysis/${
                                loadedData.latestVersionReportId
                              }`}
                              className="font-bold underline"
                            >
                              click here
                            </a>{" "}
                            for the latest version (v{loadedData.latestVersion}
                            ).
                          </div>
                        )}
                    </CustomChat>
                  </CopilotKit>
                </div>
              </div>

              <PDFViewerDialog
                open={isDocViewerOpen}
                doc={document}
                initialPage={initialPage}
                setDocViewerState={setDocViewerState}
                citation={citation}
              />
            </>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

export default GenReportPrivate;

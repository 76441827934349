import { getFileIcon } from "../Utils/file-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui/button";
import {
  ChevronDown,
  ChevronUp,
  Trash,
  FileIcon,
  Eye,
  Loader2,
} from "lucide-react";
import React, { useState } from "react";
import { DocumentItem, SortField, SortOrder } from "../types";
import { getMimeType } from "../Utils/file-icons";
import format from "date-fns/format";

interface ListViewProps {
  showLoadingMsg: boolean;
  documents: DocumentItem[];
  sortField: SortField;
  sortOrder: SortOrder;
  setSortOrder: (sortOrder: SortOrder) => void;
  setSortField: (field: SortField) => void;
  onDelete: (document: DocumentItem) => void;
  onViewDocument: (document: DocumentItem) => Promise<void>;
}
interface DocumentRowProps {
  document: DocumentItem;
  onDelete: (document: DocumentItem) => void;
  onViewDocument: (document: DocumentItem) => Promise<void>;
}

const ListView: React.FC<ListViewProps> = ({
  showLoadingMsg,
  documents,
  sortField,
  sortOrder,
  setSortOrder,
  setSortField,
  onDelete,
  onViewDocument,
}) => {
  const _handleSort = (field: SortField) => {
    handleSort({ sortField, sortOrder, setSortOrder, setSortField, field });
  };
  const _renderSortIcon = (field: SortField) => {
    return renderSortIcon({ sortField, field, sortOrder });
  };

  if (documents.length === 0 || showLoadingMsg) {
    return (
      <div className="flex h-64 w-full items-center justify-center">
        <div className="text-center">
          <FileIcon className="mx-auto mb-4 h-12 w-12 text-muted-foreground opacity-30" />
          <h3 className="text-lg font-medium">
            {showLoadingMsg ? "Loading..." : "No documents found"}
          </h3>
        </div>
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[30px]"></TableHead>
          <TableHead>
            <Button
              variant="ghost"
              onClick={() => _handleSort("name")}
              className="font-bold"
            >
              Name {_renderSortIcon("name")}
            </Button>
          </TableHead>
          <TableHead>
            {" "}
            <Button
              variant="ghost"
              onClick={() => _handleSort("document_type")}
              className="font-bold"
            >
              Type {_renderSortIcon("document_type")}
            </Button>
          </TableHead>
          {/* <TableHead className="text-center">Shared with Organization</TableHead> */}
          <TableHead>
            <Button
              variant="ghost"
              onClick={() => _handleSort("created_at")}
              className="px-0 font-bold"
            >
              Created At {_renderSortIcon("created_at")}
            </Button>
          </TableHead>
          <TableHead>
            <span className="font-bold">Actions</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {documents.map((document) => {
          return (
            <DocumentRow
              key={document.id}
              document={document}
              onDelete={onDelete}
              onViewDocument={onViewDocument}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const DocumentRow: React.FC<DocumentRowProps> = ({
  document,
  onDelete,
  onViewDocument,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const mimeType = getMimeType(document.filename);
  const FileIcon = getFileIcon(mimeType);
  const formattedDate = (date: number) => format(date * 1000, "MMM d, yyyy");

  const handleViewDocument = async () => {
    setIsLoading(true);
    try {
      await onViewDocument(document);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment key={document.id}>
      <TableRow>
        <TableCell>
          {/* <Button variant="ghost" size="sm" className="h-8 w-8 p-0" onClick={() => toggleExpandRow(document.id)}>
            {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
          </Button> */}
        </TableCell>
        <TableCell>
          <div className="flex items-center space-x-2">
            <FileIcon className="h-4 w-4 text-muted-foreground" />
            <span>{document.filename}</span>
          </div>
        </TableCell>
        <TableCell className="capitalize">
          {document.documentType.replace(/_/g, " ")}
        </TableCell>
        {/* <TableCell className="text-center">
          <div className="flex justify-center">
            <Switch
              checked={false}
              onCheckedChange={() => toggleShared(document.id)}
              aria-label={`Toggle sharing for ${document.filename}`}
            />
          </div>
        </TableCell> */}
        <TableCell>{formattedDate(document.createdAt)}</TableCell>
        <TableCell>
          <div className="flex space-x-2">
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={handleViewDocument}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Eye className="h-4 w-4" />
              )}
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={() => onDelete(document)}
            >
              <Trash className="h-4 w-4" />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {/* {isExpanded && (
        <TableRow>
          <TableCell colSpan={6} className="bg-muted/50">
            <div className="pl-10">
              <DocumentTags
                documentId={doc.id}
                initialTags={doc.tags}
                documentContent={doc.content}
                onTagsUpdate={(newTags) => updateDocumentTags(doc.id, newTags)}
              />
            </div>
          </TableCell>
        </TableRow>
      )} */}
    </React.Fragment>
  );
};

// -------------- HELPER FUNCTIONS --------------

interface SortIconProps {
  sortField: SortField;
  field: SortField;
  sortOrder: SortOrder;
}

const renderSortIcon = ({ sortField, field, sortOrder }: SortIconProps) => {
  if (sortField !== field) {
    return null;
  }
  return sortOrder === "asc" ? (
    <ChevronUp className="ml-1 h-4 w-4" />
  ) : (
    <ChevronDown className="ml-1 h-4 w-4" />
  );
};

interface handleSortProps {
  sortField: SortField;
  sortOrder: SortOrder;
  setSortOrder: (sortOrder: SortOrder) => void;
  setSortField: (field: SortField) => void;
  field: SortField;
}

const handleSort = ({
  sortField,
  sortOrder,
  setSortOrder,
  setSortField,
  field,
}: handleSortProps) => {
  if (sortField === field) {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  } else {
    setSortField(field);
    setSortOrder("asc");
  }
};

export default ListView;

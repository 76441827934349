/**
 * v0 by Vercel.
 * @see https://v0.dev/t/EayyhRAuokY
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import React from "react";
import { AvatarImage, AvatarFallback, Avatar } from "~/components/ui/avatar";
import { Button } from "~/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

import { CustomMarkdown } from "./CustomMarkdown";
import toast from "react-hot-toast";
import { Rating } from "react-simple-star-rating";

import { useMutation } from "react-query";
import { updateMessageRating } from "~/api/query_fns/chat-api";

import { openReferenceType } from "~/doc-util";
import { Message } from "~/components/CustomMessages";

//const MemoizedRating = React.memo(Rating);

interface CustomChatMessageComponentProps {
  message: Message;
  showAvatar?: boolean;
  includeButtons?: boolean;
  openReference: openReferenceType;
  allMessages: Message[];
  ratings: Record<string, number>;
  setRatings: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}

export default function CustomChatMessageComponent({
  message,
  openReference,
  showAvatar = false,
  includeButtons = true,
  allMessages,
  ratings,
  setRatings,
}: CustomChatMessageComponentProps) {
  //console.log("CustomChatMessageComponentCustomChatMessageComponent All messages:", allMessages);
  const lastUserMessage =
    [...allMessages].reverse().find((message) => message.role === "user") ||
    null;
  message.questionId = lastUserMessage?.id;

  //console.log("Last user message ID:", lastUserMessage?.id);
  const messageContent = message.content;
  const rating = ratings[message.id] || 0;

  const ratingLabels = ["INCORRECT", "BAD", "OK", "GOOD", "GREAT"];

  const updateRatingMutation = useMutation(updateMessageRating);

  const handleRating = (messageId: string) => (rate: number) => {
    if (rate !== ratings[messageId]) {
      setRatings((prevRatings) => ({ ...prevRatings, [messageId]: rate }));

      console.log("Attempting to update rating:", { messageId, rate });
      toast.success(`Thank you for your feedback`, {
        position: "bottom-right",
        style: {
          border: "1px solid #000000",
          padding: "16px",
          color: "#000000",
        },
        iconTheme: {
          primary: "#000000",
          secondary: "#FFFAEE",
        },
      });

      updateRatingMutation.mutate(
        { id: messageId, rating: rate },
        {
          onSuccess: (data) => {
            console.log("updateRatingMutation.mutate onSuccess", data);
          },
          onError: (error) => {
            console.error("Error updating rating:", error);
            toast.error("Failed to update rating");
          },
        }
      );
    } else {
      console.log("Rating unchanged:", { messageId, rate });
    }
  };

  const handleCopyClick = () => {
    if (typeof messageContent === "string") {
      navigator.clipboard
        .writeText(messageContent)
        .then(() => {
          toast.success(`Copied to clipboard`, {
            position: "bottom-right",
            style: {
              border: "1px solid #000000",
              padding: "16px",
              color: "#000000",
            },
            iconTheme: {
              primary: "#000000",
              secondary: "#FFFAEE",
            },
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("messageContent is not a string");
    }
  };

  return (
    <TooltipProvider>
      <div
        className="copilotKitMessage copilotKitAssistantMessage mx-auto mt-4 mt-4 w-full max-w-2xl space-y-6 p-6"
        style={{
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

          borderRadius: "5px",
        }}
      >
        <div className="flex items-start gap-4">
          {showAvatar && (
            <Avatar className="h-10 w-10 border">
              <AvatarImage alt="Image" src="/placeholder-user.jpg" />
              <AvatarFallback>Q</AvatarFallback>
            </Avatar>
          )}
          <div className="grid flex-1 gap-2">
            <div className="prose prose-stone" style={{ color: "black" }}>
              <CustomMarkdown
                content={`${messageContent}`}
                openReference={openReference}
                reportId={"na"}
                className="chatMarkdown"
              />
            </div>
            {includeButtons && (
              <div className="mt-4 flex items-center gap-2">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="h-6 w-6 text-gray-400 hover:bg-transparent hover:text-gray-900"
                      size="icon"
                      variant="ghost"
                      onClick={handleCopyClick}
                    >
                      <ClipboardIcon className="h-4 w-4" />
                      <span className="sr-only">Copy</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Copy</p>
                  </TooltipContent>
                </Tooltip>
                <Rating
                  allowTitleTag={false}
                  tooltipDefaultText="CLICK TO RATE"
                  onClick={handleRating(message.id)}
                  initialValue={rating}
                  size={20}
                  SVGclassName={`inline-block`}
                  allowFraction={false}
                  showTooltip
                  tooltipArray={ratingLabels}
                  tooltipStyle={{
                    backgroundColor: "#e0e0e0",
                    color: "#333",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                  fillColor="black"
                  emptyColor="lightgray"
                  allowHover={rating === 0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
}

function ClipboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
    </svg>
  );
}

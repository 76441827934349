import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { useEffect, useState } from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { searchPlugin, SearchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { Citation } from "~/doc-util";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

// Use the boundingBox from Citation type
type BoundingBox = NonNullable<Citation["boundingBox"]>;

const convertToBoxRegionArea = (
  polygon: number[],
  pageIndex: number,
  pageHeight = 11,
  pageWidth = 8.5
) => {
  const xCoordinates = polygon.filter((_, i) => i % 2 === 0);
  const yCoordinates = polygon.filter((_, i) => i % 2 === 1);

  const minX = Math.min(...xCoordinates);
  const maxX = Math.max(...xCoordinates);
  const minY = Math.min(...yCoordinates);
  const maxY = Math.max(...yCoordinates);

  const result = {
    pageIndex: pageIndex - 1,
    left: (minX / pageWidth) * 100,
    top: (minY / pageHeight) * 100,
    width: ((maxX - minX) / pageWidth) * 100,
    height: ((maxY - minY) / pageHeight) * 100,
  };

  console.log("Converting box:", {
    input: { polygon, pageIndex, pageHeight, pageWidth },
    coordinates: { xCoordinates, yCoordinates },
    bounds: { minX, maxX, minY, maxY },
    result,
  });

  return result;
};

const testPolygon = [
  {
    polygon: [0.7354, 5.0417, 4.059, 5.0369, 4.059, 5.2088, 0.7354, 5.2088],
    pageIndex: 0,
    pageHeight: 11,
    pageWidth: 8.5,
  },
];

type MainDocumentColumnProps = {
  presignedUrl: string;
  persistHighlight: () => void;
  hideDocument: boolean;
  highlightedContent: string | null;
  initialPage?: number;
  boundingBox?: BoundingBox[];
};

const MainDocumentColumn = ({
  presignedUrl,
  persistHighlight,
  hideDocument,
  highlightedContent,
  initialPage,
  boundingBox = testPolygon,
}: MainDocumentColumnProps) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);

  // Use the page navigation plugin with its default settings
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [currentPage, setCurrentPage] = useState(initialPage || 0);
  const { jumpToPage } = pageNavigationPluginInstance;

  // Add toolbar plugin with default settings
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    console.log("boundingBox:", boundingBox);
    console.log("initialPage:", initialPage);
  }, [boundingBox, initialPage]);

  // Use search plugin with default rendering for better reliability
  const searchPluginInstance: SearchPlugin = searchPlugin({
    ...(highlightedContent ? { keyword: [highlightedContent] } : {}),
  });
  const { highlight, clearHighlights, setTargetPages } = searchPluginInstance;

  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props) => (
      <div
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        {boundingBox
          .filter((bb) => {
            return bb.pageIndex - 1 === props.pageIndex;
          })
          .map((bb, idx) => {
            const boxRegionArea = convertToBoxRegionArea(
              bb.polygon,
              bb.pageIndex,
              bb.pageHeight,
              bb.pageWidth
            );

            return (
              <div
                key={`bbox-${boxRegionArea.pageIndex}-${idx}`}
                style={{
                  position: "absolute",
                  left: `${boxRegionArea.left}%`,
                  top: `${boxRegionArea.top}%`,
                  width: `${boxRegionArea.width}%`,
                  height: `${boxRegionArea.height}%`,
                  background:
                    "linear-gradient(to right, rgba(0, 255, 127, 0.2) 50%, transparent 50%)",
                  backgroundSize: "200% 100%",
                  backgroundPosition: "right bottom",
                  borderBottom: "3px solid rgba(0, 255, 127, 0.7)",
                  pointerEvents: "none",
                  zIndex: 1000,
                  animation: "highlightIn 3.5s ease-out forwards",
                }}
              />
            );
          })}
      </div>
    ),
    trigger: Trigger.None,
  });

  // programmatically highlight words based on persisted selection
  useEffect(() => {
    if (!isDocumentLoaded) {
      return;
    }

    if (highlightedContent) {
      setTargetPages((targetPage) =>
        // going back from search results deducts a page for unknown reasons
        [currentPage, currentPage + 1].includes(targetPage.pageIndex)
      );
      highlight({
        keyword: highlightedContent,
      });
    } else {
      clearHighlights();
    }
  }, [highlightedContent, isDocumentLoaded]);

  // jump to initial page if one is set in the url
  useEffect(() => {
    if (initialPage && isDocumentLoaded) {
      jumpToPage(initialPage - 1);
    }
  }, [initialPage, isDocumentLoaded]);

  return (
    <div
      className="col-span-3 h-[100vh] w-full"
      style={{ display: hideDocument ? "none" : "block" }}
      onMouseUp={persistHighlight}
    >
      {/* Use the default toolbar which includes search and page navigation */}
      <div className="rpv-core__viewer">
        <div className="rpv-core__inner-container">
          <Toolbar />
        </div>
      </div>

      <Viewer
        fileUrl={presignedUrl}
        plugins={[
          pageNavigationPluginInstance,
          searchPluginInstance,
          highlightPluginInstance,
          toolbarPluginInstance,
        ]}
        onDocumentLoad={(e) => {
          console.log(e);
          setDocumentLoaded(true);
          handleDocumentLoad();
        }}
        onPageChange={(e) => setCurrentPage(e.currentPage)}
        defaultScale={SpecialZoomLevel.PageFit}
      />
    </div>
  );
};
export default MainDocumentColumn;

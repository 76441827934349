import React, { useState, useEffect, useRef } from "react";
import {
  FileText,
  ChevronLeft,
  ChevronRight,
  X,
  MessageSquare,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/ui/button";
import { ChatSession, fetchChatSessions } from "~/api/query_fns/chat-api";

interface ChatSessionsListProps {
  onSessionSelect?: (chatId: string) => void;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

const ChatSessionsList: React.FC<ChatSessionsListProps> = ({
  onSessionSelect,
  isOpen: externalIsOpen,
  onOpenChange,
}) => {
  const [sessions, setSessions] = useState<ChatSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  // Sync with external isOpen state if provided
  useEffect(() => {
    if (externalIsOpen !== undefined) {
      setIsOpen(externalIsOpen);
    }
  }, [externalIsOpen]);

  useEffect(() => {
    const loadSessions = async () => {
      setLoading(true);
      try {
        const response = await fetchChatSessions({ page });
        console.log("chatsession response", response);
        setSessions(response.chatSessions || []);
        setTotalPages(response.pagination?.totalPages || 1);
      } catch (err) {
        console.error("Failed to fetch chat sessions:", err);
        setError("Failed to load chat history");
      } finally {
        setLoading(false);
      }
    };

    loadSessions();
  }, [page]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsOpen(true);
    if (onOpenChange) onOpenChange(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      if (onOpenChange) onOpenChange(false);
    }, 300);
  };

  const handleSessionClick = (chatId: string) => {
    if (onSessionSelect) {
      onSessionSelect(chatId);
      setIsOpen(false);
      if (onOpenChange) onOpenChange(false);
    } else {
      navigate(`/chat/${chatId}`);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onOpenChange) onOpenChange(false);
  };

  const renderContent = () => {
    if (loading && sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <div className="mx-auto h-5 w-5 animate-spin rounded-full border-2 border-[#666666] border-t-transparent"></div>
          <p className="mt-2 text-sm text-[#666666]">Loading chat history...</p>
        </div>
      );
    }

    if (error && sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <p className="text-sm text-[#E63946]">{error}</p>
        </div>
      );
    }

    if (sessions.length === 0) {
      return (
        <div className="p-4 text-center">
          <p className="text-sm text-[#666666]">
            No previous chat sessions found
          </p>
        </div>
      );
    }

    return (
      <div className="max-h-[calc(100vh-180px)] overflow-y-auto scrollbar-thin">
        {sessions.map((session) => (
          <div
            key={session.chatId}
            className="cursor-pointer border-b border-[#E5E5E5] p-4 transition-colors duration-200 hover:bg-[#F7F7F7]"
            onClick={() => handleSessionClick(session.chatId)}
          >
            <div className="mb-1 flex flex-wrap gap-1">
              {session.documentFilenames &&
              session.documentFilenames.length > 0 ? (
                session.documentFilenames.map((filename, index) => (
                  <div
                    key={`${session.chatId}-${index}`}
                    className="flex items-center gap-1 whitespace-nowrap rounded-md border border-[#E5E5E5] bg-[#F7F7F7] px-2 py-1 text-xs"
                  >
                    <FileText size={12} className="text-[#1E1E1E]" />
                    <span className="max-w-[150px] truncate text-[#1E1E1E]">
                      {filename}
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-xs text-[#666666]">
                  Chat history with no documents
                </div>
              )}
            </div>
            {/* Format the date in a more readable way */}
            <div className="mt-1 text-xs text-[#999999]">
              Last updated:{" "}
              {new Date(session.updatedAt).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      ref={listRef}
      className="print:hidden"
      style={{
        position: "fixed",
        top: "80px",
        bottom: "20px",
        right: 0,
        width: isOpen ? "280px" : "32px",
        transition: "all 0.2s ease-in-out",
        overflow: "hidden",
        zIndex: 40,
        borderRadius: "8px 0 0 8px",
        boxShadow: isOpen ? "0px 1px 3px rgba(0, 0, 0, 0.1)" : "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Side navigation container */}
      <div className="flex h-full flex-col">
        {/* Tab indicator */}
        <div className="absolute inset-y-0 left-0 flex w-8 items-center justify-center border-l border-[#E5E5E5] bg-[#F7F7F7] transition-colors duration-200 hover:bg-[#E5E5E5]">
          <div className="flex h-32 flex-col items-center justify-center gap-2">
            <span className="vertical-text whitespace-nowrap text-xs font-medium tracking-wider text-[#1E1E1E]">
              HISTORY
            </span>
            <MessageSquare className="mt-2 h-4 w-4 text-[#1E1E1E]" />
          </div>
        </div>

        {/* Content panel */}
        <div
          className={`absolute inset-0 ml-8 border-l border-[#E5E5E5] bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.1)] transition-all duration-200 ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex items-center justify-between border-b border-[#E5E5E5] bg-[#FAFAFA] p-5">
            <div className="flex items-center gap-2">
              <MessageSquare className="h-5 w-5 text-[#1E1E1E]" />
              <span className="text-xl font-medium text-[#000000]">
                Chat History
              </span>
            </div>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0 hover:bg-[#E5E5E5]"
              onClick={handleClose}
            >
              <X className="h-4 w-4 text-[#1E1E1E]" />
            </Button>
          </div>

          {renderContent()}

          {totalPages > 1 && (
            <div className="sticky bottom-0 flex items-center justify-between border-t border-[#E5E5E5] bg-white p-4">
              <Button
                variant="outline"
                size="sm"
                onClick={handlePreviousPage}
                disabled={page === 1}
                className="rounded-lg border-[#E5E5E5] bg-[#E5E5E5] text-[#000000] hover:bg-[#CCCCCC]"
              >
                <ChevronLeft className="mr-1 h-4 w-4" />
              </Button>
              <span className="text-sm text-[#666666]">
                Page {page} of {totalPages}
              </span>
              <Button
                variant="outline"
                size="sm"
                onClick={handleNextPage}
                disabled={page === totalPages}
                className="rounded-lg border-[#E5E5E5] bg-[#E5E5E5] text-[#000000] hover:bg-[#CCCCCC]"
              >
                <ChevronRight className="ml-1 h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Add CSS for vertical text and scrollbar styling */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .vertical-text {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          transform: rotate(180deg);
        }
        
        /* Webkit scrollbar styling */
        .scrollbar-thin::-webkit-scrollbar {
          width: 4px;
        }
        
        .scrollbar-thin::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .scrollbar-thin::-webkit-scrollbar-thumb {
          background-color: #E5E5E5;
          border-radius: 9999px;
        }
        
        .scrollbar-thin::-webkit-scrollbar-thumb:hover {
          background-color: #1E1E1E;
        }
      `,
        }}
      />
    </div>
  );
};

export default ChatSessionsList;

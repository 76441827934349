import React from "react";
import logoImage from "../../public/images/Qumis_FullColor_Logomark_RGB.png";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { CheckCircle, AlertCircle } from "lucide-react";

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup: React.FC<WelcomePopupProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="overflow-hidden rounded-lg border border-[#E5E5E5] p-0 shadow-[0px_1px_3px_rgba(0,0,0,0.1)] sm:max-w-[550px]">
        <div className="bg-white p-8">
          <div className="text-center">
            <img
              src={logoImage}
              alt="Qumis Logo"
              width={100}
              height={100}
              className="mx-auto mb-7"
            />
            <h2 className="mb-3 text-2xl font-bold text-[#000000]">
              Welcome to Qumis
            </h2>
            <p className="mb-8 text-base text-[#666666]">
              Your intelligent insurance analysis platform
            </p>
          </div>

          <div className="grid gap-6 py-3">
            <div className="rounded-lg border border-[#E5E5E5] bg-[#FAFAFA] p-6 shadow-[0px_1px_3px_rgba(0,0,0,0.05)]">
              <div className="flex items-start">
                <AlertCircle className="mr-5 mt-1 h-6 w-6 flex-shrink-0 text-[#F2994A]" />
                <div>
                  <h3 className="mb-3 text-lg font-medium text-[#000000]">
                    Qumis may not always be right
                  </h3>
                  <p className="leading-relaxed text-[#666666]">
                    Qumis may give inaccurate responses. When in doubt, use the
                    Qumis Clerk chat to ask additional questions and use
                    external sources to verify responses.
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-lg border border-[#E5E5E5] bg-[#FAFAFA] p-6 shadow-[0px_1px_3px_rgba(0,0,0,0.05)]">
              <div className="flex items-start">
                <CheckCircle className="mr-5 mt-1 h-6 w-6 flex-shrink-0 text-[#27AE60]" />
                <div>
                  <h3 className="mb-3 text-lg font-medium text-[#000000]">
                    Qumis will get better with your feedback
                  </h3>
                  <p className="leading-relaxed text-[#666666]">
                    Please rate responses in the chat, particularly the rare
                    instances where responses might be incorrect.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex justify-center">
            <Button
              onClick={onClose}
              className="rounded-lg bg-[#000000] px-10 py-2.5 text-base font-medium text-white transition-colors duration-200 hover:bg-[#1E1E1E]"
            >
              Get Started
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomePopup;

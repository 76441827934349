import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import JobReportSearch from "~/components/Search/v2/JobReports";
import UCTemplateV2 from "~/components/UCTemplateV2";
import { ReportGenerationButtonPropsV2 } from "~/components/UCReportGenerationButtonV2";
import { FileText, Scale, CheckSquare } from "lucide-react";

interface UCReportV2Props {
  reportType: ReportType;
  reportTitle: string;
  newPath: string;
}

const UCPolicyComparisonV3 = ({ reportType, newPath }: UCReportV2Props) => {
  const [additionalNotes] = useState("");
  const [uploadedDocs] = useState<SingleDocResponseData[]>([]);

  const { isLoading } = useGenerateReport(
    uploadedDocs,
    reportType,
    additionalNotes
  );

  const MainComponent = () => (
    <JobReportSearch reportTypes={[reportType, "tower_analysis"]} />
  );

  const buttons = [
    {
      buttonText: "Mulitple Documents",
      description:
        "Compare multiple documents side by side: quotes, policies, binders, applications, etc.",
      route: newPath,
      icon: <FileText />,
    },
    {
      buttonText: "Compare Quotes",
      description: "Compare multiple insurance quotes or policies side by side",
      route: newPath,
      icon: <FileText />,
    },
    {
      buttonText: "Quote vs Policy or Binders",
      description: "Analyze a new quote against an existing policy or binders",
      route: newPath,
      icon: <Scale />,
    },
    {
      buttonText: "Coverage Checklist",
      description: "Generate a detailed coverage comparison checklist",
      route: newPath,
      icon: <CheckSquare />,
    },
  ] as ReportGenerationButtonPropsV2[];

  return (
    <UCTemplateV2
      MainComponent={MainComponent}
      Buttons={buttons}
      isLoading={isLoading}
      loadingTitle="GENERATING COMPARISON(S)  "
      title="Comparisons"
      description="Select Policy Related Comparisons:"
    />
  );
};

export default UCPolicyComparisonV3;

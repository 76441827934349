import { useAsyncError, useParams } from "react-router-dom";
import ErrorReportGenerationComponent from "./ErrorReportGenerationComponent";

const ErrorBoundaryReport = () => {
  const error = useAsyncError() as Error;
  const message: string = error?.message || "Report is no longer available.";

  const { job_id } = useParams<{ job_id: string }>();

  if (job_id) {
    return <ErrorReportGenerationComponent job_id={job_id} error={error} />;
  }

  return (
    <>
      {message === "REPORT_NOT_AVAILABLE" && (
        <div
          className="mx-auto mt-10 max-w-7xl px-4 py-16"
          style={{ borderRadius: "10px" }}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
              borderBottom: "1px solid #ddd",
              paddingBottom: "20px",
            }}
          >
            Report Unavailable
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.6",
              color: "#666",
              margin: "40px 0",
              textAlign: "justify",
              padding: "20px",
            }}
          >
            This report is no longer available. All report related documents are
            deleted after 24 hrs. Please generate another report or if you have
            any issues contact{" "}
            <a
              href="mailto:support@qumis.ai"
              style={{ color: "#337ab7", textDecoration: "none" }}
            >
              support@qumis.ai
            </a>
            .
          </p>
        </div>
      )}
      {message === "ORG_CAP_REACHED" && (
        <div
          className="mx-auto mt-10 max-w-7xl px-4 py-16"
          style={{ borderRadius: "10px" }}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
              borderBottom: "1px solid #ddd",
              paddingBottom: "20px",
            }}
          >
            Report Generation Limit
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.6",
              color: "#666",
              margin: "40px 0",
              textAlign: "center",
              padding: "20px",
            }}
          >
            Your organization has reached its report generation limit. Please
            contact{" "}
            <a
              href="mailto:support@qumis.ai"
              style={{ color: "#337ab7", textDecoration: "none" }}
            >
              support@qumis.ai
            </a>{" "}
            for assistance.
          </p>
        </div>
      )}
      {message !== "REPORT_NOT_AVAILABLE" && message !== "ORG_CAP_REACHED" && (
        <div
          className="mx-auto mt-10 max-w-7xl px-4 py-16"
          style={{ borderRadius: "10px" }}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
              borderBottom: "1px solid #ddd",
              paddingBottom: "20px",
            }}
          >
            Report Unavailable
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.6",
              color: "#666",
              margin: "40px 0",
              textAlign: "justify",
              padding: "20px",
            }}
          >
            The job report you are looking for could not be found. Please check
            the job ID or contact{" "}
            <a
              href="mailto:support@qumis.ai"
              style={{ color: "#337ab7", textDecoration: "none" }}
            >
              support@qumis.ai
            </a>{" "}
            for assistance.
          </p>
        </div>
      )}
    </>
  );
};

export default ErrorBoundaryReport;

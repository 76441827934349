import { List, FileText, Search } from "lucide-react";
import { Input } from "~/components/ui/input";
import { DocumentTypeFilter } from "../Components/DocumentTypeFilter";
import { DocumentTypeEnum } from "~/api";
import { ViewType } from "../types";
import React, { useRef } from "react";

interface HeaderParams {
  currentSearch: string;
  setCurrentSearch: (search: string) => void;
  selectedTypes: DocumentTypeEnum[];
  toggleDocumentType: (type: DocumentTypeEnum) => void;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  itemsPerPage: number;
  totalItemsCount: number;
  filteredItemsCount: number;
}
interface ToggleViewBlockParams {
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
}
interface CountOfResultsProps {
  itemsPerPage: number;
  totalItemsCount: number;
  filteredItemsCount: number;
}
interface SearchBlockParams {
  currentSearch: string;
  setCurrentSearch: (search: string) => void;
  selectedTypes: DocumentTypeEnum[];
  toggleDocumentType: (type: DocumentTypeEnum) => void;
  onSearchClick: () => void;
}

const Header: React.FC<HeaderParams> = ({
  currentSearch,
  setCurrentSearch,
  selectedTypes,
  toggleDocumentType,
  viewType,
  setViewType,
  itemsPerPage,
  totalItemsCount,
  filteredItemsCount,
}) => {
  const resultsRef = useRef<HTMLDivElement>(null);

  const scrollToResults = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mt-8 space-y-6">
      <div className="flex items-center justify-between">
        <ToggleViewBlock viewType={viewType} setViewType={setViewType} />
        <CountOfResults
          ref={resultsRef}
          itemsPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          filteredItemsCount={filteredItemsCount}
        />
      </div>
      <SearchBlock
        currentSearch={currentSearch}
        setCurrentSearch={setCurrentSearch}
        selectedTypes={selectedTypes}
        toggleDocumentType={toggleDocumentType}
        onSearchClick={scrollToResults}
      />
    </div>
  );
};

const ToggleViewBlock: React.FC<ToggleViewBlockParams> = ({
  viewType,
  setViewType,
}) => {
  return (
    <div className="flex items-center gap-2">
      <p className="text-gray-500">Search by:</p>
      <button
        className={`inline-flex items-center justify-center rounded-md p-2 text-sm font-medium transition-colors
          ${
            viewType === "list"
              ? "bg-gray-200"
              : "border border-input bg-background hover:bg-accent hover:text-accent-foreground"
          }`}
        onClick={() => setViewType("list")}
      >
        <List className="h-4 w-4" />
        <span className="ml-2">File Name</span>
      </button>
      <button
        className={`inline-flex items-center justify-center rounded-md p-2 text-sm font-medium transition-colors
          ${
            viewType === "content"
              ? "bg-gray-200"
              : "border border-input bg-background hover:bg-accent hover:text-accent-foreground"
          }`}
        onClick={() => setViewType("content")}
      >
        <FileText className="h-4 w-4" />
        <span className="ml-2">File Content</span>
      </button>
    </div>
  );
};

const CountOfResults = React.forwardRef<HTMLDivElement, CountOfResultsProps>(
  ({ itemsPerPage, totalItemsCount, filteredItemsCount }, ref) => {
    return (
      <div ref={ref}>
        <p className="text-lg font-medium text-gray-700">
          {filteredItemsCount > 0
            ? `Showing ${Math.min(
                itemsPerPage,
                filteredItemsCount
              )} of ${totalItemsCount}`
            : "No results found"}
        </p>
      </div>
    );
  }
);

const SearchBlock: React.FC<SearchBlockParams> = ({
  currentSearch,
  setCurrentSearch,
  selectedTypes,
  toggleDocumentType,
  onSearchClick,
}) => {
  return (
    <div className="space-y-6">
      {/* Search results displayed at the top */}
      {currentSearch && (
        <div className="rounded-md border border-gray-300 bg-gray-100 p-4">
          <p className="text-sm font-medium text-gray-800">
            Search results for:{" "}
            <span className="font-bold">{currentSearch}</span>
          </p>
        </div>
      )}

      <div className="space-y-6">
        {/* Search input */}
        <div className="w-full">
          <div className="flex w-full items-center rounded-md border border-gray-900 bg-white px-4 py-3 shadow-sm">
            <Search className="mr-3 h-5 w-5 text-gray-700" />
            <Input
              type="text"
              placeholder="Search documents..."
              value={currentSearch}
              onChange={(e) => setCurrentSearch(e.target.value)}
              className="h-7 flex-1 border-0 p-0 text-black placeholder:text-gray-500 focus-visible:ring-0"
              onClick={onSearchClick}
            />
          </div>
        </div>

        {/* Document type filters */}
        <div className="pt-2">
          <h3 className="mb-3 text-sm font-semibold text-gray-700">
            Filter by document type:
          </h3>
          <DocumentTypeFilter
            selectedTypes={selectedTypes}
            onTypeToggle={toggleDocumentType}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;

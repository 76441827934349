"use client";

import React, { useState } from "react";
import { LoadingComponent } from "~/utils/util-report";
import { Card, CardContent } from "~/components/ui/card";
import { useNavigate } from "react-router-dom";

export interface ButtonComponent {
  buttonText: string;
  route: string;
  icon: React.ReactNode;
  description: string;
}

interface UCTemplatePropsV2 {
  MainComponent: React.ComponentType;
  Buttons: ButtonComponent[];
  isLoading?: boolean;
  loadingTitle?: string;
  enableLoading?: boolean;
  title: string;
  description?: string;
}

const UCTemplateV2: React.FC<UCTemplatePropsV2> = ({
  MainComponent,
  Buttons,
  isLoading = false,
  loadingTitle = "LOADING",
  enableLoading = true,
  title,
  description = "Select the following options:",
}) => {
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  console.log("selectedButton", selectedButton);
  const navigate = useNavigate();

  if (isLoading && enableLoading) {
    return <LoadingComponent title={loadingTitle} />;
  }

  return (
    <div className="flex h-[88vh] flex-col overflow-hidden">
      <Card className="flex-shrink-0 rounded-lg bg-white p-6 shadow-sm">
        <h2 className="mb-2 px-4 text-[24px] text-[#000000]">{title}</h2>
        <p className="mb-4 px-4 text-[14px] text-[#666666]">{description}</p>
        <div className="grid grid-cols-1 justify-between gap-4 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {Buttons.map((button, index) => (
            <Card
              key={index}
              className={`group cursor-pointer rounded-lg border border-[#E5E5E5] 
                shadow-sm transition-all duration-200
                ease-in-out hover:border-[#1E1E1E] hover:shadow-md`}
              onClick={() => {
                setSelectedButton(button.route);
                navigate(button.route);
              }}
            >
              <CardContent className="flex items-start space-x-4 p-4">
                <div
                  className={`rounded-full bg-[#F7F7F7] p-2 transition-colors duration-200
                    group-hover:bg-[#1E1E1E] group-hover:text-white`}
                >
                  {button.icon}
                </div>
                <div className="flex-1">
                  <h3 className="text-[16px] font-medium text-[#1E1E1E]">
                    {button.buttonText}
                  </h3>
                  <p className="mt-1 text-[14px] leading-[1.5] text-[#666666]">
                    {button.description}
                  </p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </Card>

      <Card className="mt-4 flex-1 overflow-hidden rounded-lg border-[#E5E5E5] bg-white p-6 shadow-sm">
        <div className="h-full">
          <MainComponent />
        </div>
      </Card>
    </div>
  );
};

export default UCTemplateV2;

import {
  List,
  FileText,
  ArrowUpDown,
  Calendar,
  ArrowUpAZ,
  ArrowDownAZ,
  ArrowDownUp,
} from "lucide-react";
import { Button } from "~/components/ui/button";

interface HeaderProps {
  showLoadingMsg: boolean;
  title: string;
  itemsPerPage: number;
  totalItemsCount: number;
  filteredItemsCount: number;
  viewMode: string;
  search: string;
  setViewMode: (value: "list" | "search") => void;
  enableToggle?: boolean;

  enableSortByToggle?: boolean;
  sortByDirection?: string;
  setSortByDirection?: (value: string) => void;
  sortByField?: string;
  setSortByField?: (value: string) => void;
}
interface TitleProps {
  showLoadingMsg: boolean;
  title: string;
  itemsPerPage: number;
  totalItemsCount: number;
  filteredItemsCount: number;
}
interface ToggleProps {
  viewMode: string;
  search: string;
  setViewMode: (value: "list" | "search") => void;
}
interface SortByToggleProps {
  sortByField: string;
  setSortByField: (value: string) => void;
  sortByDirection: string;
  setSortByDirection: (value: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  showLoadingMsg,
  title,
  itemsPerPage,
  totalItemsCount,
  filteredItemsCount,
  viewMode,
  setViewMode,
  search,
  enableToggle = false,
  enableSortByToggle = false,
  sortByDirection,
  setSortByDirection,
  sortByField,
  setSortByField,
}) => {
  return (
    <div className="flex items-start justify-between">
      {enableToggle && (
        <ToggleComponent
          viewMode={viewMode}
          setViewMode={setViewMode}
          search={search}
        />
      )}
      <TitleComponent
        showLoadingMsg={showLoadingMsg}
        title={title}
        itemsPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        filteredItemsCount={filteredItemsCount}
      />
      {enableSortByToggle &&
        sortByDirection &&
        setSortByDirection &&
        sortByField &&
        setSortByField && (
          <SortByComponent
            sortByField={sortByField}
            setSortByField={setSortByField}
            sortByDirection={sortByDirection}
            setSortByDirection={setSortByDirection}
          />
        )}
    </div>
  );
};

// --------------- HELPERS ---------------

const SortByComponent: React.FC<SortByToggleProps> = ({
  sortByField,
  setSortByField,
  sortByDirection,
  setSortByDirection,
}) => {
  const toggleSortByDirection = (field: string) => {
    if (!setSortByDirection) return;

    if (field === sortByField) {
      setSortByDirection(sortByDirection === "asc" ? "desc" : "asc");
    } else {
      setSortByField(field);
      setSortByDirection("asc");
    }
  };

  return (
    <div className="flex gap-2">
      <SortByReportName
        sortByField={sortByField}
        sortByDirection={sortByDirection}
        toggleSortByDirection={toggleSortByDirection}
      />
      <SortByCreatedAt
        sortByField={sortByField}
        sortByDirection={sortByDirection}
        toggleSortByDirection={toggleSortByDirection}
      />
    </div>
  );
};

interface SortByReportNameProps {
  sortByField: string;
  sortByDirection: string;
  toggleSortByDirection: (value: string) => void;
}

const SortByReportName: React.FC<SortByReportNameProps> = ({
  sortByField,
  sortByDirection,
  toggleSortByDirection,
}) => {
  const isActive = sortByField === "report_name";
  const isAsc = sortByDirection === "asc";

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={toggleSortByDirection.bind(null, "report_name")}
      className={`flex w-[50px] items-center gap-2 p-2`}
    >
      <div className="relative flex">
        {isActive ? (
          isAsc ? (
            <ArrowUpAZ size={20} />
          ) : (
            <ArrowDownAZ size={20} />
          )
        ) : (
          <ArrowDownAZ size={20} />
        )}
      </div>
    </Button>
  );
};

interface SortByCreatedAtProps {
  sortByField: string;
  sortByDirection: string;
  toggleSortByDirection: (value: string) => void;
}

const SortByCreatedAt: React.FC<SortByCreatedAtProps> = ({
  sortByField,
  sortByDirection,
  toggleSortByDirection,
}) => {
  const isActive = sortByField === "created_at";
  const isAsc = sortByDirection === "asc";

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={toggleSortByDirection.bind(null, "created_at")}
      className={`flex w-[50px] items-center gap-2 p-2`}
    >
      <div className="relative flex gap-1">
        <Calendar size={16} />
        {isActive ? (
          isAsc ? (
            <ArrowUpDown className="h-4 w-4 [&>path:nth-child(3)]:stroke-[3] [&>path:nth-child(4)]:stroke-[3]" />
          ) : (
            <ArrowDownUp className="h-4 w-4 [&>path:nth-child(1)]:stroke-[3] [&>path:nth-child(2)]:stroke-[3]" />
          )
        ) : (
          <ArrowDownUp className="h-4 w-4" />
        )}
      </div>
    </Button>
  );
};

const TitleComponent: React.FC<TitleProps> = ({
  showLoadingMsg,
  itemsPerPage,
  totalItemsCount,
  filteredItemsCount,
}) => {
  const msg = showLoadingMsg
    ? "Loading..."
    : filteredItemsCount > 0
    ? `Showing ${Math.min(
        itemsPerPage,
        filteredItemsCount
      )} of ${totalItemsCount}`
    : "";

  return (
    <div className="min-h-[28px] space-y-2">
      {/*   <h1 className="text-2xl font-bold tracking-tight">{title}</h1> */}
      <p className="text-lg text-muted-foreground">{msg}</p>
    </div>
  );
};

const ToggleComponent: React.FC<ToggleProps> = ({ viewMode, setViewMode }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <p className="text-gray-500">Search by:</p>
        <div className="inline-flex h-9 items-center rounded-md border bg-muted p-1">
          <button
            className={`inline-flex items-center justify-center rounded px-3 py-1 text-sm font-medium transition-colors ${
              viewMode === "list"
                ? "bg-background text-foreground shadow-sm"
                : "text-muted-foreground hover:bg-muted hover:text-foreground"
            }`}
            onClick={() => setViewMode("list")}
          >
            <List className="mr-2 h-4 w-4" />
            Name
          </button>
          <button
            className={`inline-flex items-center justify-center rounded px-3 py-1 text-sm font-medium transition-colors ${
              viewMode === "search"
                ? "bg-background text-foreground shadow-sm"
                : "text-muted-foreground hover:bg-muted hover:text-foreground"
            }`}
            onClick={() => setViewMode("search")}
          >
            <FileText className="mr-2 h-4 w-4" />
            Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;

import {
  createSearchDocumentsRequestParams,
  createSearchDocumentsRequest,
  createSearchDocumentsResponse,
} from "~/utils/typesense/customSearch/createSearchDocumentsRequest";
import initConnerction from "~/utils/state/store";
import { ViewType } from "./types";

interface loadDataProps {
  viewType: ViewType;
  selectedTypes: string[];
  currentSearch: string;
  currentPage: number;
  sortField: string;
  sortOrder: string;
}

const requestParams = ({
  viewType,
  selectedTypes,
  currentSearch,
  currentPage,
  sortField,
  sortOrder,
}: loadDataProps): createSearchDocumentsRequestParams => {
  return {
    documentsParams: {
      query: currentSearch,
      queryBy: viewType === "list" ? "filename" : "",
      sortBy: sortField && sortOrder ? `${sortField}:${sortOrder}` : undefined,
      filterBy:
        selectedTypes.length > 0
          ? `document_type:[${selectedTypes.join(", ")}]`
          : "",
      facetBy: "document_type,user_id,organization_id",
      page: currentPage,
      hitsPerPage: viewType == "list" ? 10 : 5,
    },
    documentsContentParams: {
      query: currentSearch,
      queryBy: viewType === "content" ? "content" : "",
      filterBy: "",
      facetBy: "organization_id",
    },
  };
};

const initStore = () => {
  const store = initConnerction<createSearchDocumentsResponse>(
    createSearchDocumentsRequest
  );

  const loadData = ({
    viewType,
    selectedTypes,
    currentSearch,
    currentPage,
    sortField,
    sortOrder,
  }: loadDataProps) => {
    const params = requestParams({
      viewType,
      selectedTypes,
      currentSearch,
      currentPage,
      sortField,
      sortOrder,
    });
    store.actions.loadData(params);
  };

  return {
    state: store.state,
    actions: { ...store.actions, loadData },
  };
};

export { initStore };

import { apiFn } from "~/api/utils";

export interface BoundingBox {
  polygon: number[];
  pageIndex: number;
  pageHeight?: number;
  pageWidth?: number;
}

export interface Citation {
  id: string;
  filename: string;
  page: number;
  quote: string;
  matched: boolean;
  boundingBox?: BoundingBox;
}

export interface CitationServiceDocument {
  id: string;
  document_type: string;
  text_url: string;
  filename: string;
  file_hash: string;
}

export interface CitationServiceRequest {
  content: string;
  documents: CitationServiceDocument[];
  o_id: string;
  report_id?: string;
}

export interface CitationServiceResponse {
  response: string;
  citations: Citation[];
}

// Citation service API function
export const getCitations = apiFn<
  CitationServiceRequest,
  CitationServiceResponse
>("POST", "/api/citation-service", import.meta.env.VITE_LAMBDA_URL, false);

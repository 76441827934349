import DocumentManagement from "./DocumentsManagement";

const UCDocumentManagement: React.FC = () => {
  return (
    <div className="mx-auto max-w-[80%] gap-4">
      <DocumentManagement />
    </div>
  );
};

export default UCDocumentManagement;

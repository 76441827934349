import { format } from "date-fns";
import { FileText } from "lucide-react";
import { ElementProps } from "../types";
import { useState } from "react";

interface FilesProps {
  element: ElementProps;
  search: string;
}
interface ListViewProps {
  list: ElementProps[];
  search: string;
  linkAction?: (hit: any) => void;
}

const ListView: React.FC<ListViewProps> = ({ list, search, linkAction }) => {
  const formattedDate = (date: number) => format(date * 1000, "MMM d, yyyy");

  return (
    <div className="space-y-4 py-4">
      {list.map((element) => (
        <div
          key={element.id}
          className={`rounded-md border bg-white shadow-sm ${
            linkAction ? "cursor-pointer hover:bg-accent" : ""
          }`}
          onClick={() => linkAction && linkAction(element)}
        >
          <div className="flex flex-row items-start justify-between p-4">
            <h3 className="flex-1 pr-2 text-lg font-semibold leading-tight">
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightContent(element.title, search),
                }}
              />
            </h3>
            <span className="inline-flex items-center rounded-full bg-secondary px-2 py-0.5 text-xs font-semibold text-secondary-foreground">
              {formattedDate(element.createdAt)}
            </span>
          </div>
          <Files element={element} search={search} />
        </div>
      ))}
    </div>
  );
};

const Files: React.FC<FilesProps> = ({ element, search }) => {
  const [showAll, setShowAll] = useState(false);
  if (element.filenames.length === 0) return null;
  const filenames = showAll ? element.filenames : element.filenames.slice(0, 3);
  const hasMore = element.filenames.length > 3;

  return (
    <div className="flex flex-wrap gap-1 px-4 pb-4">
      {filenames.map((filename, i) => (
        <button
          key={i}
          className="inline-flex h-8 items-center gap-1 rounded-md border bg-background px-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground"
        >
          <FileText className="h-4 w-4 shrink-0" />
          <span
            className="inline-block overflow-hidden text-ellipsis whitespace-nowrap"
            dangerouslySetInnerHTML={{
              __html: highlightContent(filename, search),
            }}
          />
        </button>
      ))}
      {hasMore && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowAll(!showAll);
          }}
          className="inline-flex h-8 items-center gap-1 rounded-md border bg-background px-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground"
        >
          {showAll ? "Show less" : `+${element.filenames.length - 3} Show more`}
        </button>
      )}
    </div>
  );
};

const highlightContent = (html: string, search: string) => {
  if (!search || search === "") return html;
  const searchRegex = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return html.replace(
    new RegExp(`(${searchRegex})`, "gi"),
    "<span class='bg-yellow-200 dark:bg-yellow-800'>$1</span>"
  );
};

export default ListView;

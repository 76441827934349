import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog-background";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { DocViewerState, Citation } from "~/doc-util";
import { DocumentTypeEnum, getDocument } from "~/api";
import { ChevronLeft } from "lucide-react";
import CustomDocViewer from "./DocViewer";

interface PDFViewerDialogProps {
  doc: SingleDocResponseData | null;
  initialPage: number;
  setDocViewerState: (state: DocViewerState) => void;
  open: boolean;
  citation: Citation | null;
}

export interface DocumentItem {
  id: string;
  filename: string;
  documentType: DocumentTypeEnum;
  userEmail: string;
  createdAt: number;
  presignedUrl?: string; // Optional presigned URL for document download/view
}
interface openDocumentViewerProps {
  document: DocumentItem;
  setDocViewerState: (state: DocViewerState) => void;
}

// Function to open a document in the PDF viewer
export const openDocumentViewer = async ({
  document,
  setDocViewerState,
}: openDocumentViewerProps) => {
  try {
    // Fetch the document using the getDocument API
    const response = await getDocument({ id: document.id });

    // Use the fetched document data with the presigned URL
    setDocViewerState({
      isOpen: true,
      doc: response,
      page: 1,
      citation: null,
    });
  } catch (error) {
    console.error("Error fetching document:", error);
    // Fallback to using the document data we already have
    const docData: SingleDocResponseData = {
      document: {
        id: document.id,
        filename: document.filename,
        documentType: document.documentType,
        // Required properties in DocumentType
        organizationName: "", // Default value
        uploaderEmail: document.userEmail || "",
        createdAt: new Date(document.createdAt * 1000).toISOString(),
        claimNumber: "", // Default value
        claimColor: "", // Default value
      },
      // Use a default URL if presignedUrl is not available
      presignedUrl:
        document.presignedUrl ||
        `${import.meta.env.VITE_API_URL}/api/documents/${document.id}/download`,
      existingDoc: true,
    };

    setDocViewerState({
      isOpen: true,
      doc: docData,
      page: 1,
      citation: null,
    });
  }
};

export const PDFViewerDialog: React.FC<PDFViewerDialogProps> = ({
  open,
  doc,
  initialPage,
  setDocViewerState,
  citation,
}) => {
  const { isPdfViewer, docFileType } = parseDoc(doc);
  console.log("PDFViewerDialog", doc, isPdfViewer, docFileType);
  return (
    <Dialog
      open={open}
      defaultOpen={true}
      modal={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setDocViewerState({
            isOpen: false,
            doc: doc,
            page: initialPage,
            citation: citation,
          });
        }
      }}
    >
      <DialogContent
        className="fixed relative top-[20vh] h-[95vh] transform-gpu overflow-hidden rounded-none border border-gray-100 bg-white shadow-2xl transition-all duration-200 dark:border-gray-800 dark:bg-gray-900"
        style={{
          position: "fixed",
          left: "0",
          width: "100vw",
          height: "100vh",
          top: "0vh",
          margin: "0",
          zIndex: 1000,
          transform: "none",
          borderRight: "2px solid rgba(0,0,0,0.1)",
          boxShadow: "4px 0 8px rgba(0,0,0,0.1)",
        }}
      >
        <div
          className="absolute bottom-0 right-0 z-[999]"
          style={{
            width: "40px",
            height: "40px",
            background: "linear-gradient(135deg, transparent 50%, #f0f0f0 50%)",
            borderLeft: "1px solid rgba(0,0,0,0.1)",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            transform: "translate(1px, 1px)",
          }}
        />

        <button
          onClick={() =>
            setDocViewerState({
              isOpen: false,
              doc: doc,
              page: initialPage,
              citation: citation,
            })
          }
          className="absolute right-0 top-1/2 z-[10000] -translate-y-1/2 rounded-l-md bg-gray-100 p-2 transition-all duration-200 hover:-translate-x-1 hover:bg-gray-200 hover:shadow-md dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>

        <DialogHeader className="border-b px-3 py-1 dark:border-gray-800">
          <div className="flex items-center">
            <DialogTitle className="text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
              {doc?.document.filename || "Document Viewer"}
            </DialogTitle>
          </div>
          <DialogDescription className="text-xs text-gray-500 dark:text-gray-400">
            Page {initialPage}
          </DialogDescription>
        </DialogHeader>

        <div className="max-h-[calc(98vh-60px)] overflow-auto px-3 py-1">
          {doc &&
            (isPdfViewer ? (
              <MainDocument
                presignedUrl={doc.presignedUrl || ""}
                persistHighlight={() => null}
                hideDocument={false}
                highlightedContent={""}
                initialPage={initialPage || 0}
                boundingBox={
                  citation?.boundingBox ? [citation.boundingBox] : undefined
                }
              />
            ) : (
              <CustomDocViewer
                fileUrl={doc.presignedUrl || ""}
                fileType={docFileType || ""}
              />
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

// --------------- HELPER FUNCTIONS ---------------

const MICROSOFT_DOC_FORMATS = ["docx", "doc", "xls", "xlsx"];

interface ParseDocResult {
  isPdfViewer: boolean;
  isMicrosoftDoc: boolean;
  docFileType: string;
}
const parseDoc = (doc: SingleDocResponseData | null): ParseDocResult => {
  if (!doc)
    return { isPdfViewer: false, isMicrosoftDoc: false, docFileType: "" };

  const _filename = doc.document.filename;
  const isMicrosoftDoc = MICROSOFT_DOC_FORMATS.some((format) =>
    _filename.includes(format)
  );
  const docFileType = _filename.split(".").pop() || "";

  return { isPdfViewer: !isMicrosoftDoc, isMicrosoftDoc, docFileType };
};

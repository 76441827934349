import { Reducer, useReducer } from "react";
import { Action, DefaultState } from "./types";
import Actions from "./actions";

const initConnerction = <DataParams>(
  connection: (params: any) => Promise<DataParams>
) => {
  // Reducer
  const reducer: Reducer<DefaultState<DataParams>, Action<DataParams>> = (
    state,
    action
  ) => {
    // console.log(action.type, state, action);
    switch (action.type) {
      case "SET_ORIGINAL_DATA":
        return { ...state, initialData: action.data, data: action.data };
      case "SET_RELOADED_DATA":
        return { ...state, data: action.data };
      case "SET_LOADING":
        return { ...state, isLoading: action.value };
      case "SET_ERROR":
        return { ...state, error: action.value };
      case "SET_READY":
        return { ...state, isReady: action.value };
      default:
        return state;
    }
  };
  // InitialState
  const initialState: DefaultState<DataParams> = {
    initialData: null,
    data: null,
    isLoading: false,
    isReady: false,
    error: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Actions
  const loadData = Actions.loadData<DataParams>({
    state,
    dispatch,
    connection,
  });

  return {
    state,
    actions: { loadData },
  };
};

export default initConnerction;

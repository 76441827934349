import { Search } from "lucide-react";

interface SearchContainerProps {
  currentRefinement: string;
  refine: (value: string) => void;
  setViewMode: (value: "list" | "search") => void;
}

const SearchContainer = ({
  currentRefinement,
  refine,
  setViewMode,
}: SearchContainerProps) => {
  console.log(setViewMode);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    refine(e.target.value);
    // e.target.value.length > 0 && setViewMode("search");
  };

  return (
    <div className="relative">
      <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
      <input
        type="search"
        value={currentRefinement}
        onChange={handleSearchChange}
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 pl-9 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        placeholder="Search reports..."
      />
    </div>
  );
};

export default SearchContainer;

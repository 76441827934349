/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";
import { Input } from "~/components/ui/input";
import { useState, useEffect } from "react";
import { ReportOptionEnum } from "~/api/query_fns/coverage-analysis";
import { useLocation } from "react-router-dom";

export default function UCClaimCoverageNew() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reportSubType = searchParams.get("reportSubType");

  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
    handleDeleteUploadedDoc,
    uploadedDocs,
    claimNumber,
    setClaimNumber,
    reportOptions,
    toggleReportOption,
    setReportOptions,
  } = useGenerateReportNew("policy_fact");

  const [isClaimNumberComplete, setIsClaimNumberComplete] = useState(false);
  const [validationState, setValidationState] = useState({
    hasPolicyDocs: false,
    hasFactDocs: false,
  });

  // Set the audit option automatically when in audit mode
  useEffect(() => {
    if (reportSubType === "audit") {
      // Ensure the audit option is selected and can't be toggled off
      if (!reportOptions.includes(ReportOptionEnum.POLICY_FACT_AUDIT_CLAIM)) {
        setReportOptions([ReportOptionEnum.POLICY_FACT_AUDIT_CLAIM]);
      }
    }
  }, [reportSubType, reportOptions, setReportOptions]);

  useEffect(() => {
    const policyDocs = uploadedDocs.filter(
      (doc) => doc.document.documentType === DocumentTypeEnum.Policy
    );
    const factDocs = uploadedDocs.filter(
      (doc) => doc.document.documentType === DocumentTypeEnum.Fact
    );

    setValidationState({
      hasPolicyDocs: policyDocs.length > 0,
      hasFactDocs: factDocs.length > 0,
    });
  }, [uploadedDocs]);

  const handleClaimNumberChange = (value: string) => {
    setClaimNumber(value);
    setIsClaimNumberComplete(value.trim() !== "");
  };

  if (isLoading) {
    return <LoadingComponent title="GENERATING COVERAGE ANALYSIS" />;
  }

  // Set title based on reportSubType
  const title =
    reportSubType === "coverage"
      ? "Generate Coverage"
      : reportSubType === "letters"
      ? "Generate Letters"
      : reportSubType === "active"
      ? "Ongoing Claim Update"
      : reportSubType === "audit"
      ? "Audit Closed Claim"
      : "Generate Claim Analysis";

  return (
    <StepReportComponent
      title={title}
      lastStepName="Generate Coverage Report"
      steps={[
        {
          number: 1,
          label: "Additional Information",
          description:
            "Please note that this text box is for additional context...",
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
              reportOptions={reportOptions}
              toggleReportOption={toggleReportOption}
            />
          ),
          validate: () => true, // Optional step, always valid
        },
        {
          number: 1,
          label: "Claim Number",
          description: "Enter Claim or Matter Number",
          component: (
            <ClaimNumberComponent
              claimNumber={claimNumber}
              setClaimNumber={handleClaimNumberChange}
            />
          ),
          validate: () => isClaimNumberComplete,
          getValidationMessages: () => ["Please enter a claim number"],
          isMandatory: true,
        },
        {
          number: 2,
          label: "Policy Documents",
          description:
            "Upload policy documents. Up to 1000 pages total of policy and fact documents per claim.",
          component: (
            <PolicyDocumentsComponent
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          ),
          validate: () => validationState.hasPolicyDocs,
          getValidationMessages: () => [
            "Please upload at least one policy document",
          ],
        },
        {
          number: 3,
          label: "Fact Patterns",
          description: "Upload any facts related to this claim.",
          component: (
            <FactPatternsComponent
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          ),
          validate: () => validationState.hasFactDocs,
          getValidationMessages: () => [
            "Please upload at least one fact document",
          ],
        },
      ]}
      onFinalStep={handleGenerateReport} // Pass the final step handler
      onCancelPath="/claim-coverage"
    />
  );
}

interface PolicyDocumentsProps {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}

const PolicyDocumentsComponent: React.FC<PolicyDocumentsProps> = ({
  uploadedDocs,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}) => {
  const policyDocs = uploadedDocs.filter(
    (doc) => doc.document.documentType === DocumentTypeEnum.Policy
  );

  return (
    <div id="step1" className="flex w-full flex-col">
      <Card className="p-3">
        <UploadArea
          showSearch={true}
          uploadedDocs={policyDocs}
          db_document_type={DocumentTypeEnum.Policy}
          onUploadComplete={handleUploadComplete}
          onDeleteDocument={handleDeleteUploadedDoc}
          validateFiles={() => policyDocs.length > 0}
          availableDocumentTypes={[DocumentTypeEnum.Policy]}
        />
      </Card>
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
  reportOptions,
  toggleReportOption,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
  reportOptions: ReportOptionEnum[];
  toggleReportOption: (option: ReportOptionEnum) => void;
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reportSubType = searchParams.get("reportSubType");

  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
      handleDeleteUploadedDoc={handleDeleteUploadedDoc}
      includeUploadHeading={false}
      includeUploadArea={true}
      showReportOptions={true}
      reportOptions={reportOptions}
      toggleReportOption={toggleReportOption}
      reportType="policy_fact"
      reportSubType={reportSubType || undefined}
    />
  );
};

interface FactPatternsProps {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}

const FactPatternsComponent: React.FC<FactPatternsProps> = ({
  uploadedDocs,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}) => {
  const factDocs = uploadedDocs.filter(
    (doc) => doc.document.documentType === DocumentTypeEnum.Fact
  );

  return (
    <div id="step2" className="flex w-full flex-col">
      <Card className="p-3">
        <UploadArea
          showSearch={true}
          uploadedDocs={factDocs}
          db_document_type={DocumentTypeEnum.Fact}
          onUploadComplete={handleUploadComplete}
          onDeleteDocument={handleDeleteUploadedDoc}
          validateFiles={() => factDocs.length > 0}
          availableDocumentTypes={[DocumentTypeEnum.Fact]}
        />
      </Card>
    </div>
  );
};

interface ClaimNumberComponentProps {
  claimNumber: string;
  setClaimNumber: (claimNumber: string) => void;
}

const ClaimNumberComponent: React.FC<ClaimNumberComponentProps> = ({
  claimNumber,
  setClaimNumber,
}) => {
  return (
    <div>
      <Input
        className="mt-2"
        type="text"
        id="claimNumber"
        name="claimNumber"
        placeholder="Example: 0001_CLAIM"
        value={claimNumber}
        onChange={(e) => setClaimNumber(e.target.value)}
        required
      />
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import { connectSearchBox } from "react-instantsearch-dom";
import { createTypesenseSearchClient } from "../utils/typesense/instantSearch/CreateTypesenseSearchClient";
import { format } from "date-fns";
import { Input } from "~/components/ui/input";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Paragraph } from "~/components/ui/paragraph";
import { getDocument } from "~/api";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { SearchBoxProvided } from "react-instantsearch-core";
import { UploadedFile } from "~/routes/Documents/types";
import { CheckIcon, Eye, Loader2 } from "lucide-react";
import {
  DocumentItem,
  openDocumentViewer,
  PDFViewerDialog,
} from "./PDFViewerDialog";
import { useDocViewer } from "~/doc-util";
import { Button } from "./ui/button";

type HitType = {
  id: string;
  filename: string;
  pages: string;
  userEmail: string;
  createdAt: number;
  claimNumber?: string;
  documents?: Array<{ id: string; filename: string }>;
  objectID: string;
};

interface HitsProps {
  hits: HitType[];
  showPreview: boolean;
  wasViewedDoc: DocumentItem | null;
  onDocumentSelect: (doc: SingleDocResponseData) => void;
  completedFiles: UploadedFile[];
  handleOpenDocumentViewer: (document: DocumentItem) => void;
}

const CustomHits: React.FC<HitsProps> = ({
  handleOpenDocumentViewer,
  hits,
  showPreview,
  wasViewedDoc,
  onDocumentSelect,
  completedFiles,
}) => {
  const completedFileIds = completedFiles.map(
    ({
      docResponse: {
        document: { id },
      },
    }) => id
  );
  const hitsWrapperRef = useRef<HTMLDivElement>(null);
  const [focusedHit, setFocusedHit] = useState<HitType | null>(null);

  // Handle selection of a hit
  const onHitSelect = async (hit: HitType, isCompleted: boolean) => {
    console.log("isCompleted:", hit.id, isCompleted);
    if (isCompleted) {
      return;
    }

    console.log("Hit ID:", hit.id);
    if (hit.id) {
      try {
        const doc = await getDocument({ id: hit.id || "" });
        onDocumentSelect(doc);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
  };

  // Handle key presses
  const _onKeyPress = (e: KeyboardEvent) => {
    const _hits = showPreview ? hits : [];
    onKeyPress({
      hits: _hits,
      e,
      focusedHit,
      setFocusedHit,
      onHitSelect,
      isCompleted: completedFileIds.includes(focusedHit?.id || ""),
    });
  };
  useEffect(() => {
    window.removeEventListener("keydown", _onKeyPress);
    window.addEventListener("keydown", _onKeyPress);
    return () => {
      window.removeEventListener("keydown", _onKeyPress);
    };
  }, [hits, focusedHit, completedFileIds]);

  // Scroll to the focused hit
  useEffect(() => {
    if (focusedHit && hitsWrapperRef.current) {
      const focusedElement = hitsWrapperRef.current.querySelector(
        `[data-hit-id="${focusedHit.id}"]`
      );
      if (focusedElement) {
        focusedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [focusedHit]);

  return (
    <>
      {showPreview && (
        <div
          ref={hitsWrapperRef}
          className="hits-wrapper absolute left-8 z-[10] max-h-[200px] w-[calc(100%-4rem)] overflow-y-auto border border-[hsl(var(--input))] bg-white px-[10px]"
        >
          {hits.length > 0 ? (
            <div className="">
              {hits.map((hit) => (
                <Hit
                  key={hit.id}
                  hit={hit}
                  isFocused={focusedHit?.id === hit.id}
                  wasViewedDoc={wasViewedDoc}
                  onHitSelect={onHitSelect}
                  handleOpenDocumentViewer={handleOpenDocumentViewer}
                  isCompleted={completedFileIds.includes(hit.id)}
                />
              ))}
            </div>
          ) : (
            <Paragraph
              variant="small"
              className="my-5 p-4 text-muted-foreground"
            >
              No results.
            </Paragraph>
          )}
        </div>
      )}
    </>
  );
};

interface HitProps {
  hit: HitType;
  wasViewedDoc: DocumentItem | null;
  onHitSelect: (hit: HitType, isCompleted: boolean) => void;
  isCompleted: boolean;
  handleOpenDocumentViewer: (document: DocumentItem) => void;
  isFocused: boolean;
}

const Hit: React.FC<HitProps> = ({
  hit,
  wasViewedDoc,
  onHitSelect,
  isCompleted,
  handleOpenDocumentViewer,
  isFocused,
}) => {
  const formattedDate = format(hit.createdAt * 1000, "MMM d, yyyy");

  const [isLoadingVDocument, setIsLoadingVDocument] = useState(false);

  const handleClick = () => {
    onHitSelect(hit, isCompleted);
  };

  const _handleOpenDocumentViewer = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoadingVDocument(true);
    try {
      await handleOpenDocumentViewer(hit as unknown as DocumentItem);
    } finally {
      setIsLoadingVDocument(false);
    }
  };

  const RenderEye: React.FC = () => {
    if (wasViewedDoc?.id === hit.id) {
      return (
        <div className="relative">
          <Eye className="h-4 w-4" />
          <div className="absolute -bottom-[3px] -right-[10px] flex">
            <CheckIcon className="h-2.5 w-2.5" />
            <CheckIcon className="-ml-1 h-2.5 w-2.5" />
          </div>
        </div>
      );
    } else {
      return <Eye className="h-4 w-4" />;
    }
  };

  return (
    <div
      data-hit-id={hit.id}
      className={`
        flex cursor-pointer items-center justify-between border-b border-b-[hsl(var(--input))] p-2 pr-6 hover:bg-slate-50
        ${isFocused ? "bg-slate-50" : ""}`}
      onClick={() => handleClick()}
    >
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          className="gap-1 p-2"
          onClick={_handleOpenDocumentViewer}
          disabled={isLoadingVDocument}
        >
          <>
            {/* Preview */}
            {isLoadingVDocument ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <RenderEye />
            )}
          </>
        </Button>
        <div className="flex flex-col">
          <p className="m-0 text-[16px] font-semibold">{hit.filename} #2</p>
          <p className="m-0 text-xs text-muted-foreground">{formattedDate}</p>
        </div>
      </div>
      <div className="flex items-center gap-2 pr-3">
        {isCompleted && <CheckIcon className="h-4 w-4 text-green-500" />}
      </div>
    </div>
  );
};

interface CustomSearchBoxProps extends SearchBoxProvided {
  setShowPreview: (show: boolean) => void;
  showPreview: boolean;
}

const CustomSearchBox = ({
  currentRefinement,
  refine,
  setShowPreview,
  showPreview,
}: CustomSearchBoxProps) => {
  // Initialize with false to hide results when modal first opens
  const _onBlurAction = () => {
    if (!showPreview) {
      setTimeout(() => {
        setShowPreview(false);
        refine("");
      }, 100);
    }
  };

  return (
    <form
      noValidate
      action=""
      role="search"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex w-full items-center rounded-lg pl-8 pr-8 pt-8">
        <div className="flex-grow">
          <div className="relative">
            <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
            <Input
              type="search"
              className="pl-11 pr-8"
              value={currentRefinement}
              onFocus={() => {
                // Only show preview if there's text in the search box
                if (currentRefinement.trim().length > 0) {
                  setShowPreview(true);
                }
              }}
              onBlur={() => _onBlurAction()}
              onChange={(event) => {
                refine(event.currentTarget.value);
                // Show preview only if there's text in the search box
                setShowPreview(event.currentTarget.value.trim().length > 0);
              }}
              placeholder="Search file ..."
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const ConnectedSearchBox = connectSearchBox<
  SearchBoxProvided & {
    setShowPreview: (show: boolean) => void;
    showPreview: boolean;
  }
>(({ setShowPreview, showPreview, ...props }) => (
  <CustomSearchBox
    currentRefinement={props.currentRefinement}
    refine={props.refine}
    setShowPreview={setShowPreview}
    isSearchStalled={props.isSearchStalled}
    showPreview={showPreview}
  />
));

interface JobReportFileSearchProps {
  file_types: string[];
  itemsPerPage: number;
  uploudFileFromList: (doc: SingleDocResponseData) => void;
  completedFiles: UploadedFile[];
}

const JobReportFileSearch: React.FC<JobReportFileSearchProps> = ({
  file_types = [],
  itemsPerPage,
  uploudFileFromList,
  completedFiles,
}) => {
  const searchClient = createTypesenseSearchClient("documents");

  // PDF Viewer state
  const {
    isDocViewerOpen,
    initialPage,
    document: selectedDocument,
    setDocViewerState,
    citation,
  } = useDocViewer();

  // Initialize with false to hide results when modal first opens
  const [_showPreview, setShowPreview] = useState(false);
  const [_wasViewedDoc, setWasViewedDoc] = useState<DocumentItem | null>(null);

  const handleDocumentSelect = (doc: SingleDocResponseData) => {
    uploudFileFromList(doc);
    setShowPreview(false);
  };

  // Define the hit type
  type HitType = {
    id: string;
    filename: string;
    pages: string;
    userEmail: string;
    createdAt: number;
    claimNumber?: string;
    documents?: Array<{ id: string; filename: string }>;
    objectID: string;
  };

  const ConnectedHits = connectHits<HitType>(({ hits }) => (
    <CustomHits
      hits={hits}
      showPreview={_showPreview}
      wasViewedDoc={_wasViewedDoc}
      completedFiles={completedFiles}
      onDocumentSelect={handleDocumentSelect}
      handleOpenDocumentViewer={(document: DocumentItem) => {
        setWasViewedDoc(document);
        openDocumentViewer({
          document,
          setDocViewerState,
        });
      }}
    />
  ));

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName="documents">
        <Configure
          query_by="filename"
          filter_by={`document_type:[${file_types.join(", ")}]`}
          facet_by="document_type,user_id,organization_id"
          per_page={itemsPerPage}
        />
        <div className="relative mb-4">
          <ConnectedSearchBox
            setShowPreview={setShowPreview}
            showPreview={_showPreview}
          />
          <ConnectedHits />
        </div>
      </InstantSearch>

      {/* PDF Viewer Dialog */}
      <PDFViewerDialog
        open={isDocViewerOpen}
        doc={selectedDocument}
        initialPage={initialPage}
        setDocViewerState={setDocViewerState}
        citation={citation}
      />
    </>
  );
};

export default JobReportFileSearch;

// --------------- HELPER FUNCTIONS ---------------

interface OnKeyPressProps {
  hits: HitType[];
  e: KeyboardEvent;
  focusedHit: HitType | null;
  setFocusedHit: (hit: HitType | null) => void;
  onHitSelect: (hit: HitType, isCompleted: boolean) => void;
  isCompleted: boolean;
}

const onKeyPress = ({
  hits,
  e,
  focusedHit,
  setFocusedHit,
  onHitSelect,
  isCompleted,
}: OnKeyPressProps) => {
  const _hitIndex = hits.findIndex((hit) => hit.id === focusedHit?.id);

  if (e.key === "Enter") {
    focusedHit && onHitSelect(focusedHit, isCompleted);
  } else if (e.key === "Escape") {
    setFocusedHit(null);
  } else if (e.key === "ArrowUp") {
    let _nextHit = _hitIndex === -1 ? 0 : _hitIndex - 1;
    _nextHit = _nextHit < 0 ? 0 : _nextHit;

    setFocusedHit(hits[_nextHit]);
  } else if (e.key === "ArrowDown") {
    let _nextHit = _hitIndex === -1 ? 0 : _hitIndex + 1;
    _nextHit = _nextHit >= hits.length ? hits.length - 1 : _nextHit;

    setFocusedHit(hits[_nextHit]);
  }
};

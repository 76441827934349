import { Button } from "~/components/ui/button";
// import { PromptTemplate } from "../types";
import { Edit, Trash2 } from "lucide-react";
import { PromptTemplate } from "~/api/query_fns/prompt_template";
interface ItemProps {
  prompt: PromptTemplate;
  onSelectPrompt: (prompt: PromptTemplate) => void;
  onEditPrompt: (prompt: PromptTemplate) => void;
  onDeletePrompt: (prompt: PromptTemplate) => void;
}

const Item: React.FC<ItemProps> = ({
  prompt,
  onSelectPrompt,
  onEditPrompt,
  onDeletePrompt,
}) => {
  return (
    <div
      key={prompt.id}
      className="flex items-center justify-between px-2 py-1.5"
    >
      <button
        onClick={() => onSelectPrompt(prompt)}
        className="max-w-[150px] truncate text-left text-sm hover:underline"
      >
        {prompt.name}
      </button>
      <div className="flex gap-1">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onEditPrompt(prompt)}
          className="h-6 w-6"
        >
          <Edit className="h-3 w-3" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onDeletePrompt(prompt)}
          className="h-6 w-6 text-destructive"
        >
          <Trash2 className="h-3 w-3" />
        </Button>
      </div>
    </div>
  );
};

export default Item;

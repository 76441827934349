import { apiFn } from "~/api/utils";

// Typesense "/typesense/search"
export interface TypesenseRequestParams {
  collection_name: string;
  search_params: {
    q?: string;
    query_by?: string;
    filter_by?: string;
    facet_by?: string;
    page?: number;
    per_page?: number;
    [key: string]: any;
  };
}
export interface TypesenseResponseData {
  hits: any[];
  found: number;
  page: number;
  request_params: any;
  facet_counts: any[];
}

// Typesense "/typesense/search_documents"
export interface TypesenseRequestWithContentParams {
  search_params: {
    q?: string;
    query_by?: string;
    filter_by?: string;
    facet_by?: string;
    page?: number;
    per_page?: number;
    [key: string]: any;
  };
}
export interface TypesenseResponseWithContentData {
  hits: any[];
  found: number;
  page: number;
  request_params: any;
  facet_counts: any[];
  resultContentHits: {
    content: string;
  }[];
}

export const searchTypesense = apiFn<
  TypesenseRequestParams,
  TypesenseResponseData
>("POST", "/typesense/search");

// search by filename and content
export const searchDocumentsWithContentTypesense = apiFn<
  TypesenseRequestWithContentParams,
  TypesenseResponseWithContentData
>("POST", "/typesense/search_documents");

import { Heading } from "~/components/ui/heading";
import { Textarea } from "~/components/ui/textarea";
import UploadArea from "~/routes/Documents/UploadArea";
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import { ReportOptionEnum } from "~/api/query_fns/coverage-analysis";
import ReportOptions from "~/components/ReportOptions";

interface AdditionalInformationProps {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
  includeUploadHeading?: boolean;
  includeUploadArea?: boolean;
  placeholder?: string;
  availableDocumentTypes?: DocumentTypeEnum[];
  db_document_type?: DocumentTypeEnum;
  showReportOptions?: boolean;
  reportOptions?: ReportOptionEnum[];
  toggleReportOption?: (option: ReportOptionEnum) => void;
  reportType?: string;
  reportSubType?: string;
}

const AdditionalInformation = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
  includeUploadHeading = false,
  includeUploadArea = false,
  placeholder = "Please describe any additional information relevant for the analysis.",
  availableDocumentTypes = [DocumentTypeEnum.Attachment],
  db_document_type = DocumentTypeEnum.Attachment,
  showReportOptions = false,
  reportOptions = [],
  toggleReportOption = () => {},
  reportType = "",
  reportSubType,
}: AdditionalInformationProps) => {
  return (
    <div className="mb-10 w-full">
      {includeUploadHeading && (
        <Heading
          as="h2"
          className="text-black-100 flex flex-row items-center gap-2 p-1"
        >
          Additional Information
        </Heading>
      )}
      {includeUploadArea && (
        <UploadArea
          db_document_type={db_document_type || DocumentTypeEnum.Attachment}
          onUploadComplete={handleUploadComplete}
          description="Upload related docs"
          onDeleteDocument={handleDeleteUploadedDoc}
          availableDocumentTypes={availableDocumentTypes}
        />
      )}

      {showReportOptions && (
        <div className="mb-4 mt-4">
          <ReportOptions
            reportOptions={reportOptions}
            toggleReportOption={toggleReportOption}
            reportType={reportType}
            reportSubType={reportSubType}
          />
        </div>
      )}

      <Textarea
        className="mt-2 text-black"
        placeholder={placeholder}
        value={additionalNotes}
        onChange={(e) => setAdditionalNotes(e.target.value)}
        rows={5}
      />
    </div>
  );
};

export default AdditionalInformation;

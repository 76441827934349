import React from "react";
import JobReportSearch from "~/components/Search/v2/JobReports";

interface RecentReportsPanelProps {
  className?: string;
}

const RecentReportsPanel: React.FC<RecentReportsPanelProps> = ({
  className = "",
}) => {
  const reportTypes = [
    "policies_compare",
    "tower_analysis",
    "policy_fact",
    "generic_report",
    "coverage_checklist",
  ];

  return (
    <div className={`flex h-full flex-col ${className}`}>
      {/* Content */}
      <div className="w-full flex-1 overflow-y-auto">
        <JobReportSearch
          showHeader={false}
          enableSortByToggle={true}
          reportTypes={reportTypes}
          hitsPerPage={5}
          customStyles={{
            container: "p-1",
            title: "text-lg font-medium text-[#000000] mb-4",
            searchInput:
              "bg-white border border-[#E5E5E5] rounded-lg focus:border-[#007AFF] focus:ring-1 focus:ring-[#007AFF] text-[#000000] placeholder-[#666666]",
            reportItem:
              "bg-white border border-[#E5E5E5] rounded-lg p-4 mb-3 hover:border-[#007AFF] transition-colors duration-200",
            reportTitle: "text-base font-medium text-[#000000]",
            reportMeta: "text-xs text-[#666666]",
            loadingSpinner: "border-[#E5E5E5] border-t-[#000000]",
            emptyState: "text-[#666666]",
            errorState: "text-[#E63946]",
          }}
        />
      </div>
    </div>
  );
};

export default RecentReportsPanel;

import { createContext, useContext } from "react";
import { getSession } from "~/api";
import { UseQueryResult, useQuery } from "react-query";
import { ResponseData as SessionResponse } from "~/api/query_fns/session";

interface SessionContextType {
  session: SessionResponse["session"] | undefined;
  sessionQuery: UseQueryResult<SessionResponse, unknown>;
  loggedIn: boolean;
  organizationName: string;
}

const SessionContext = createContext<SessionContextType>(
  {} as SessionContextType
);

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sessionQuery = useQuery({
    queryKey: ["session"],
    queryFn: () => getSession(),
    retry: false,
  });

  const session = sessionQuery?.data?.session;
  const loggedIn = !!session?.email;
  const organizationName = session?.organizationName || "";
  return (
    <SessionContext.Provider
      value={{ sessionQuery, session, loggedIn, organizationName }}
    >
      {sessionQuery.isLoading ? <></> : children}
    </SessionContext.Provider>
  );
};

const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};

export default useSession;

import React, { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { FileText } from "lucide-react";

interface DocumentListProps {
  documents: SingleDocResponseData[];
  onDocumentClick?: (doc: SingleDocResponseData) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({
  documents,
  onDocumentClick,
}) => {
  const [showAll, setShowAll] = useState(false);

  // Show only 3 documents initially if there are more than 3
  const displayedDocuments = showAll ? documents : documents.slice(0, 3);
  const hasMoreDocuments = documents.length > 3;

  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <div className="sticky top-0 z-10 border-b border-gray-200 bg-white pb-2">
      <div className="flex items-center gap-2 px-4 pb-2 pt-3">
        <h2 className="text-base font-bold text-gray-700">
          Documents ({documents.length}):
        </h2>
        <div className="flex flex-wrap gap-1">
          {displayedDocuments.map((doc) => (
            <div
              key={doc.document.id}
              className="flex cursor-pointer items-center gap-1 whitespace-nowrap rounded-md bg-gray-100 px-2 py-1 text-xs hover:bg-gray-200"
              onClick={() => onDocumentClick && onDocumentClick(doc)}
            >
              <FileText size={12} />
              <span className="max-w-[150px] truncate">
                {doc.document.filename}
              </span>
            </div>
          ))}

          {hasMoreDocuments && (
            <button
              onClick={toggleShowAll}
              className="inline-flex h-6 items-center gap-1 rounded-md border bg-background px-1.5 text-xs font-medium hover:bg-accent hover:text-accent-foreground"
            >
              {showAll ? "Show less" : `+${documents.length - 3} more`}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentList;

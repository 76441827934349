import React from "react";
import { Button } from "~/components/ui/button";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "~/utils";
import { Rating } from "react-simple-star-rating";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import { useCitationStore } from "~/utils/ReportMenuHelper";
import { Switch } from "~/components/ui/switch";

interface ReportMenuItemProps {
  onRegenerate: () => void;
  onExportPDF: () => void;
  onCopyShareableLink: () => void;
  onAddFiles: () => void;
  rating: number;
  onRating: (rate: number) => void;
  reportType: ReportType;
}

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        "shrink-0 bg-border",
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        className
      )}
      {...props}
    />
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export const ReportHorizontalMenu: React.FC<ReportMenuItemProps> = ({
  onRegenerate,
  onExportPDF,
  onCopyShareableLink,
  onAddFiles,
  rating,
  onRating,
  reportType,
}) => {
  const ratingLabels = ["INCORRECT", "BAD", "OK", "GOOD", "GREAT"];
  const { showCitations, toggleCitations } = useCitationStore();

  return (
    <div className="mt-2 flex min-h-[80px] justify-between space-x-2 rounded-md p-2">
      <div className="flex flex-col ">
        <div className="flex items-center">
          <Rating
            tooltipDefaultText="CLICK TO RATE"
            onClick={onRating}
            initialValue={rating}
            size={20}
            SVGclassName={`inline-block`}
            allowFraction={false}
            allowTitleTag={true}
            tooltipArray={ratingLabels}
            tooltipStyle={{
              backgroundColor: "#e0e0e0",
              color: "#333",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
            fillColor="black"
            emptyColor="lightgray"
            allowHover={rating === 0}
          />
        </div>
        <div className="text-black-500 mt-1 w-full text-center text-xs">
          {rating > 0 ? "Thank You For Feedback!" : "Rate Report "}
        </div>
      </div>

      <Separator orientation="vertical" className="h-6" />

      {reportType !== "coverage_checklist" && (
        <>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={onRegenerate}
                  variant="ghost"
                  size="sm"
                  className="flex h-auto min-h-[60px] flex-col items-center gap-1 py-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3" />
                  </svg>
                  <span className="text-xs">Regenerate</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Re-Generate Report</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <Separator orientation="vertical" className="h-6" />
        </>
      )}

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={onExportPDF}
              variant="ghost"
              size="sm"
              className="flex h-auto min-h-[60px] flex-col items-center gap-1 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" y1="15" x2="12" y2="3" />
              </svg>
              <span className="text-xs">
                {reportType === "coverage_checklist"
                  ? "Export Excel"
                  : "Export PDF"}
              </span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {reportType === "coverage_checklist"
                ? "Export Excel"
                : "Export PDF"}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={onCopyShareableLink}
              variant="ghost"
              size="sm"
              className="flex h-auto min-h-[60px] flex-col items-center gap-1 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
              </svg>
              <span className="text-xs">Share Link</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Copy Shareable Link</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Separator orientation="vertical" className="h-6" />

      {reportType !== "coverage_checklist" && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={onAddFiles}
                variant="ghost"
                size="sm"
                className="flex h-auto min-h-[60px] flex-col items-center gap-1 py-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4" />
                  <polyline points="14 2 14 8 20 8" />
                  <path d="M3 15h6" />
                  <path d="M6 12v6" />
                </svg>
                <span className="text-xs">Add Files</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add Files</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex min-h-[60px] flex-col items-center justify-center space-y-1">
              <Switch
                id="citations-toggle"
                checked={showCitations}
                onCheckedChange={toggleCitations}
                className="data-[state=checked]:bg-black"
              />
              <span className="text-xs">
                {showCitations ? "Hide Citations" : "Show Citations"}
              </span>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{showCitations ? "Hide Citations" : "Show Citations"}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

import { redirect, useNavigation } from "react-router-dom";
import { useState } from "react";
import { createDocument, DocumentTypeEnum } from "~/api";
import UCPolicyListComponent from "~/components/UCPolicyListComponent";

import UploadForm from "./Analyze/UploadForm";
import { Heading } from "~/components/ui/heading";
import UCTemplate from "~/components/UCTemplate";
import { generateHash } from "~/utils";

type FormValue = { file: File; claimNumber: string; claimColor: string };
export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData) as FormValue;

  const file = data.file;
  const fileHash = await generateHash(file);

  const { document, presignedUrl } = await createDocument({
    filename: file.name,
    document_type: DocumentTypeEnum.Policy,
    claim_number: data.claimNumber,
    claim_color: data.claimColor,
    file_hash: fileHash,
  });

  await fetch(presignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });

  return redirect(`/analyze/${document.id}`);
};

const UCPrecedentSearch = () => {
  const [file, setFile] = useState<File | null>(null);
  const navigation = useNavigation();

  const isPending =
    navigation.state === "submitting" || navigation.state === "loading";

  const RightComponent = () => (
    <div className="h-full w-full">
      <Heading
        as="h2"
        className="custom-heading text-black-100 mb-4 flex flex-row items-center gap-2"
      >
        New Policy
      </Heading>

      <UploadForm
        setFile={setFile}
        disableSubmit={!file}
        submitting={isPending}
        buttonName={"Analyze"}
      />
    </div>
  );

  return (
    <UCTemplate
      SearchComponent={() => (
        <UCPolicyListComponent linkPrefix="/analyze/" itemsPerPage={5} />
      )}
      ButtonComponent={RightComponent}
      isLoading={false}
    />
  );
};

export default UCPrecedentSearch;

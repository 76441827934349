import { searchDocumentsWithContentTypesense } from "~/api/query_fns/typesense";

interface createSearchDocumentsParams {
  query: string;
  facetBy: string;
  filterBy: string;
  queryBy: string;
}

export interface createSearchDocumentsRequestParams {
  documentsParams: createSearchDocumentsParams & {
    page: number;
    hitsPerPage: number;
    sortBy?: string;
  };
  documentsContentParams: createSearchDocumentsParams;
}

export interface createSearchDocumentsResponse {
  hits: any[];
  found: number;
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
}

export const createSearchDocumentsRequest = async ({
  documentsParams,
  documentsContentParams,
}: createSearchDocumentsRequestParams): Promise<createSearchDocumentsResponse> => {
  const response = await searchDocumentsWithContentTypesense({
    search_params: {
      documents: {
        q: documentsParams.query || "",
        query: documentsParams.query || "",
        facet_by: documentsParams.facetBy,
        filter_by: documentsParams.filterBy,
        query_by: documentsParams.queryBy,
        sort_by: documentsParams.sortBy,

        page: documentsParams.page,
        per_page: documentsParams.hitsPerPage,
      },
      documents_content: {
        q: documentsContentParams.query || "",
        query: documentsContentParams.query || "",
        facet_by: documentsContentParams.facetBy,
        filter_by: documentsContentParams.filterBy,
        query_by: documentsContentParams.queryBy,
      },
    },
  });

  return {
    found: response.found,
    hits: response.hits.map((hit: any) => ({
      ...hit.document,
      objectID: hit.document.id,
      _highlightResult: hit.highlights,
    })),
    nbHits: response.hits.length,
    nbPages: Math.ceil(response.found / (documentsParams.hitsPerPage || 20)),
    hitsPerPage: documentsParams.hitsPerPage || 20,
  };
};

import React, { FC, useState, useEffect, useRef } from "react";
import { HamburgerMenuIcon as ListIcon } from "@radix-ui/react-icons";

// Define the heading item type
export type HeadingItem = {
  id: string;
  text: string;
  level: number;
};

// TableOfContents component
const TableOfContents: FC<{
  headings: HeadingItem[];
  contentRef: React.RefObject<HTMLDivElement>;
}> = ({ headings }) => {
  const [isOpen, setIsOpen] = useState(false);
  const tocRef = useRef<HTMLDivElement>(null);
  const [activeHeading, setActiveHeading] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Track scroll position to highlight active heading
  useEffect(() => {
    if (headings.length === 0) return;

    const handleScroll = () => {
      for (let i = headings.length - 1; i >= 0; i--) {
        const element = document.getElementById(headings[i].id);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100) {
            setActiveHeading(headings[i].id);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [headings]);

  if (headings.length === 0) return null;

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const getHeadingIndent = (level: number) => {
    return `${(level - 1) * 0.75}rem`;
  };

  // Format heading text based on level
  const formatHeadingText = (text: string, level: number): string => {
    // For level 1 headings, use all uppercase
    if (level === 1) {
      return text.toUpperCase();
    }

    // For other levels, normalize to title case
    const normalized = text.toLowerCase();
    return normalized.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Truncate long heading text
  const truncateText = (text: string, maxLength = 32): string => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + "...";
  };

  return (
    <div
      ref={tocRef}
      className="print:hidden"
      style={{
        position: "fixed",
        top: "65px",
        bottom: "20px",
        right: 0,
        width: isOpen ? "280px" : "32px",
        transition: "all 0.2s ease-in-out",
        overflow: "hidden",
        zIndex: 40,
        borderRadius: "8px 0 0 8px",
        boxShadow: isOpen ? "0px 1px 3px rgba(0, 0, 0, 0.1)" : "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Side navigation container */}
      <div className="flex h-full flex-col">
        {/* Tab indicator */}
        <div className="absolute inset-y-0 left-0 flex w-8 items-center justify-center border-l border-[#E5E5E5] bg-[#F7F7F7] transition-colors duration-200 hover:bg-[#E5E5E5]">
          <div className="flex h-32 flex-col items-center justify-center gap-2">
            <span className="vertical-text whitespace-nowrap text-xs font-medium tracking-wider text-[#1E1E1E]">
              CONTENTS
            </span>
            <ListIcon className="mt-2 h-4 w-4 text-[#1E1E1E]" />
          </div>
        </div>

        {/* Content panel */}
        <div
          className={`absolute inset-0 ml-8 border-l border-[#E5E5E5] bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.1)] transition-all duration-200 ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex items-center justify-between border-b border-[#E5E5E5] bg-[#FAFAFA] p-5">
            <div className="flex items-center gap-2">
              <ListIcon className="h-5 w-5 text-[#1E1E1E]" />
              <span className="text-xl font-medium text-[#000000]">
                Table of Contents
              </span>
            </div>
          </div>
          <nav
            className="overflow-y-auto p-4 scrollbar-thin"
            style={{
              maxHeight: `calc(100vh - 150px)`,
            }}
          >
            <ul className="list-none space-y-1 pl-0 text-[#666666]">
              {headings.map((heading) => {
                const isActive = activeHeading === heading.id;
                const formattedText = formatHeadingText(
                  heading.text,
                  heading.level
                );
                const displayText = truncateText(formattedText);

                return (
                  <li
                    key={heading.id}
                    className={`rounded-md transition-all duration-200 ${
                      isActive ? "relative" : "hover:bg-[#F7F7F7]"
                    }`}
                  >
                    <a
                      href={`#${heading.id}`}
                      className={`block rounded-md px-3 py-1.5 text-sm ${
                        isActive
                          ? "bg-[#E5E5E5] font-medium text-[#000000]"
                          : "text-[#666666] hover:text-[#1E1E1E]"
                      } ${
                        heading.level === 1 ? "font-semibold tracking-wide" : ""
                      }`}
                      style={{
                        paddingLeft: `calc(${getHeadingIndent(
                          heading.level
                        )} + 0.5rem)`,
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById(heading.id)?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                        setActiveHeading(heading.id);
                      }}
                      title={formattedText}
                    >
                      {isActive && (
                        <span className="absolute left-0 top-1/2 h-8 w-1.5 -translate-y-1/2 rounded-r-md bg-[#1E1E1E]"></span>
                      )}
                      {displayText}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>

      {/* Add CSS for vertical text and scrollbar styling */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .vertical-text {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          transform: rotate(180deg);
        }
        
        /* Webkit scrollbar styling */
        .scrollbar-thin::-webkit-scrollbar {
          width: 4px;
        }
        
        .scrollbar-thin::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .scrollbar-thin::-webkit-scrollbar-thumb {
          background-color: #E5E5E5;
          border-radius: 9999px;
        }
        
        .scrollbar-thin::-webkit-scrollbar-thumb:hover {
          background-color: #1E1E1E;
        }
      `,
        }}
      />
    </div>
  );
};

export default TableOfContents;

import {
  createSearchDocumentsRequestParams,
  createSearchDocumentsRequest,
  createSearchDocumentsResponse,
} from "~/utils/typesense/customSearch/createSearchDocumentsRequest";
import initConnerction from "~/utils/state/store";

interface loadDataProps {
  viewMode: "list" | "search";
  selectedTypes: string[];
  currentSearch: string;
  currentPage: number;
}

const ITEMS_PER_PAGE = 20;

const requestParams = ({
  viewMode,
  selectedTypes,
  currentSearch,
  currentPage,
}: loadDataProps): createSearchDocumentsRequestParams => {
  return {
    documentsParams: {
      query: currentSearch,
      queryBy: "filename",
      facetBy: "document_type,user_id,organization_id",
      filterBy:
        selectedTypes.length > 0
          ? `document_type:[${selectedTypes.join(", ")}]`
          : "",
      page: currentPage,
      hitsPerPage: ITEMS_PER_PAGE,
    },
    documentsContentParams: {
      query: currentSearch,
      queryBy: viewMode == "search" ? "content" : "",
      filterBy: "",
      facetBy: "organization_id",
    },
  };
};

const initStore = () => {
  const store = initConnerction<createSearchDocumentsResponse>(
    createSearchDocumentsRequest
  );

  const loadData = ({
    viewMode,
    selectedTypes,
    currentSearch,
    currentPage,
  }: loadDataProps) => {
    const params = requestParams({
      viewMode,
      selectedTypes,
      currentSearch,
      currentPage,
    });
    store.actions.loadData(params);
  };

  return {
    state: store.state,
    actions: { ...store.actions, loadData },
  };
};

export { initStore };

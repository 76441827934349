import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import JobReportSearch from "~/components/Search/v2/JobReports";
import UCTemplateV2 from "~/components/UCTemplateV2";
import { ReportGenerationButtonPropsV2 } from "~/components/UCReportGenerationButtonV2";
import {
  FileText,
  ClipboardList,
  HelpCircle,
  FileQuestion,
} from "lucide-react";

interface UCReportV2Props {
  reportType: ReportType;
  reportTitle: string;
  newPath: string;
}

const UCGenericReportV2 = ({
  reportType,
  reportTitle,
  newPath,
}: UCReportV2Props) => {
  const [additionalNotes] = useState("");
  const [uploadedDocs] = useState<SingleDocResponseData[]>([]);

  let buttonName: string;
  let description: string;

  switch (reportType) {
    case "policy_fact":
      buttonName = "Generate Policy Fact Report";
      description = "Create a detailed fact report about a policy";
      break;
    case "policies_compare":
      buttonName = "Generate Policies Comparison Report";
      description = "Compare multiple policies side by side";
      break;
    case "policy_question":
      buttonName = "Generate Policy Question Report";
      description = "Ask specific questions about a policy";
      break;
    case "generic_report":
      buttonName = "Generate Custom Report";
      description = "Create a customized report based on your needs";
      break;
    default:
      buttonName = "Generate Report";
      description = "Create a detailed report";
  }

  const { isLoading } = useGenerateReport(
    uploadedDocs,
    reportType,
    additionalNotes
  );

  const MainComponent = () => (
    <JobReportSearch reportTitle={reportTitle} reportTypes={[reportType]} />
  );

  // Define appropriate icons based on report type
  let icon;
  switch (reportType) {
    case "policy_fact":
      icon = <ClipboardList />;
      break;
    case "policies_compare":
      icon = <FileText />;
      break;
    case "policy_question":
      icon = <FileQuestion />;
      break;
    case "generic_report":
      icon = <HelpCircle />;
      break;
    default:
      icon = <FileText />;
  }

  const buttons = [
    {
      buttonText: buttonName,
      description: description,
      route: newPath,
      icon: icon,
    },
  ] as ReportGenerationButtonPropsV2[];

  return (
    <UCTemplateV2
      MainComponent={MainComponent}
      Buttons={buttons}
      isLoading={isLoading}
      loadingTitle="GENERATING REPORT"
      title={reportTitle}
      description="Select the following options:"
    />
  );
};

export default UCGenericReportV2;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { CopilotKit } from "@copilotkit/react-core";
import { CustomChat } from "~/components/util-chat";
import { v4 as uuidv4 } from "uuid";
import { Message, Role } from "~/components/CustomMessages";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { generateChatContext } from "~/utils/util-report";
import { fetchDocumentTexts } from "~/utils";
import DocumentList from "~/components/DocumentList";
import { useOpenReferenceDetails } from "../doc-util";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { Worker } from "@react-pdf-viewer/core";
import { getDocument } from "~/api/query_fns/documents";
import { Loader2, RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import ChatSessionsList from "~/components/ChatSessionsList";
import { fetchChatSessionMessages } from "~/api/query_fns/chat-api";
import { useParams, useNavigate } from "react-router-dom";
import WelcomeChatScreen from "~/components/chat/WelcomeScreen";

// Custom hook for OCR processing
const useOcrProcessing = () => {
  const [processingOcr, setProcessingOcr] = useState(false);
  const [processingDocIds, setProcessingDocIds] = useState<string[]>([]);
  const [retryAttempts, setRetryAttempts] = useState<Record<string, number>>(
    {}
  );
  // Add state to track document processing status
  const [processingStatus, setProcessingStatus] = useState<
    Record<
      string,
      {
        filename: string;
        completed: boolean;
        failed?: boolean;
      }
    >
  >({});

  // Extract the duplicate timeout handling logic into a reusable function
  const handleDocumentsExceedingTimeout = (
    docIds: string[],
    docsExceedingMaxRetries: string[],
    onComplete: (updatedDocs: SingleDocResponseData[]) => void,
    setProcessingStatus: React.Dispatch<
      React.SetStateAction<
        Record<
          string,
          { filename: string; completed: boolean; failed?: boolean }
        >
      >
    >,
    setProcessingDocIds: React.Dispatch<React.SetStateAction<string[]>>,
    setProcessingOcr: React.Dispatch<React.SetStateAction<boolean>>,
    setRetryAttempts: React.Dispatch<
      React.SetStateAction<Record<string, number>>
    >
  ) => {
    // Mark timed-out documents as failed in the status
    docsExceedingMaxRetries.forEach((id) => {
      setProcessingStatus((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          completed: true,
          failed: true,
        },
      }));
    });

    // Remove the timed-out documents from processing
    const remainingDocIds = docIds.filter(
      (id) => !docsExceedingMaxRetries.includes(id)
    );
    setProcessingDocIds(remainingDocIds);

    if (remainingDocIds.length === 0) {
      // No more documents to process
      setProcessingOcr(false);
      setRetryAttempts({});
      // Keep status visible for a moment so user can see completion
      setTimeout(() => setProcessingStatus({}), 2000);
    } else {
      // Continue checking remaining documents
      setTimeout(
        () => checkDocumentTextAvailability(remainingDocIds, onComplete),
        10000
      );
    }
  };

  // Simplify the checkDocumentTextAvailability function
  const checkDocumentTextAvailability = async (
    docIds: string[],
    onComplete: (updatedDocs: SingleDocResponseData[]) => void
  ) => {
    if (docIds.length === 0) return;

    try {
      const updatedDocs = await Promise.all(
        docIds.map(async (id) => {
          const docResponse = await getDocument({ id, includes: "text" });

          // Update processing status for this document
          setProcessingStatus((prev) => ({
            ...prev,
            [id]: {
              filename: docResponse.document.filename,
              completed: !!(
                docResponse.document.text || docResponse.document.textUrl
              ),
            },
          }));

          return docResponse;
        })
      );

      // Check if all documents have text
      const allTextAvailable = updatedDocs.every(
        (doc) => doc.document.text || doc.document.textUrl
      );

      if (allTextAvailable) {
        // Fetch text for all documents
        await fetchDocumentTexts(updatedDocs);

        // Call the completion callback with updated docs
        onComplete(updatedDocs);

        // Clear processing state
        setProcessingOcr(false);
        setProcessingDocIds([]);
        setRetryAttempts({});
        // Don't clear processing status immediately to allow the UI to show completion
        setTimeout(() => setProcessingStatus({}), 1000);
      } else {
        // Update retry attempts for each document
        const newRetryAttempts = { ...retryAttempts };

        docIds.forEach((id) => {
          newRetryAttempts[id] = (newRetryAttempts[id] || 0) + 1;
        });

        setRetryAttempts(newRetryAttempts);

        // Check if any document has exceeded max retries (10 minutes = 60 attempts at 10 second intervals)
        const maxRetries = 60; // 10 minutes with 10-second intervals
        const docsExceedingMaxRetries = docIds.filter(
          (id) => newRetryAttempts[id] > maxRetries
        );

        if (docsExceedingMaxRetries.length > 0) {
          // Some documents have exceeded max retry time
          console.error(
            "OCR processing timeout for documents:",
            docsExceedingMaxRetries
          );

          handleDocumentsExceedingTimeout(
            docIds,
            docsExceedingMaxRetries,
            onComplete,
            setProcessingStatus,
            setProcessingDocIds,
            setProcessingOcr,
            setRetryAttempts
          );
        } else {
          // Continue checking all documents
          setTimeout(
            () => checkDocumentTextAvailability(docIds, onComplete),
            10000
          );
        }
      }
    } catch (error) {
      console.error("Error checking document text availability:", error);
      // Retry after delay, but count as an attempt
      const newRetryAttempts = { ...retryAttempts };

      docIds.forEach((id) => {
        newRetryAttempts[id] = (newRetryAttempts[id] || 0) + 1;
      });

      setRetryAttempts(newRetryAttempts);

      // Check for timeout
      const maxRetries = 60;
      const docsExceedingMaxRetries = docIds.filter(
        (id) => newRetryAttempts[id] > maxRetries
      );

      if (docsExceedingMaxRetries.length > 0) {
        // Handle timeout using the same function as the success path
        handleDocumentsExceedingTimeout(
          docIds,
          docsExceedingMaxRetries,
          onComplete,
          setProcessingStatus,
          setProcessingDocIds,
          setProcessingOcr,
          setRetryAttempts
        );
      } else {
        setTimeout(
          () => checkDocumentTextAvailability(docIds, onComplete),
          10000
        );
      }
    }
  };

  // Process a new document
  const processDocument = async (
    doc: SingleDocResponseData,
    onComplete: (updatedDocs: SingleDocResponseData[]) => void
  ) => {
    // Check if document has text or textUrl
    if (!doc.document.text && !doc.document.textUrl) {
      // Document needs OCR processing
      setProcessingOcr(true);
      setProcessingDocIds((prevIds) => [...prevIds, doc.document.id]);

      // Add to processing status
      setProcessingStatus((prev) => ({
        ...prev,
        [doc.document.id]: {
          filename: doc.document.filename,
          completed: false,
        },
      }));

      // Start checking for text availability
      checkDocumentTextAvailability([doc.document.id], onComplete);
      return false; // Document needs processing
    } else {
      // Document already has text
      await fetchDocumentTexts([doc]);

      // Add to processing status as already completed
      setProcessingStatus((prev) => ({
        ...prev,
        [doc.document.id]: {
          filename: doc.document.filename,
          completed: true,
        },
      }));

      return true; // Document is ready
    }
  };

  return {
    processingOcr,
    processDocument,
    processingStatus,
    processingDocIds,
  };
};

const FullPageChatContent = () => {
  // State for chat messages and documents
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    SingleDocResponseData[]
  >([]);
  const [chatContext, setChatContext] = useState("");
  const [chatKey, setChatKey] = useState(uuidv4());
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isLoadingSession, setIsLoadingSession] = useState(false);

  // Refs
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const chatSessionsRef = useRef<HTMLDivElement>(null);

  // Router hooks
  const { chatId: urlChatId } = useParams<{ chatId?: string }>();
  const navigate = useNavigate();

  // Add a function to scroll to the bottom
  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current.querySelector(
        ".copilotKitMessages"
      );
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, []);

  // Add useEffect to scroll when messages change
  useEffect(() => {
    // Small delay to ensure content is rendered before scrolling
    const timeoutId = setTimeout(() => {
      scrollToBottom();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [chatMessages, scrollToBottom]);

  // Update the handleReset function to use the ref
  const handleReset = () => {
    // Clear uploaded documents
    setUploadedDocuments([]);

    // Clear chat messages
    setChatMessages([]);

    // Reset chat context
    setChatContext("");

    // Generate a new key to force remount of the CopilotKit component
    setChatKey(uuidv4());

    // Close the dialog
    setIsResetDialogOpen(false);

    // Trigger a refresh of the chat sessions list using the ref
    if (chatSessionsRef.current) {
      const refreshEvent = new CustomEvent("refresh-sessions");
      chatSessionsRef.current.dispatchEvent(refreshEvent);
    }

    console.log(
      "Chat reset: All documents and messages cleared, sessions refreshed"
    );
  };

  // Update the handleSessionSelect function to scroll after loading
  const handleSessionSelect = useCallback(
    async (chatId: string) => {
      console.log("Selected chat session:", chatId);

      try {
        // Show loading state
        setIsLoadingSession(true);

        // Update URL if not already on this chat
        if (window.location.pathname !== `/chat/${chatId}`) {
          navigate(`/chat/${chatId}`, { replace: true });
        }

        // Fetch messages for the selected chat session
        const response = await fetchChatSessionMessages({ chat_id: chatId });
        console.log("Fetched chat session messages:", response);

        if (response.messages && response.messages.length > 0) {
          // Sort messages by creation time to ensure correct order
          const sortedMessages = [...response.messages].sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );

          // Convert API messages to the format expected by CustomChat
          const formattedMessages = sortedMessages.map((msg) => ({
            id: msg.id,
            content: msg.message,
            role: msg.messageType === "user" ? "user" : ("assistant" as Role),
            isVisible: true,
            chatId: msg.chatId,
          }));

          console.log("Formatted messages for CustomChat:", formattedMessages);

          // Update chat messages state
          setChatMessages(formattedMessages);

          // Load associated documents if available
          if (sortedMessages[0].documentIds) {
            const docIds = sortedMessages[0].documentIds
              .split(",")
              .filter((id) => id.trim() !== "");
            console.log("Associated document IDs:", docIds);

            if (docIds.length > 0) {
              try {
                // Clear existing documents first
                setUploadedDocuments([]);

                // Fetch each document by ID
                const fetchedDocs = await Promise.all(
                  docIds.map(async (id) => {
                    const docResponse = await getDocument({
                      id,
                      includes: "text",
                    });
                    return docResponse;
                  })
                );

                console.log("Fetched documents:", fetchedDocs);

                // Update uploaded documents with all fetched documents
                // The useEffect will handle fetching texts and generating context
                setUploadedDocuments(fetchedDocs);

                // No need to explicitly call fetchDocumentTexts or generateChatContext here
                // as the useEffect will handle that when uploadedDocuments changes
              } catch (docError) {
                console.error(
                  "Error loading documents for chat session:",
                  docError
                );
              }
            }
          }

          // Generate a new key to force remount of the CopilotKit component
          setChatKey(uuidv4());

          console.log("Loaded chat session messages successfully");

          // Scroll to bottom after a short delay to ensure rendering is complete
          setTimeout(scrollToBottom, 300);
        } else {
          console.log("No messages found for this chat session");
        }
      } catch (error) {
        console.error("Error loading chat session:", error);
      } finally {
        setIsLoadingSession(false);
      }
    },
    [
      navigate,
      setChatMessages,
      setUploadedDocuments,
      setChatKey,
      scrollToBottom,
    ]
  );

  // Load chat session from URL parameter if available
  useEffect(() => {
    if (urlChatId) {
      handleSessionSelect(urlChatId);
    }
  }, [urlChatId, handleSessionSelect]);

  // Generate chat context when documents change
  useEffect(() => {
    if (uploadedDocuments.length > 0) {
      // First ensure all document texts are loaded
      fetchDocumentTexts(uploadedDocuments).then(() => {
        // Then generate context after texts are loaded
        const context = generateChatContext(
          uploadedDocuments.map((doc) => doc.document),
          "",
          false
        );
        setChatContext(context);
        console.log(
          "Generated new chat context:",
          context.substring(0, 100) + "..."
        );
      });
    } else {
      setChatContext("");
      console.log("Cleared chat context");
    }
  }, [uploadedDocuments]);

  // Use the OCR processing hook
  const { processingOcr, processDocument, processingStatus, processingDocIds } =
    useOcrProcessing();

  // Add PDF viewer dialog state management
  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  } = useOpenReferenceDetails(uploadedDocuments, []);

  const appendToChatMessages = (newMessages: Message[]) => {
    setChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  // Handle document upload
  const handleDocumentUpload = async (doc: SingleDocResponseData) => {
    console.log("FullPageChat handleDocumentUpload  ", doc);

    // Add document to state even without text
    setUploadedDocuments((prevDocs) => [...prevDocs, doc]);

    // Process the document
    const isReady = await processDocument(doc, (updatedDocs) => {
      // Update uploaded documents with the new data
      setUploadedDocuments((prevDocs) => {
        // Replace the documents that were being processed
        const filteredDocs = prevDocs.filter(
          (d) =>
            !updatedDocs.some(
              (updatedDoc) => updatedDoc.document.id === d.document.id
            )
        );
        return [...filteredDocs, ...updatedDocs];
      });
    });

    // If document is ready immediately, no need to do anything else
    if (isReady) {
      console.log("Document is ready immediately");
    }
  };

  const handleDeleteDocument = (docId: string) => {
    setUploadedDocuments((prevDocs) =>
      prevDocs.filter((d) => d.document.id !== docId)
    );
  };

  useEffect(() => {
    console.log(
      "Chat context or key changed. Context length:",
      chatContext.length
    );
  }, [chatContext, chatKey]);

  // Define custom header styles
  const customHeaderStyle = {
    background: "white",
    color: "white",
    borderBottom: "0px solid #e0e0e0",
    boxShadow: "none",
  };

  // Define custom messages container style
  const customMessagesStyle = {
    background: "white",
    // You can add more styles here as needed
  };

  // Define custom window style
  const customWindowStyle = {
    border: "none",
    boxShadow: "none",
    background: "white",
    // You can add more styles here as needed
  };

  // Handle document click from the document list
  const handleDocumentClick = (doc: SingleDocResponseData) => {
    // Open the document in the PDF viewer
    setDocViewerState({
      doc,
      page: 1, // Start at the first page
      isOpen: true,
      citation: null,
    });
  };

  return (
    <div className="mx-auto my-auto flex h-[90vh] w-[50vw] flex-col overflow-hidden">
      {/* Document list section with fixed 5% height */}
      <div className="sticky top-0 z-10 flex h-[5vh] max-h-[5vh] min-h-[5vh] w-full items-center justify-between overflow-y-auto bg-white">
        <div
          className={`document-container h-full ${
            uploadedDocuments.length > 0 ? "flex-grow" : "w-full"
          }`}
        >
          {uploadedDocuments.length > 0 && (
            <DocumentList
              documents={uploadedDocuments}
              onDocumentClick={handleDocumentClick}
            />
          )}
        </div>

        <div className="flex items-center">
          {/* Reset button */}
          <button
            onClick={() => setIsResetDialogOpen(true)}
            className="mr-2 flex items-center justify-center rounded-full p-1 transition-colors hover:bg-gray-100"
            title="Reset chat"
            disabled={
              uploadedDocuments.length === 0 && chatMessages.length === 0
            }
          >
            <RefreshCw
              className={`h-4 w-4 ${
                uploadedDocuments.length === 0 && chatMessages.length === 0
                  ? "text-gray-300"
                  : "text-gray-600"
              }`}
            />
          </button>
        </div>
      </div>

      {/* Chat section with fixed 85% height */}
      <div
        className="relative h-[85vh] max-h-[85vh] min-h-[85vh]"
        ref={chatContainerRef}
      >
        {/* Chat History Panel - conditionally rendered */}
        <div ref={chatSessionsRef}>
          <ChatSessionsList
            onSessionSelect={handleSessionSelect}
            key={`sessions-${chatKey}`}
          />
        </div>

        <CopilotKit
          key={chatKey}
          url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}
        >
          <CustomChat
            welcomeScreen={(props) => (
              <WelcomeChatScreen
                uploadedDocuments={uploadedDocuments}
                sendMessage={props.sendMessage}
                onDocumentUpload={handleDocumentUpload}
                onDeleteDocument={handleDeleteDocument}
              />
            )}
            context={chatContext}
            initialMessage={""}
            documents={uploadedDocuments}
            reportKey=""
            openReference={openReference}
            reportSource="chat"
            appendToReportChatMessages={(newMessages) => {
              appendToChatMessages(newMessages);
              // Scroll to bottom after adding new messages
              setTimeout(scrollToBottom, 100);
            }}
            initialChatMessages={chatMessages}
            selectedTextMenuOption={undefined}
            clearSelectedTextMenuOption={() => undefined}
            oId=""
            addCitations={addCitations}
            title=""
            headerStyle={customHeaderStyle}
            headerClassName="custom-header-class"
            messagesStyle={customMessagesStyle}
            windowStyle={customWindowStyle}
            showUploadButton={true}
            onDocumentUpload={handleDocumentUpload}
            hideHeader={true}
            key={`chat-${chatKey}-${chatContext.length}`}
          />
        </CopilotKit>

        {/* OCR Processing Overlay */}
        {processingOcr && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/30 backdrop-blur-sm">
            <div className="flex flex-col items-center rounded-lg bg-white/90 p-8">
              <Loader2 className="mb-4 h-12 w-12 animate-spin text-black" />
              <h3 className="text-xl font-semibold text-black">
                Processing OCR
              </h3>
              <p className="mt-2 text-sm text-gray-600">
                Extracting text from your documents... (
                {processingDocIds.length} remaining)
              </p>

              {/* Document processing status list */}
              <div className="mt-4 w-full max-w-md">
                <h4 className="mb-2 text-sm font-medium text-gray-700">
                  Document Status:
                </h4>
                <div className="max-h-40 space-y-2 overflow-y-auto">
                  {Object.entries(processingStatus).map(([id, status]) => (
                    <div key={id} className="flex items-center justify-between">
                      <div className="flex items-center">
                        <span className="max-w-[200px] truncate text-sm text-gray-600">
                          {status.filename}
                        </span>
                      </div>
                      <div>
                        {status.completed ? (
                          status.failed ? (
                            <span className="text-sm text-red-500">Failed</span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-green-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )
                        ) : (
                          <div className="flex space-x-1">
                            <div
                              className="h-2 w-2 animate-pulse rounded-full bg-gray-500"
                              style={{ animationDelay: "0ms" }}
                            ></div>
                            <div
                              className="h-2 w-2 animate-pulse rounded-full bg-gray-500"
                              style={{ animationDelay: "300ms" }}
                            ></div>
                            <div
                              className="h-2 w-2 animate-pulse rounded-full bg-gray-500"
                              style={{ animationDelay: "600ms" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <p className="mt-4 text-xs text-gray-500">
                This may take a few moments
              </p>
            </div>
          </div>
        )}

        {/* Loading overlay for session loading */}
        {isLoadingSession && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-white/80">
            <div className="flex flex-col items-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="mt-2 text-sm text-gray-600">
                Loading chat session...
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Add PDF Viewer Dialog */}
      <PDFViewerDialog
        open={isDocViewerOpen}
        doc={document}
        initialPage={initialPage}
        setDocViewerState={setDocViewerState}
        citation={citation}
      />

      {/* Reset confirmation dialog */}
      <Dialog open={isResetDialogOpen} onOpenChange={setIsResetDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Reset Chat</DialogTitle>
            <DialogDescription>
              Are you sure you want to reset the chat?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsResetDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleReset}>
              Reset
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const FullPageChat = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <FullPageChatContent />
    </Worker>
  );
};

export default FullPageChat;

import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { ArrowRight } from "lucide-react";

interface ActionCardProps {
  id: string;
  title: string;
  description: string;
  icon: ReactNode;
  path: string;
  primaryAction: string;
  beta?: boolean;
}

const ActionCard = ({
  id,
  title,
  description,
  icon,
  path,
  primaryAction,
  beta,
}: ActionCardProps) => {
  return (
    <NavLink
      key={id}
      to={path}
      className="group flex h-full flex-col overflow-hidden rounded-2xl border border-[#E5E5E5] bg-white shadow-[0px_2px_4px_rgba(0,0,0,0.05)] transition-all duration-300 hover:scale-[1.02] hover:transform hover:shadow-[0px_12px_24px_rgba(0,0,0,0.12)]"
    >
      <div className="relative flex h-full flex-col overflow-hidden p-5">
        <div className="relative z-10 mb-4 flex items-center">
          <div className="rounded-xl bg-white p-3 text-[#1E1E1E] shadow-sm">
            {icon}
          </div>
          <h3 className="relative z-10 ml-3 text-base font-medium text-[#000000] transition-colors group-hover:text-[#1E1E1E]">
            {title}
          </h3>
          {beta && (
            <span className="ml-auto rounded-full bg-[#FAFAFA] px-3 py-1 text-xs font-semibold text-[#1E1E1E]">
              BETA
            </span>
          )}
        </div>
        <p className="relative z-10 mb-4 line-clamp-2 flex-grow text-sm text-[#666666]">
          {description}
        </p>
        <div className="relative z-10 mt-auto flex items-center justify-between">
          <div className="flex-1 pr-2">
            <button className="w-full rounded-xl border border-[#E5E5E5] bg-[#FAFAFA] px-4 py-2.5 text-left transition-all duration-300 hover:bg-[#F5F5F5]">
              <div className="flex items-center">
                <span className="text-sm font-medium text-[#000000] group-hover:underline">
                  {primaryAction}
                </span>
                <ArrowRight className="ml-auto h-4 w-4 text-[#1E1E1E] transition-transform duration-300 group-hover:translate-x-1.5" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default ActionCard;

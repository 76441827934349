import { SearchClient, SearchOptions } from "instantsearch.js/es/types";
import { searchTypesense } from "~/api/query_fns/typesense";

interface RequestParams {
  indexName: string;
  params: SearchOptions;
}
export interface ResponseParams {
  index: string;
  hitsPerPage: number | undefined;
  nbHits: number;
  nbPages: number;
  page: number;
  processingTimeMS: number;
  query: string;
  hits: any[];
  found: number;
  request_params: any;
  facet_counts: any[];
}

// ----------------------- TYPESENSE SEARCH CLIENT -----------------------
export function createTypesenseSearchClient(
  collectionName: string
): SearchClient {
  return {
    search: async (requests: RequestParams[]): Promise<any> => {
      try {
        const results = await Promise.all(
          requests.map(async (request) => initRequest(request, collectionName))
        );

        return { results };
      } catch (error: any) {
        console.error("Error in Typesense search:", error);
        return { results: [] };
      }
    },
    searchForFacetValues: undefined,
  };
}

// ----------------------- HELPER FUNCTIONS -----------------------
const initRequest = async (
  request: RequestParams,
  collectionName: string
): Promise<ResponseParams> => {
  const response = await searchTypesense({
    collection_name: collectionName,
    search_params: {
      q: request.params?.query || "",
      query_by: request.params?.query,
      filter_by: request.params?.filters,
      facet_by: request.params?.facets?.join(","),
      page: request.params?.page,
      per_page: request.params?.hitsPerPage,
      ...request.params,
    },
  });

  return {
    ...response,
    index: request.indexName,
    hitsPerPage: request.params?.hitsPerPage,
    nbHits: response.found,
    nbPages: Math.ceil(response.found / (request.params?.hitsPerPage || 20)),
    page: response.page,
    processingTimeMS: 0,
    query: request.params?.query || "",
    hits: response.hits.map((hit: any) => ({
      ...hit.document,
      objectID: hit.document.id,
      _highlightResult: hit.highlights,
    })),
  };
};

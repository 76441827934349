import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import JobReportSearch from "~/components/Search/v2/JobReports";
import UCTemplateV2 from "~/components/UCTemplateV2";
import { ReportGenerationButtonPropsV2 } from "~/components/UCReportGenerationButtonV2";
import { FileText, RefreshCw, CheckCircle, ClipboardList } from "lucide-react";

interface UCReportV2Props {
  reportType: ReportType;
  reportTitle: string;
  newPath: string;
}

const UCClaimCoverageV2 = ({
  reportType,
  reportTitle,
  newPath,
}: UCReportV2Props) => {
  const [additionalNotes] = useState("");
  const [uploadedDocs] = useState<SingleDocResponseData[]>([]);

  const { isLoading } = useGenerateReport(
    uploadedDocs,
    reportType,
    additionalNotes
  );

  const MainComponent = () => (
    <JobReportSearch reportTitle={""} reportTypes={[reportType]} />
  );

  const buttons = [
    {
      buttonText: "Generate Coverage Report",
      description: "Create a detailed coverage analysis report",
      route: newPath + "?reportSubType=coverage",
      icon: <ClipboardList />,
    },
    {
      buttonText: "Letters",
      description:
        "Generate FNOL, rebuttal, reservation of rights, claim denial letters with appropriate justification",
      route: `${newPath}?reportSubType=letters`,
      icon: <FileText />,
    },
    {
      buttonText: "Ongoing Claim Update",
      description:
        "Document progress, updates, review and determine next steps for active claims",
      route: `${newPath}?reportSubType=active`,
      icon: <RefreshCw />,
    },
    {
      buttonText: "Audit Closed Claims",
      description:
        "Systematically examine closed claim files to verify they were handled properly from start to finish. ",
      route: `${newPath}?reportSubType=audit`,
      icon: <CheckCircle />,
    },
  ] as ReportGenerationButtonPropsV2[];

  return (
    <UCTemplateV2
      MainComponent={MainComponent}
      Buttons={buttons}
      isLoading={isLoading}
      loadingTitle={`GENERATING ${reportTitle.toUpperCase()}`}
      title={reportTitle}
      description="Select the following options:"
    />
  );
};

export default UCClaimCoverageV2;
